import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {environment} from "../../../environments/environment";
import {ClassicType} from "../../shared/models/ClassicType";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ScadenzeStoreService} from "../../Scadenzario/_services/scadenze-store.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";
import {ApiRestService} from "../_services/api-rest.service";

@Component({
  selector: 'app-edit-attivita-rma',
  templateUrl: './edit-attivita-rma.component.html',
  styleUrls: ['./edit-attivita-rma.component.css']
})
export class EditAttivitaRmaComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  @Input() Item: Scadenza;
  @Input() IsModal: boolean = true;
  @Output() updated = new EventEmitter<Scadenza>();
  @Output() added = new EventEmitter<Scadenza>();
  @Input() hasScadenzeFiglie: boolean = environment.Modules.ScadenzeFiglie;
  TipiSelezionati: ClassicType[] = [];
  Tipi: ClassicType[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField:'id',
    textField: 'label',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  constructor(
    private store: ScadenzeStoreService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private apirestservice: ApiRestService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if(this.Item?.id)
      this.subscriptions.add(this.apirestservice.GetSegnalazione({id: this.Item.id}).subscribe(data => {
        this.Item = data ?? new Scadenza();
        this.compila(data);
      }))
    else
      this.compila(this.Item);

  }
  compila(item: Scadenza){
    if(item?.tipoObject)
      this.TipiSelezionati = [item.tipoObject];
    if(item?.manager){
      item.managerObject = item.manager;
    }
  }
  saveModalButton(close?: boolean) {
    if (this.Item && !isNaN(this.Item.id)) {
      this.apirestservice.UpdateSegnalazione(this.Item).subscribe(data => {
        this.updated.emit(data);
        this.ngOnInit();
        if(close)
          this.closeBtnClick();
      });
    } else if (this.Item && (isNaN(this.Item.id) || this.Item.id < 1)) {
      this.apirestservice.AddSegnalazione(this.Item).subscribe(data => {
        this.added.emit(data);
        this.Item.id = data.id;
        this.ngOnInit();
        if(close)
          this.closeBtnClick();
      });
    }
  }
  closeBtnClick() {
    this.activeModal.dismiss();
  }

  protected readonly environment = environment;

  ChangeHandler(item: { OnChange: string; Event: any }) {
    switch (item.OnChange ){
      case 'OnFilesChange' :
        this.ngOnInit();
        break;
    }


  }
}
