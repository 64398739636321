<div class="card" *ngIf="CheckList && FirstCatIsTab">
  <ul ngbNav #nav="ngbNav" [(activeId)]="ActiveId" class="nav-tabs">
    <li [ngbNavItem]="todo.id" *ngFor="let todo of CheckList;">
      <a ngbNavLink> {{todo.categoria}} </a>
      <ng-template ngbNavContent>
        <app-questionario [(CheckList)]="todo.figli" [(Congelamenti)]="Congelamenti"
          (CheckListChange)="change()"></app-questionario>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<div *ngIf="CheckList && !FirstCatIsTab">
  <div class="card" *ngFor="let todo of CheckList;">
    <div class="card-body">
      <h4 class="card-title" *ngIf="restroute" style="background-color: #a1a2a3;  padding: 10px;">{{todo.categoria}}
      </h4>
      <h4 class="card-title" *ngIf="!restroute">{{todo.categoria}}</h4>
      <span class="text-info mb-3" *ngFor="let oldVal of GetChecksHistory(todo)">{{oldVal.Data | date:
        'dd/MM/yyyy'}}&nbsp;&nbsp;-&nbsp;&nbsp;{{oldVal.Utente}}&nbsp;&nbsp;-&nbsp;&nbsp;{{oldVal.Value}} <br></span>
      <div class="table-responsive" *ngIf="todo.foglie?.length>0 && !isNota(todo) && !(todo.figli?.length>0)">
        <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">
          <thead>
            <tr>
              <th>Check</th>
              <th scope="col">Valore</th>
              <th scope="col">Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of todo.foglie">
              <td>
                {{ item.check }}
              </td>
              <td class="align-middle">
                <input [(ngModel)]="item.risposta" (ngModelChange)="change(item, todo.foglie)"
                  [disabled]="isModifyDisabled" type="checkbox" name="">
              </td>
              <td class="align-middle">
                <textarea *ngIf="item.conNote" [(ngModel)]="item.note" (ngModelChange)="change()"
                  [disabled]="isModifyDisabled" class="form-control" type="text" name=""></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" *ngIf="todo.foglie?.length>0 && isNota(todo)">
        <div class="col-12" *ngFor="let item of todo.foglie;">
          <span class="text-info" style="margin-bottom: 10px; font-weight: bold;"
            *ngFor="let oldVal of GetNoteHistory(item)">{{oldVal.Data | date:
            'dd/MM/yyyy'}}&nbsp;&nbsp;-&nbsp;&nbsp;{{oldVal.Utente}}&nbsp;&nbsp;-&nbsp;&nbsp;{{oldVal.Value}}
            <br><br></span>
          <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled"
            class="form-control"></textarea>
        </div>
      </div>
      <div class="row" *ngIf="todo.figli?.length>0 && !isNota(todo)">
        <div class="col-12">
          <app-questionario [(CheckList)]="todo.figli" [(Congelamenti)]="Congelamenti"
            (CheckListChange)="change()"></app-questionario>
        </div>

        <!--        <div class="col-12">-->
        <!--          <div class="card"  *ngFor="let todo2 of todo.figli; let level_2 = index;">-->
        <!--            <div class="card-body" >-->
        <!--              <h4 class="card-title">{{todo2.categoria}}</h4>-->
        <!--              <div *ngIf="LastValues?.length > 0 && todo2.foglie?.length>0 && !isNota(todo2)">-->
        <!--                <div *ngFor="let oldValue2 of LastValues[level_1].figli[level_2].foglie">-->
        <!--                  <span *ngIf="oldValue2.risposta">{{LastCong.utente}} - {{LastCong.data | date: 'dd/MM/yyyy HH:mm'}} - {{oldValue2.check}}{{oldValue2.note ?  ' - (': '' }}{{oldValue2.note}}{{oldValue2.note ?')': '' }}</span>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="table-responsive" *ngIf="todo2.foglie?.length>0 && !isNota(todo2) && !(todo2.figli?.length>0)">-->
        <!--                <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">-->
        <!--                  <thead>-->
        <!--                  <tr>-->
        <!--                    <th>Check</th>-->
        <!--                    <th scope="col">Valore</th>-->
        <!--                    <th scope="col">Note</th>-->
        <!--                  </tr>-->
        <!--                  </thead>-->
        <!--                  <tbody>-->
        <!--                  <tr *ngFor="let item of todo2.foglie">-->
        <!--                    <td >-->
        <!--                <span >-->
        <!--                  <span class="badge badge-light text-dark rounded-pill font-16 mr-1">-->
        <!--                    <i-feather name="info" class="feather-sm"></i-feather>-->
        <!--                  </span>-->
        <!--                  {{ item.check }}-->
        <!--                </span>-->
        <!--                    </td>-->
        <!--                    <td class="align-middle" >-->
        <!--                <span >-->
        <!--                  <input [(ngModel)]="item.risposta" (ngModelChange)="change()" [disabled]="isModifyDisabled"  type="checkbox" name="" >-->
        <!--                </span>-->
        <!--                    </td>-->
        <!--                    <td class="align-middle" *ngIf="item.conNote">-->
        <!--                <span >-->

        <!--                  <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control" type="text" name="" ></textarea>-->
        <!--                </span>-->
        <!--                    </td>-->
        <!--                  </tr>-->
        <!--                  </tbody>-->
        <!--                </table>-->
        <!--              </div>-->
        <!--              <div class="row" *ngIf="todo2.foglie?.length>0 && isNota(todo2)">-->
        <!--                <div class="col-12" *ngFor="let item of todo2.foglie; let noteLevel_2= index;">-->
        <!--                  <span *ngIf="LastValues?.length>0 && LastValues[level_1].figli[level_2].foglie[noteLevel_2].risposta && LastValues[level_1].figli[level_2].foglie[noteLevel_2].note?.length>0">{{LastCong.utente}} - {{LastCong.data | date: 'dd/MM/yyyy HH:mm'}} - {{LastValues[level_1].figli[level_2].foglie[noteLevel_2].note}}</span>-->
        <!--                  <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control"></textarea>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--              <div class="row" *ngIf="todo2.figli?.length>0 && !isNota(todo2) ">-->
        <!--                <div class="col-12">-->
        <!--                  <div class="card"  *ngFor="let todo3 of todo2.figli; let level_3 = index;">-->
        <!--                    <div class="card-body" >-->
        <!--                      <h4 class="card-title">{{todo3.categoria}}</h4>-->
        <!--                      <div *ngIf="LastValues?.length > 0 && todo3.foglie?.length>0 && !isNota(todo3)">-->
        <!--                        <div *ngFor="let oldValue3 of LastValues[level_1].figli[level_2].figli[level_3].foglie">-->
        <!--                          <span *ngIf="oldValue3.risposta && oldValue3.check?.length > 0">{{LastCong.utente}} - {{LastCong.data | date: 'dd/MM/yyyy HH:mm'}} - {{oldValue3.check}}{{oldValue3.note ? ' - (': '' }}{{oldValue3.note}}{{oldValue3.note ?')': '' }}</span>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                      <div class="table-responsive" *ngIf="todo3.foglie?.length>0 && !isNota(todo3)">-->
        <!--                        <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">-->
        <!--                          <thead>-->
        <!--                          <tr>-->
        <!--                            <th>Check</th>-->
        <!--                            <th scope="col">Valore</th>-->
        <!--                            <th scope="col">Note</th>-->
        <!--                          </tr>-->
        <!--                          </thead>-->
        <!--                          <tbody>-->
        <!--                          <tr *ngFor="let item of todo3.foglie">-->
        <!--                            <td >-->
        <!--                <span >-->
        <!--                  <span class="badge badge-light text-dark rounded-pill font-16 mr-1">-->
        <!--                    <i-feather name="info" class="feather-sm"></i-feather>-->
        <!--                  </span>-->
        <!--                  {{ item.check }}-->
        <!--                </span>-->
        <!--                            </td>-->
        <!--                            <td class="align-middle" >-->
        <!--                <span >-->
        <!--                  <input [(ngModel)]="item.risposta" (ngModelChange)="change()" [disabled]="isModifyDisabled"  type="checkbox" name="" >-->
        <!--                </span>-->
        <!--                            </td>-->
        <!--                            <td class="align-middle" *ngIf="item.conNote">-->
        <!--                <span >-->
        <!--                  <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control" type="text" name="" ></textarea>-->
        <!--                </span>-->
        <!--                            </td>-->
        <!--                          </tr>-->
        <!--                          </tbody>-->
        <!--                        </table>-->
        <!--                      </div>-->
        <!--                      <div class="row" *ngIf="todo3.foglie?.length>0 && isNota(todo3)">-->
        <!--                        <div class="col-12" *ngFor="let item of todo3.foglie; let noteLevel_3 = index">-->
        <!--                          <span *ngIf="LastValues?.length>0 && LastValues[level_1].figli[level_2].figli[level_3].foglie[noteLevel_3].risposta && LastValues[level_1].figli[level_2].figli[level_3].foglie[noteLevel_3].note?.length>0">{{LastCong.utente}} - {{LastCong.data | date: 'dd/MM/yyyy HH:mm'}} - {{LastValues[level_1].figli[level_2].figli[level_3].foglie[noteLevel_3].note}}</span>-->
        <!--                          <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control"></textarea>-->
        <!--                        </div>-->
        <!--                      </div>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>