import { Intervento } from './../../Interventi/Models/Intervento';
import { AgmMarker, MouseEvent } from '../../../LibrerieCustom/agm-core-1.1.0';
export class MarkerDragObj {
  marker: Marker;
  mouseEvent: MouseEvent;
}
export class Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  iconUrl: string;
  selected: boolean;
  inLista: boolean;
  pianificato?: boolean;
  intervento: number;
  interventoObject?: any;
  visible?:boolean = true;
}
