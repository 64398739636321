import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Categoria } from "../../../formazione/_Models/categoria";
import { ApiPathsService } from "../../../api-paths.service";
import { adjustClientRect } from "@angular/cdk/drag-drop/client-rect";

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html'
})
export class QuestionarioComponent implements OnInit {
  @Input() CheckList: Todo[];
  @Output() CheckListChange = new EventEmitter<Todo[]>();
  @Input() isModifyDisabled: boolean = false;
  @Output() OnCheck = new EventEmitter<Todo[]>();
  @Output() OnSave = new EventEmitter<Todo[]>();
  @Input() restroute: string;
  @Input() SingleSelection: boolean = false;
  @Input() filter;
  @Input() Congelamenti: any[] = [];
  @Output() CongelamentiChange = new EventEmitter<any[]>();
  @Input() FirstCatIsTab: boolean = false;
  LastValues: Todo[];
  LastCong: any;
  constructor(private ApiPaths: ApiPathsService, private toastService: ToastrService,) { }
  ngOnInit() {
    if (this.restroute) {
      this.ApiPaths.ClassicPost(this.restroute, this.filter).subscribe(data => {
        if (data) {
          this.CheckList = data.todo;
        }
      });
      // if (this.Congelamenti?.length > 0) {
      //   this.LastCong = this.Congelamenti?.find(x => true);
      //   var id_congelamento = this.LastCong?.id;
      //   this.ApiPaths.ClassicPost(this.restroute, {congelamento: id_congelamento}).subscribe(data => {
      //     if (data) {
      //       this.LastValues = data.todo;
      //     }
      //   });
      // }
    }

  }
  saveClick() {
    this.OnSave.emit(this.CheckList);
  }

  OnCheckChange(todo: Todo, item: Check) {
    todo.foglie.filter(x => x?.id != item?.id)?.forEach(check => check.risposta = false);
    this.OnCheck.emit(this.CheckList);
  }

  isNota(item: Todo) {
    return item.foglie.some(x => x.isNota);
  }

  change(item: Check, foglie: Check[]) {
    if (this.SingleSelection)
      foglie?.forEach(x => x.risposta = x.id == item.id ? item.risposta : false);
    this.CheckListChange.emit(this.CheckList);
  }

  /*
  *
  * {
      "id": 132,
      "codice": "2",
      "check": "10-14",
      "note": null,
      "risposta": true,
      "conNote": false,
      "isNota": false
  }
  *
  * {
      "id": 175,
      "codice": null,
      "check": null,
      "note": "CONTATTI dsa",
      "risposta": true,
      "conNote": false,
      "isNota": true
  }
  * */
  GetNoteHistory(foglia: Check): { Utente?: string, Data?: string, Value?: string }[] {
    return this.Congelamenti.map(cliente => {
      return {
        Utente: cliente.utente,
        Data: cliente.data,
        Value: cliente?.checks?.find(x => x.id == foglia.id && x.note?.length > 0)?.note
      }
    })?.filter(x => x.Value) ?? [];
  }
  GetChecksHistory(todo: Todo): { Utente?: string, Data?: string, Value?: string }[] {
    return this.Congelamenti.map(cliente => {
      var value: string = "";
      cliente?.checks?.filter((x: Check) => todo.foglie?.find(y => y.id == x.id) && x.check?.length > 0)?.forEach((x: Check) => value += (value ? ` - ${x.check}` : x.check));
      return {
        Utente: cliente.utente,
        Data: cliente.data,
        Value: value
      }
    })?.filter(x => x.Value?.length > 0) ?? [];
  }

}
export class Check {
  id: number;
  check: string;
  codice: string;
  esito: string;
  valore: number;
  modifica: boolean;
  categorie: number[];
  conNote: boolean;
  note: string;
  categorieSelezionate: Categoria[];
  risposta: boolean;
  isNota?: boolean;
  constructor(modifica: boolean) {
    this.modifica = modifica;
  }
}
export class Todo {
  id: number;
  foglie: Check[];
  categoria: string;
  figli: Todo[];
}
