<div class="card">
  <div class="card-body">
    <div class="table-responsive">
      <table class="table bg-white table-hover align-middle mb-0 no-wrap">
        <thead>
        <tr>
          <th>
            Utente
          </th>
          <th>Nome</th>
          <th scope="col">Anteprima</th>
          <th scope="col">Descrizione</th>
          <th scope="col">Data</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of items | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
          <td>
            <span class="text-dark font-medium font-16">{{ item?.referenceObject?.label }}</span>
          </td>
          <td>
            <span class="text-dark font-medium font-16">{{ item?.file }}</span>
          </td>
          <td>
            <div class="el-card-avatar el-overlay-1 d-flex justify-items-center"
                 *ngIf="includesExtensions(item, ['.pdf', '.jpg', '.jpeg', '.png']) ">
              <img style="max-height: 200px; width: auto;"
                   *ngIf="includesExtensions(item, ['.jpg', '.jpeg', '.png']) && authenticationService?.currentUserValue?.access_token"
                   [src]="Api?.getBaseAPI() + '/file/download/' + item.id?.toString()+ '?token=' + authenticationService?.currentUserValue?.access_token"
                   alt=""/>
              <pdf-viewer
                *ngIf="includesExtensions(item, ['.pdf'])"
                [src]="Api?.getBaseAPI() + '/file/download/' + item.id?.toString()+ '?token=' + authenticationService?.currentUserValue?.access_token"
                [render-text]="true" [autoresize]="true" [zoom]="0.2" [zoom-scale]="'page-width'"
                [external-link-target]="'blank'"
                style=" display: block; width: 100%; height: 200px; "
              ></pdf-viewer>
            </div>
          </td>
          <td class="align-middle">
            <span>
               {{ item.description }}
            </span>
          </td>
          <td class="align-middle">
            <span>
              {{ item.date | date: 'dd/MM/yyyy' }}
            </span>
          </td>
        </tr>

        </tbody>
      </table>
    </div>


    <div class="d-flex justify-content-center p-2 pt-3 bg-white border-top">
      <ngb-pagination (pageChange)="getPage($event)" id="paginationComponent" [maxSize]="10"
                      [collectionSize]="collectionSize" [pageSize]="pageSize">
      </ngb-pagination>
    </div>

  </div>
</div>
