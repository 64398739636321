<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem?.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            (click)="hasSubmenu(sidebarnavItem) ? '' : handleNotify()"
            >
            <div class="nav-small-cap" *ngIf="sidebarnavItem?.extralink === true"><i
                    [ngClass]="[sidebarnavItem?.icon??'']"></i><span
                    class="hide-menu">{{sidebarnavItem.title | translate}}</span></div>
            <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem?.class === '' || sidebarnavItem?.class === undefined ? [getPath(sidebarnavItem?.path)] : null"
                [queryParams]="sidebarnavItem?.class === '' || sidebarnavItem?.class === undefined ? getQuerystring(sidebarnavItem?.path): null"
                [ngClass]="[sidebarnavItem.class ?? '']" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
               >
                 <i [ngClass]="[sidebarnavItem?.icon ?? '']"></i>
<!--                <i-feather [name]="sidebarnavItem.icon" [ngClass]="[sidebarnavItem.icon]"></i-feather>-->
                <span class="hide-menu">{{sidebarnavItem?.title | translate}}
                    <span *ngIf="sidebarnavItem?.label != '' " [ngClass]="[sidebarnavItem?.labelClass ?? '']">{{sidebarnavItem?.label | translate}}</span>
                </span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="hasSubmenu(sidebarnavItem)"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }" >
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of getVisibleItem(sidebarnavItem.submenu)"
                (click)="hasSubmenu(sidebarnavItem) ? '' : handleNotify()"
                    [class.active]="showSubMenu === sidebarnavSubItem?.title"
                    >
                    <a class="sidebar-link"
                        [routerLink]="hasSubmenu(sidebarnavSubItem) ? null : [getPath(sidebarnavSubItem?.path)]"
                        [queryParams]="hasSubmenu(sidebarnavSubItem) ? null : getQuerystring(sidebarnavSubItem?.path)"
                        [class.active]="isActive(sidebarnavSubItem)"
                        [ngClass]="[sidebarnavSubItem?.class ?? '']" *ngIf="!sidebarnavSubItem?.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem?.title ?? '')">
                        <i [ngClass]="[sidebarnavSubItem.icon?? '']"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title ?? '' | translate}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse Second-level" *ngIf="hasSubmenu(sidebarnavSubItem)"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem?.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of getVisibleItem(sidebarnavSubItem.submenu)"
                            [ngClass]="[sidebarnavSubsubItem?.class ?? '']">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem?.path]"
                                *ngIf="!sidebarnavSubsubItem?.extralink"
                                [class.active]="isActive(sidebarnavSubsubItem)"
                               >
                                <i [ngClass]="[sidebarnavSubsubItem?.icon ?? '']"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem?.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>
