/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: CRM.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/Modules/CRM.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 19/11/24;
 * Updated at: 19/11/24;
 */

export function CRM() {
    return {
        enabled: true,
        label: "CRM",
        Campi: [
            {
                nome: 'Attività',
                oggetti: ['attivita'],
                modificabile: true,
                class: "col-lg-12",
                required: true
            },
            {
                nome: 'Data',
                oggetti: ['data_attivita'],
                modificabile: true,
                inputType: 'datetime-local',
                class: "col-lg-12",
                required: true
            },
            {
                nome: 'Data di chiusura',
                oggetti: ['data_chiusura'],
                hideif: {fieldnotexist: 'data_chiusura'},
                disabledif: {fieldexist: 'data_chiusura'},
                modificabile: true,
                inputType: 'datetime-local',
                class: "col-lg-12",
            },
            {
                nome: 'Attività conclusa',
                oggetti: ['att_chiusa'],
                modificabile: true,
                inputType: 'checkbox',
                class: "col-lg-12",
            },
            {
                prevDivClass: "col-md-12 mt-3",
                modificabile: true,
                nome: 'Allegato',
                oggetti: [null],
                inputType: 'file',
                placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
                visibleifProhibited: true
            },
            {
                nome: 'Note:',
                oggetti: ['note'],
                modificabile: true,
                inputType: 'textarea',
                class: "col-lg-12",
            },
            {
                source:'/anagrafica/clienti/attivita/types',
                nome: 'Tipologia',
                internalOutput: 'tipoObject',
                oggetti: ['tipo'],
                OutputField: 'tipo',
                inputType: 'singleDropdown',
                visibleifProhibited: false,
                modificabile: true,
                settings: {
                    singleSelection: true,
                    closeDropDownOnSelection: true,
                    singleOutput: true,
                    idField: 'id',
                    textField: 'label',
                    searchPlaceholderText: "Cerca",
                    allowSearchFilter: true,
                    allowRemoteDataSearch: true,
                },
                class: 'col-lg-12',
            },
            {
              nome: 'Responsabile',
              source: '/anagrafica/persone/list',
              responseHasData: true,
              parameter: [{ nome: "page", value: 1 }, { nome: "size", value: 200 }],
              internalOutput: 'responsabile',
              oggetti: ['resp_attivita'],
              OutputField: 'resp_attivita',
              OnChange: 'OnOperatoreChange',
              /*OnFilter: 'filtroManager',*/
              modificabile: true,
              inputType: 'singleDropdown',
              disabledif: { hasRoles: ['OP', 'AS', 'PS'] },
              placeholder: 'Seleziona',
              settings: {
                singleSelection: true,
                closeDropDownOnSelection: true,
                singleOutput: true,
                idField: 'id',
                textField: 'fullName',
                searchPlaceholderText: "Cerca",
                allowSearchFilter: true,
                allowRemoteDataSearch: true,
              },
              class: 'col-lg-12',
              visibleifProhibited: false
            },
        ],
        Headers: [
        ],
        Capacita: [],
        Azioni: [],
        SaveMap: (x) => {
            return x;
        },
    }
}

export var CrmEnv = () => {
   
    var env = {
        TaskAddon: {
            Crm: CRM(),
        }
    };
    return env;
}


