import { ApiRestService } from './api-rest.service';
import { Spesa } from './../Models/Spesa';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Categorie } from 'src/app/Progetti/Models/Categorie';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { ClassicType } from 'src/app/shared/models/ClassicType';
import {Fattura} from "../Models/fattura";

@Injectable({
  providedIn: 'root'
})
export class PrimaNotaStoreService {
  constructor(private ApiRestService: ApiRestService) { }
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private ADDABLE = new BehaviorSubject<boolean>(false);
  private FILTER_ENABLED = new BehaviorSubject<boolean>(true);
  private BACK_URL = new BehaviorSubject<string>("primanota/lista-spese");
  private CATEGORIE = new BehaviorSubject<Categorie[]>([]);
  private CATEGORIE_CERCATE = new BehaviorSubject<Categorie[]>([]);
  private TIPI = new BehaviorSubject<ClassicType[]>([]);
  private SPESE = new BehaviorSubject<RitornoPaginazione<Spesa[]>>({ total: 0, data: [] });
  private SPESA = new BehaviorSubject<Spesa>(null);
  private TIPI_Fattura = new BehaviorSubject<ClassicType[]>([]);
  private FATTURE = new BehaviorSubject<RitornoPaginazione<Fattura[]>>({ total: 0, data: [] });
  private FATTURA = new BehaviorSubject<Fattura>(null);
  private dataStore: {
    Editable: boolean,
    Addable: boolean,
    FilterEnabled: boolean,
    BackUrl: string,
    Categorie: Categorie[],
    CategorieCercate: Categorie[],
    Tipi: ClassicType[],
    Spese: RitornoPaginazione<Spesa[]>,
    Spesa: Spesa,
    TIPI_Fattura: ClassicType[],
    Fatture: RitornoPaginazione<Fattura[]>,
    Fattura: Fattura
  } = {
      Editable: false,
      Addable: false,
      FilterEnabled: true,
      BackUrl: "primanota/lista-spese",
      Categorie: [],
      CategorieCercate: [],
      Tipi: [],
      Spese: {total: 0, data:[]},
      Spesa: null,
      TIPI_Fattura: [],
      Fatture: {total: 0, data:[]},
      Fattura: null
    }
  readonly $editable = this.EDITABLE.asObservable();
  readonly $addable = this.ADDABLE.asObservable();
  readonly $filterEnabled = this.FILTER_ENABLED.asObservable();
  readonly $backUrl = this.BACK_URL.asObservable();
  readonly $tipi = this.TIPI.asObservable();
  readonly $categorieCercate = this.CATEGORIE_CERCATE.asObservable();
  readonly $categorie = this.CATEGORIE.asObservable();
  readonly $spese = this.SPESE.asObservable();
  readonly $spesa = this.SPESA.asObservable();
  readonly $tipi_Fattura = this.CATEGORIE.asObservable();
  readonly $fatture = this.FATTURE.asObservable();
  readonly $fattura = this.FATTURA.asObservable();
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshAddable = () => this.ADDABLE.next(Object.assign({}, this.dataStore).Addable);
  refreshFilterEnable = () => this.FILTER_ENABLED.next(Object.assign({}, this.dataStore).FilterEnabled);
  refreshBackUrl = () => this.BACK_URL.next(Object.assign({}, this.dataStore).BackUrl);
  refreshCategorie = () => this.CATEGORIE.next(Object.assign({}, this.dataStore).Categorie);
  refreshCategorieCercate = () => this.CATEGORIE_CERCATE.next(Object.assign({}, this.dataStore).CategorieCercate);
  refreshTipi = () => this.TIPI.next(Object.assign({}, this.dataStore).Tipi);
  refreshSpese = () => this.SPESE.next(Object.assign({}, this.dataStore).Spese);
  refreshSpesa = () => this.SPESA.next(Object.assign({}, this.dataStore).Spesa);
  refreshTipi_Fattura = () => this.TIPI_Fattura.next(Object.assign({}, this.dataStore).TIPI_Fattura);
  refreshFattura = () => this.FATTURA.next(Object.assign({}, this.dataStore).Fattura);
  refreshFatture = () => this.FATTURE.next(Object.assign({}, this.dataStore).Fatture);
  getTipi() {
    this.ApiRestService.getTipiSpese().subscribe(data => {
      this.dataStore.Tipi = data;
      this.refreshTipi();
    })
  }
  getSpese(value) {
    this.ApiRestService.getListaSpese(value).subscribe(data => {
      this.dataStore.Spese = data;
      this.refreshSpese();
    })
  }
  getTipiFatture() {
    this.ApiRestService.getstatiFattura().subscribe(data => {
      this.dataStore.TIPI_Fattura = data;
      this.refreshTipi_Fattura();
    })
  }
  getFatture(value) {
    this.ApiRestService.getListaFatture(value).subscribe(data => {
      this.dataStore.Fatture = data;
      this.refreshFatture();
    })
  }
  filterSpese(value) {
    this.ApiRestService.FilterListaSpese(value).subscribe(data => {
      this.dataStore.Spese = data;
      this.refreshSpese();
    })
  }
  getCategorie(value?) {
    if (value) {
      this.ApiRestService.searchCategorieSpesa(value).subscribe(data => {
        this.dataStore.CategorieCercate = data;
        this.refreshCategorieCercate();
      })
    }
    else {
      this.ApiRestService.getListCategorieSpesa().subscribe(data => {
        this.dataStore.Categorie = data;
        this.refreshCategorie();
      })
    }
  }
  updateSpesaSelezionata(value: Spesa) {
    if(value?.id)
    this.ApiRestService.getSpesa(value).subscribe(data => {
      this.dataStore.Spesa = data;
      this.refreshSpesa();
    })
    else {
      this.dataStore.Spesa = value;
      this.refreshSpesa();
    }
  }
  updateFatturaSelezionata(value: Fattura) {
    if (value?.id)
      this.ApiRestService.getFattura(value).subscribe(data => {
        this.dataStore.Fattura = data;
        this.refreshFattura();
        return;
      });
    else{
      this.dataStore.Fattura = value;
      this.refreshFattura();
    }
  }
  updateFilterEnable(value: boolean) {
    this.dataStore.FilterEnabled = value;
    this.refreshFilterEnable();
  }
  updateBackUrl(value: string) {
    this.dataStore.BackUrl = value;
    this.refreshBackUrl();
  }
  updateAddable(value: boolean) {
    this.dataStore.Addable = value;
    this.refreshAddable();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
}
