import { Valutazione } from './../models/Valutazione';
import { Filtri_Valutazioni } from './../models/Filtri_Valutazioni';
import { RitornoPaginazione } from '../../shared/models/RitornoPaginazione';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import {CheckList} from "../models/CheckList";
import {GetModel} from "../models/get-model";
import {Categorie} from "../../Progetti/Models/Categorie";
import {Check} from "../models/Check";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: this.headers()
    };
  }
  headers() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      })
  }
  getListaValutazioni(value: Filtri_Valutazioni): Observable<RitornoPaginazione<Valutazione[]>> {
    return this.http.post<RitornoPaginazione<Valutazione[]>>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/list`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getValutazione(value): Observable<GetModel> {
    return this.http.post<GetModel>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/get`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getchecklist(value): Observable<CheckList> { //"checks": [], "outcomes": []
    return this.http.post<CheckList>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/checklist`,
      JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
  }
  updateValutazione(value:Valutazione): Observable<any> {
    return this.http.post<Valutazione[]>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addValutazione(value:Valutazione): Observable<any> {
    return this.http.post<Valutazione[]>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteValutazione(value: Valutazione): Observable<any> {
    return this.http.post<Valutazione[]>(`${this.apiPaths.getBaseAPI()}/task/valutazioni/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  addCheckListCategoria(updateData: Categorie) {
    return this.http.post<Categorie[]>(`${this.apiPaths.getBaseAPI()}/task/categorie/check/add`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  updateCheckListCategoria(updateData: Categorie) {
    return this.http.post<Categorie[]>(`${this.apiPaths.getBaseAPI()}/task/categorie/check/update`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  deleteCheckListCategoria(updateData: Categorie) {
    return this.http.post<Categorie[]>(`${this.apiPaths.getBaseAPI()}/task/categorie/check/delete`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  getCheckListCategorie() {
    return this.http.get<Categorie[]>(`${this.apiPaths.getBaseAPI()}/task/categorie/check/list`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  getCheckListCategorieSearch(value) {
    return this.http.post<Categorie[]>(`${this.apiPaths.getBaseAPI()}/task/categorie/check/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/task/check/add`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  updateCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/task/check/update`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  deleteCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/task/check/delete`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  restoreCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/task/check/restore`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  getCheckList(value) {
    return this.http.post<RitornoPaginazione<Check[]>>(`${this.apiPaths.getBaseAPI()}/task/check/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  getAllCheck(value) {
    return this.http.post<RitornoPaginazione<Check[]>>(`${this.apiPaths.getBaseAPI()}/task/check/listAll`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
}
