<div
     class="angular-editor"
     #angularEditor
     [style.width]="config.width"
     [style.minWidth]="config.minWidth"
>
     <angular-editor-toolbar
          *ngIf="config.toolbarPosition === 'top'"
          #editorToolbar
          [id]="id"
          [uploadUrl]="config.uploadUrl"
          [upload]="config.upload"
          [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
          [fonts]="getFonts()"
          [customClasses]="config.customClasses"
          [defaultFontName]="config.defaultFontName"
          [defaultFontSize]="config.defaultFontSize"
          [hiddenButtons]="config.toolbarHiddenButtons"
          (execute)="executeCommand($event)"
     >
       <ng-container
         [ngTemplateOutlet]="customButtonsTemplateRef"
         [ngTemplateOutletContext]="{ executeCommandFn: this.executeCommandFn}"
       >
       </ng-container>
     </angular-editor-toolbar>

     <div
          class="angular-editor-wrapper"
          #editorWrapper
     >
          <div
               #editor
               class="angular-editor-textarea"
               [attr.contenteditable]="config.editable"
               [attr.tabindex]="disabled ? -1 : tabIndex"
               [attr.translate]="config.translate"
               [attr.spellcheck]="config.spellcheck"
               [style.height]="config.height"
               [style.minHeight]="config.minHeight"
               [style.maxHeight]="config.maxHeight"
               [style.background-color]="config.BgColor"
               [style.outline]="config.outline === false ? 'none': undefined"
               (input)="onContentChange($event.target)"
               (focus)="onTextAreaFocus($event)"
               (blur)="onTextAreaBlur($event)"
               (click)="exec()"
               (keyup)="exec()"
               (mouseout)="onTextAreaMouseOut($event)"
               (paste)="onPaste($event)"
          >
          </div>
          <span class="angular-editor-placeholder">{{ placeholder || config['placeholder'] }}</span>
     </div>
     <angular-editor-toolbar
          *ngIf="config.toolbarPosition === 'bottom'"
          #editorToolbar
          [id]="id"
          [uploadUrl]="config.uploadUrl"
          [upload]="config.upload"
          [showToolbar]="config.showToolbar !== undefined ? config.showToolbar : true"
          [fonts]="getFonts()"
          [customClasses]="config.customClasses"
          [defaultFontName]="config.defaultFontName"
          [defaultFontSize]="config.defaultFontSize"
          [hiddenButtons]="config.toolbarHiddenButtons"
          (execute)="executeCommand($event)"
     ></angular-editor-toolbar>
</div>
