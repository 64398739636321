<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title" id="editUserLabel" style="font-weight: bold">{{selected.padre ? 'Intervento' : 'RMA.title' | translate }} {{selected?.progressivo}} {{selected?.nome}}</h5>
  <button type="button" class="close" (click)="cancelClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [class.modal-body]="IsModal">
  <div class="row" *ngIf=" selected?.id && !environment.RmaAddOn['SezioniRmaEdit']">
    <div class="col-lg-6">
       <span style="font-weight: bold">{{'RMA.productData' | translate}}:</span> <br>
      &nbsp;<span style="font-weight: bold" *ngIf="selected?.seriale">{{'RMA.matricola' | translate}}:</span>&nbsp;{{selected?.seriale}}<br>
      &nbsp;<span style="font-weight: bold" *ngIf="selected?.prodotto">{{'RMA.product' | translate}}:</span>&nbsp;{{selected?.prodotto}}<br>
      &nbsp;<span style="font-weight: bold" *ngIf="selected?.rivenditore">{{'RMA.rivenditore' | translate}}:</span>&nbsp;{{selected?.rivenditore}}<br>
      &nbsp;<span style="font-weight: bold" *ngIf="selected?.acquistoObject?.label">{{'RMA.tipoAcquisto' | translate}}:</span>&nbsp;{{selected?.acquistoObject?.label}}
    </div>
    <div class="col-lg-12"></div>
  </div>
  <div class="card" *ngIf="!Card && !environment.RmaAddOn['SezioniRmaEdit']" >
    <div class="row" >
      <div class="col-lg-3" >
        <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.dInizio' | translate}}</label>
        <input type="datetime-local" [readOnly]="selected.id" #ie="ngModel" name="ip" required [(ngModel)]="selected.data_inizio_prevista" id="ip" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.CircostanzaDifetto">
        <label for="categoria" class="control-label col-form-label">{{'RMA.cDifetto' | translate }}:</label>
        <ng-multiselect-dropdown [disabled]="selected.id != undefined " required #categoria="ngModel" [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="categoria" name="categoria"
                                 [data]="difettilist" [(ngModel)]="Difetti" (ngModelChange)="onDifettoChange()">
        </ng-multiselect-dropdown>
        <!--      <small class="form-text text-danger" *ngIf="!categoria.valid ">{{'RMA.required' | translate }}</small>-->
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Brand">
        <label for="categoria" class="control-label col-form-label" >{{'RMA.brand' | translate }}:</label>
        <ng-multiselect-dropdown [disabled]="selected.id != undefined" required #categoria="ngModel" [settings]="dropdownSettings" [placeholder]="'RMA.brand' | translate" id="brand" name="categoria"
                                 [data]="Brands" [(ngModel)]="Brand">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="row" *ngIf="selected?.acquirente  && environment.RmaAddOn.Cliente.Enabled ">
      <div class="card-body col-lg-12">
        <h4 class="card-title">
          {{'RMA.cliente.title' | translate}}
        </h4>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Referente" >
        <label for="referentea" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.referente' | translate}}:</label>
        <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="selected.acquirente.referente" id="referentea" class="form-control">
        <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Nominativo" >
        <label for="nominativoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.nominativo' | translate}}:</label>
        <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="selected.acquirente.nominativo" id="nominativoa" class="form-control">
        <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Indirizzo">
        <label for="indirizzoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.indirizzo' | translate}}:</label>
        <input type="text" #indirizzo="ngModel"  required  name="indirizzo" [(ngModel)]="selected.acquirente.indirizzo" id="indirizzoa" class="form-control">
        <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Citta">
        <label for="cittaa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.citta' | translate}}:</label>
        <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="selected.acquirente.citta" id="cittaa" class="form-control">
        <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Cap">
        <label for="capa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.cap' | translate}}:</label>
        <input type="text" #cap="ngModel"  name="cap" required [(ngModel)]="selected.acquirente.cap" id="capa" class="form-control">
        <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Provincia">
        <label for="provinciaa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.provincia' | translate}}:</label>
        <input type="text" #provincia="ngModel"  name="provincia" required [(ngModel)]="selected.acquirente.provincia" id="provinciaa" class="form-control">
        <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Telefono">
        <label for="telefonoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.telefono' | translate}}:</label>
        <input type="text" #telefono="ngModel"  name="telefono" required [(ngModel)]="selected.acquirente.telefono" id="telefonoa" class="form-control">
        <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Email">
        <label for="emailaaa" class="mb-0 text-dark control-label col-form-label">
          <a *ngIf="selected?.acquirente?.email" href="mailto:{{selected.acquirente.email}}?subject=Ticket{{selected?.id?.toString()}}" class="link font-16 text-success font-medium mr-2">
          <i class="fas fa-address-card"></i>
          </a>{{'RMA.cliente.email' | translate}}:
        </label>
        <input type="text" #email="ngModel"  name="email" required [(ngModel)]="selected.acquirente.email" id="emailaaa" class="form-control">
        <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
      </div>
    </div>
    <div class="row" *ngIf="selected?.ritiro && environment.RmaAddOn.Ritiro.Enabled">
      <div class="card-body col-lg-12">
        <h4 class="card-title">
          {{'RMA.ritiro.title' | translate}}
        </h4>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Referente" >
        <label for="referente" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.referente' | translate}}:</label>
        <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="selected.ritiro.referente" id="referente" class="form-control">
        <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Nominativo" >
        <label for="nominativo" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.nominativo' | translate}}:</label>
        <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="selected.ritiro.nominativo" id="nominativo" class="form-control">
        <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Indirizzo">
        <label for="indirizzo" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.indirizzo' | translate}}:</label>
        <input type="text" #indirizzo="ngModel"  required  name="indirizzo" [(ngModel)]="selected.ritiro.indirizzo" id="indirizzo" class="form-control">
        <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Citta">
        <label for="citta" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.citta' | translate}}:</label>
        <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="selected.ritiro.citta" id="citta" class="form-control">
        <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Cap">
        <label for="cap" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.cap' | translate}}:</label>
        <input type="text" #cap="ngModel"  name="cap" required [(ngModel)]="selected.ritiro.cap" id="cap" class="form-control">
        <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Provincia">
        <label for="provincia" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.provincia' | translate}}:</label>
        <input type="text" #provincia="ngModel"  name="provincia" required [(ngModel)]="selected.ritiro.provincia" id="provincia" class="form-control">
        <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Telefono">
        <label for="telefono" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.telefono' | translate}}:</label>
        <input type="text" #telefono="ngModel"  name="telefono" required [(ngModel)]="selected.ritiro.telefono" id="telefono" class="form-control">
        <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Email">
        <label for="email" class="mb-0 text-dark control-label col-form-label">
          <a *ngIf="selected?.ritiro?.email" href="mailto:{{selected.ritiro.email}}?subject=Ticket{{selected?.id?.toString()}}" class="link font-16 text-success font-medium mr-2">
            <i class="fas fa-address-card"></i>
          </a>
          {{'RMA.ritiro.email' | translate}}:
        </label>
        <input type="text" #email="ngModel"  name="email" required [(ngModel)]="selected.ritiro.email" id="email" class="form-control">
        <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Chiusura">
        <label for="chiusura" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.chiusura' | translate}}:</label>
        <input type="text" #chiusura="ngModel"  name="chiusura" required [(ngModel)]="selected.ritiro.chiusura" id="chiusura" class="form-control">
        <small class="form-text text-danger" *ngIf="!chiusura.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Disponibilita">
        <label for="disponibilita" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.disponibilita' | translate}}:</label>
        <input type="text" #disponibilita="ngModel"  name="disponibilita" required [(ngModel)]="selected.ritiro.disponibilita" id="disponibilita" class="form-control">
        <small class="form-text text-danger" *ngIf="!disponibilita.valid">{{'RMA.required' | translate }}</small>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-6" *ngIf="DescRequired && environment.RmaAddOn.DescrizioneDifetto">
        <label for="desc" class="control-label col-form-label">{{'RMA.descDifetto' | translate}}</label>
        <textarea type="text" #desc="ngModel" [readOnly]="selected.id" rows="10" name="desc" required [(ngModel)]="selected.difettoDesc" id="desc" class="form-control"></textarea>
        <small class="form-text text-danger" *ngIf="!desc.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-12" *ngIf="environment.RmaAddOn.Difetto">
        <label for="note" class="mb-0 text-dark control-label col-form-label">{{'RMA.difetto' | translate }}:</label>
        <textarea type="text" #note="ngModel" [readOnly]="selected.id" rows="10" required="required" name="note" [placeholder]="'RMA.difetto' | translate " [(ngModel)]="selected.note" id="note" class="form-control"></textarea>
        <small class="form-text text-danger" *ngIf="!note.valid ">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Telefono">
        <label for="telefonoq" class="mb-0 text-dark control-label col-form-label">{{'RMA.tel' | translate }}:</label>
        <input type="text" #telefono="ngModel" name="telefono" required [(ngModel)]="selected.telefono" placeholder="{{'RMA.tel' | translate }}:" id="telefonoq" class="form-control">
        <!--      <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>-->
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.dfinep && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
        <label for="fp" class="mb-0 text-dark control-label col-form-label">{{'RMA.dfinep' | translate }} </label>
        <input type="datetime-local" #fp="ngModel" [readOnly]="!selected.data_inizio_prevista"  name="fp" required [(ngModel)]="selected.data_fine_prevista" id="fp" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.diniziop &&  !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
        <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.diniziop' | translate }}:</label>
        <input type="datetime-local" #ie="ngModel" name="ie" required [(ngModel)]="selected.data_inizio_prevista" id="dip" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.dapertura_c">
        <label for="dac" class="mb-0 text-dark control-label col-form-label">{{'RMA.dapertura_c' | translate }} </label>
        <input type="datetime-local" #fp="ngModel" [readOnly]="true" name="fp" required [(ngModel)]="selected.data_apertura_centro" id="dac" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.dinizioe &&  !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
        <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.dInizioe' | translate }}:</label>
        <input type="datetime-local" #ie="ngModel" name="ie" required [(ngModel)]="selected.data_inizio_effettiva" id="ie" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.dfinee && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
        <label for="ie" class="mb-0 text-dark control-label col-form-label">{{'RMA.dfinee' | translate }} </label>
        <input type="datetime-local" #ie="ngModel" [readOnly]="!selected.data_inizio_effettiva" name="fe" required [(ngModel)]="selected.data_fine_effettiva" id="fe" class="form-control">
      </div>
      <div class="col-lg-3" *ngIf="selected.id && environment.RmaAddOn.Assistenza.Tecnico">
        <label for="dat" class="mb-0 text-dark control-label col-form-label">{{'RMA.dassegnazione_t' | translate }} </label>
        <input type="datetime-local" #fp="ngModel" [readOnly]="true" name="fp" required [(ngModel)]="selected.data_assegnazione_tec" id="dat" class="form-control">
      </div>
      <div class="col-lg-2" *ngIf="environment.RmaAddOn.Garanzia">
        <label for="garanzia" class="mb-0 text-dark control-label col-form-label">{{'RMA.garanzia' | translate }}:</label>
        <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="selected.garanzia"  id="garanzia" class="form-control">
      </div>
      <div class="col-lg-2"*ngIf="selected.id && !permission.isOperativeRole('C') && !permission.isOperativeRole('AC')">
        <label for="priorita" class="mb-0 text-dark control-label col-form-label">{{'RMA.priorita' | translate }}</label>
        <input type="checkbox" #garanzia="ngModel"  name="garanzia" [(ngModel)]="priorita" id="priorita" class="form-control">
      </div>
      <div class="col-lg-2" *ngIf="environment.RmaAddOn.chiuso && !this.permission.hasOperativeRoles(environment.ClientiType)">
        <label for="chiuso" class="mb-0 text-dark control-label col-form-label">{{'RMA.chiuso' | translate }}:</label>
        <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="selected.chiuso"  id="chiuso" class="form-control">
      </div>
      <div class="col-lg-12" *ngIf="selected.id && !this.permission.hasOperativeRoles(['C', 'AC', 'CA'])">
        <label for="note" class="mb-0 text-dark control-label col-form-label">{{'RMA.noteInterne' | translate }}</label>
        <textarea type="text" #note="ngModel" [readOnly]="!selected.id" rows="10" name="note" [(ngModel)]="selected.noteTecnico" id="notet" class="form-control"></textarea>
        <small class="form-text text-danger" *ngIf="!note.valid ">{{'RMA.required' | translate}}</small>
      </div>
      <br>
      <div class="col-lg-12"></div>
      <table class="table bg-white table-hover align-middle mb-0 no-wrap">
        <tbody>
        <tr *ngIf="selected?.ricevutaId">
          <td class="align-middle" style="width: 20%">
                  <span  class="font-16 font-weight-bold">
                    {{'RMA.downloadRicevuta' | translate }}&nbsp;&nbsp;
                  </span>
            <a href="javascript:void(0)" class="link" (click)='download(selected.ricevutaId)'>
              <i-feather name="download" class="feather-sm"></i-feather>
            </a>&nbsp;
            <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="view(selected.ricevutaId)">
              <i class="fas fa-eye"></i>
            </a>
          </td>
          <td class="align-middle">
            <div class="el-element-overlay" >
              <div class="el-card-item" >
                <div class="el-card-avatar el-overlay-1 d-flex justify-items-center" >
                  <img style="max-height: 200px; width: auto;" [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.ricevutaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"  alt="" />
                  <pdf-viewer
                    [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.ricevutaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token" [render-text]="true" [external-link-target]="'blank'"
                    style=" display: block; width: 100%; height: 200px; "
                  ></pdf-viewer>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="selected?.targhettaId">
          <td class="align-middle" style="width: 20%">
                  <span  class="font-16 font-weight-bold">
                    {{'RMA.downloadTarghetta' | translate }}&nbsp;&nbsp;
                  </span>
            <a href="javascript:void(0)" class="link" (click)='download(selected.targhettaId)'>
              <i-feather name="download" class="feather-sm"></i-feather>
            </a> &nbsp;
            <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="view(selected.targhettaId)">
              <i class="fas fa-eye"></i>
            </a>
          </td>
          <td class="align-middle">
            <div class="el-element-overlay" >
              <div class="el-card-item" >
                <div class="el-card-avatar el-overlay-1  d-flex justify-items-center" >
                  <img style="max-height: 200px; width: auto;" [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.targhettaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"  alt="" />
                  <pdf-viewer
                    [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.targhettaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
                    [render-text]="true" [external-link-target]="'blank'" style="display: block;"
                    style="width: 100%; height: 200px;"
                  ></pdf-viewer>
                  <!--<div class="el-overlay" >
                    <ul class="list-style-none el-info">
                      <li class="el-item">
                        <a class="btn default btn-outline image-popup-vertical-fit el-link" [href]="apiPaths?.getBaseAPI() + '/file/download/' + selected.targhettaId?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token">
                          <i class="icon-magnifier"></i>
                        </a>
                      </li>
                    </ul>
                  </div>-->
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="selected?.estensione">
          <td class="align-middle" style="width: 20%">
                  <span  class="font-16 font-weight-bold">
                    {{'RMA.downloadEgaranzia' | translate }}&nbsp;&nbsp;
                  </span>
            <a href="javascript:void(0)" class="link" (click)='download(selected.estensione.id)'>
              <i-feather name="download" class="feather-sm"></i-feather>
            </a>
            <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="view(selected.estensione.id)">
              <i class="fas fa-eye"></i>
            </a>
          </td>
          <td class="align-middle">
            <div class="el-element-overlay" >
              <div class="el-card-item" >
                <div class="el-card-avatar el-overlay-1" >
                  <img style="max-height: 200px; width: auto;" [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.estensione.id?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"  alt="" />
                  <pdf-viewer
                    [src]="apiPaths?.getBaseAPI() + '/file/download/' + selected.estensione.id?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
                    [render-text]="true" [external-link-target]="'blank'" style="display: block;"
                    style="width: 100%; height: 200px;"
                  ></pdf-viewer>
                  <!--<div class="el-overlay" >
                    <ul class="list-style-none el-info">
                      <li class="el-item">
                        <a class="btn default btn-outline image-popup-vertical-fit el-link" [href]="apiPaths?.getBaseAPI() + '/file/download/' + selected.estensione.id?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token">
                          <i class="icon-magnifier"></i>
                        </a>
                      </li>
                    </ul>
                  </div>-->
                </div>
              </div>
            </div>
          </td>
        </tr>

        </tbody>
      </table>

    </div>
    <app-file-uploader [url]="'/rma/rma/file'" [(id)]="selected.id" *ngIf="selected.id && !permission.isOperativeRoles(['C', 'AC']) && permission.isPermitted('lista-file')" (onChange)="onFileChanged()"></app-file-uploader>
    <div class="row" *ngIf="selected?.assistenza && environment.RmaAddOn.Assistenza.Enabled && !permission.isOperativeRoles(['C', 'AC'])">
      <div class="col-lg-8"  >
        <label for="categoria" class="control-label col-form-label">{{'RMA.ca.title' | translate }}:</label>
        <ng-multiselect-dropdown #categoria="ngModel" [disabled]="this.permission.isOperativeRoles(['CA', 'C', 'AC'])" [settings]="ClienteDropdownSettings" id="ass" name="categoria"
                                 [data]="Assistenze" [(ngModel)]="Assistenza" (ngModelChange)="CentroAssistenzaCambiato()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-1 d-flex align-items-end"  >
        <a href="javascript:void(0)" class="link font-16 text-danger font-medium mr-2" (click)="OpenMap()">
          <i class="fas fa-map"></i>
        </a>
        <a *ngIf="selected?.assistenza?.email" href="mailto:{{selected.assistenza.email}}?subject=Ticket{{selected?.id?.toString()}}" class="link font-16 text-success font-medium mr-2">
          <i class="fas fa-address-card"></i>
        </a>
      </div>

      <div class="col-lg-3">
        <label for="categoria" *ngIf="selected.id  && Assistenza?.length > 0 && environment.RmaAddOn.Assistenza.Tecnico" class="control-label col-form-label">{{'RMA.tecnico' | translate }}:</label>
        <ng-multiselect-dropdown *ngIf="selected.id && Assistenza?.length > 0 && environment.RmaAddOn.Assistenza.Tecnico"
                                 required #tecnico="ngModel" [settings]="TecniciDropdownSettings" [placeholder]="'Seleziona'" id="tecnico" name="tecnico"
                                 [data]="tecnici" [(ngModel)]="selectedTecnici" (ngModelChange)="onTecnicoChanged()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Referente" >
        <label for="referenteaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.referente' | translate}}:</label>
        <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="selected.assistenza.referente" id="referenteaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Nominativo" >
        <label for="nominativoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.nominativo' | translate}}:</label>
        <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="selected.assistenza.nominativo" id="nominativoaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Indirizzo">
        <label for="indirizzoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.indirizzo' | translate}}:</label>
        <input type="text" #indirizzo="ngModel"  required  name="indirizzo" [(ngModel)]="selected.assistenza.indirizzo" id="indirizzoaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Citta">
        <label for="cittaaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.citta' | translate}}:</label>
        <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="selected.assistenza.citta" id="cittaaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Cap">
        <label for="capaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.cap' | translate}}:</label>
        <input type="text" #cap="ngModel"  name="cap" required [(ngModel)]="selected.assistenza.cap" id="capaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Provincia">
        <label for="provinciaaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.provincia' | translate}}:</label>
        <input type="text" #provincia="ngModel"  name="provincia" required [(ngModel)]="selected.assistenza.provincia" id="provinciaaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Telefono">
        <label for="telefonoaass" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.telefono' | translate}}:</label>
        <input type="text" #telefono="ngModel"  name="telefono" required [(ngModel)]="selected.assistenza.telefono" id="telefonoaass" class="form-control">
        <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
      </div>
      <div class="col-lg-3" *ngIf="environment.RmaAddOn.Assistenza.Email">
        <label for="emaila" class="mb-0 text-dark control-label col-form-label">{{'RMA.ca.email' | translate}}:</label>
        <input type="text" #email="ngModel"  name="email" required [(ngModel)]="selected.assistenza.email" id="emaila" class="form-control">
        <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
      </div>
    </div>
    <div *ngIf="selected?.id && selected?.photo?.length > 0 && environment.RmaAddOn.Allegati">
      <div class="d-md-flex align-items-center bg-white p-2" >
        <h4 class="d-flex align-items-center p-2 ">{{'RMA.allegati' | translate}}</h4>
      </div>
      <div class="table-responsive" *ngIf="selected.photo">
        <table class="table bg-white table-hover align-middle mb-0 no-wrap">
          <tbody>
          <tr *ngFor="let photo of selected.photo; let i = index">
            <td class="align-middle">
              <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="download(photo)">
                <i class="fas fa-download"></i>
              </a>
              <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="view(photo)">
                <i class="fas fa-eye"></i>
              </a>
            </td>
            <td>
              <div class="el-element-overlay" >
                <div class="el-card-item" >
                  <div class="el-card-avatar el-overlay-1" style="max-height: 200px; width: 500px;">
                    <img style="max-height: 200px; width: auto;" [src]="apiPaths?.getBaseAPI() + '/file/download/' + photo?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"  alt="" />
                    <pdf-viewer [src]="apiPaths?.getBaseAPI() + '/file/download/' + photo?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token"
                                [render-text]="true" [external-link-target]="'blank'" style="display: block;"
                                style="width: 100%; height: 200px;">
                    </pdf-viewer>
                    <!--<div class="el-overlay" *ngIf="">
                      <ul class="list-style-none el-info">
                        <li class="el-item">
                          <a class="btn default btn-outline image-popup-vertical-fit el-link" [href]="apiPaths?.getBaseAPI() + '/file/download/' + photo?.toString()+ '?token=' + authSvc?.currentUserValue?.access_token">
                            <i class="icon-magnifier"></i>
                          </a>
                        </li>
                      </ul>
                    </div>-->
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <app-voci-costo *ngIf="selected?.id && selected?.voci && environment.RmaAddOn.vociDiCosto && !permission.isOperativeRoles(['C','AC'])" [(myProp)]="selected.voci"></app-voci-costo>
    <app-attivita-rma *ngIf="selected?.id && permission.isPermitted('gestione-segnalazione') && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask" [(ListaScadenze)]="selected.figli" (AddButtonClick)="ScadAddButton()" (Updated)="Refresh()"></app-attivita-rma>

 <!-- <hr *ngIf="selected?.id && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask">
  <app-attivita-rma *ngIf="selected?.id && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask" [ListaScadenze]="selected.figli" (AddButtonClick)="ScadAddButton()" (Updated)="updated.emit()"></app-attivita-rma>-->
  </div>
  <ng-container *ngIf="environment.RmaAddOn['SezioniRmaEdit']">
    <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)" (OnChange)="ChangeHandler($event)" [(Campi)]="environment.RmaAddOn['SezioniRmaEdit']" [(Oggetto)]="selected"></app-sector-viewer>
   <div *ngIf="environment.RmaAddOn['ProveAcquistoMultipleRma'] && selected?.acquirente" >
      <div class="row" style="background-color: #87858525; padding-bottom: 20px;">
        <div class="col-lg-3 d-flex align-items-end" *ngIf="selected?.sedeId">
          <label for="categoria" class="control-label col-form-label">
            <span class="d-flex align-items-center p-2 font-weight-bold font-14">
                {{'provaAcquisto.title'|translate}}: &nbsp;
                <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{selected.proveObject.length}}</span>
            </span>
          </label>
        </div>
        <div class="col-lg-6" *ngIf="selected?.sedeId">
          <ng-multiselect-dropdown [settings]="PAdropdownSettings" [placeholder]="'Seleziona'"
                                   [data]="ApprestamentiSelezionabili()" [(ngModel)]="selectedPA" >
          </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3 d-flex align-items-end" *ngIf="selected?.sedeId">
          <div class="d-flex align-items-end">
            <button type="submit" (click)="addPA(selectedPA)" class="ml-auto btn btn-outline-dark p-2 waves-effect waves-light">
              <span class="ml-1"> Aggiungi </span> &nbsp;
              <i class="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>
      <app-lista-prova-acquisto [(List)]="selected.proveObject" [Headers]="environment.RmaAddOn['ListProvaAcquistoRmaField']" [Cliente]="selected.acquirente" [isModifyDisabled]="true" [Local]="true" [HasFilter]="false" [HasHeader]="false" [(Sede)]="selected.sedeId" ></app-lista-prova-acquisto>
    </div>
    <div *ngIf="selected?.id && environment.RmaAddOn['InterventiFigli'] && ! selected?.padre ">
      <div class="d-md-flex align-items-center  p-2" style="background-color: #ffffff;">
        <div class="ml-auto mt-3 mt-md-0" *ngIf="!permission.isOperativeRole('CA')">
          <button type="button" (click)="AddRma()" [disabled]="!CanAddFigli()" style="margin-bottom: -90px;" [ngbTooltip]="CanAddFigli()? '': 'Per Aggiungere un intervento bisogna aver spuntato i 4 check di controllo'"  class="btn btn-cyan rounded-pill p-2" ><i
            class="fas fa-plus  font-14 mr-1 text-white"></i>
            <span class="ml-1 text-white font-weight-bold">{{'RMA.add' | translate}}</span>
          </button>
        </div>
      </div>
        <app-rma-list  [(Padre)]="selected.id" [filtri]="{page:0, size:100, tipo: 'R', figli: true}"></app-rma-list>
    </div>
    <app-esiti-rma *ngIf="environment.RmaAddOn['sottoTask'] && selected?.id && !selected.padre && permission.isPermitted('gestione-segnalazione') && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask" [(ListaScadenze)]="selected.figli" [editEnabled]=" !environment.RmaAddOn['esitoLock'] || (permission.itsme_mario(selected?.managerObject?.id) &&  (!selected['chkDoc'] || permission.isOperativeRoles(['AZ'])) && !(permission.HasCapacity(['tecnico']) && !selected['data_assegnazione_tecnico']))" [checkSelezionate]="selected.attivita" (AddButtonClick)="EsitoAddButton()" (Updated)="Refresh()"></app-esiti-rma>
  </ng-container>
</div>

<div class="d-flex align-items-center justify-content-end m-t-10 m-b-10 m-r-10">
  <button (click)="CaricaModello()" *ngIf="environment.RmaAddOn['Modelli'] && permission.HasCapacity(['gestione-commesse-incorso']) && !selected['chkDoc']&& !selected['chiuso']" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Carica Modello </span>
    <i-feather name="upload" class="feather-md "></i-feather>
  </button>
  <button type="submit" *ngIf="showAnagrafica && !permission.isOperativeRoles(['AG'])"  (click)="save()" class=" btn btn-success   waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.save' | translate}} </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
<!--  <a href="mailto:{{selected.clienteObject.pec ?? selected.clienteObject.email ?? 'noEmailFound@verificaspa.it'}}?subject={{'Accettazione Vs. proposta contrattuale per la verifica degli impianti elettrici di cui al DPR 462/01 del 22/10/2001 e assegnazione numero di pratica per impianto sito in:\n' }}&body={{getTextPec()}}" type="submit"  class=" btn btn-rounded btn-info p-2 waves-effect waves-light" *ngIf="hasPecButton()">-->
<!--    <span class="ml-1 text-white font-weight-bold"> Invia Pec </span>-->
<!--    <i class="far fa-address-card "></i>-->
<!--  </a>-->
  <ng-container *ngIf="environment.RmaAddOn['Pulsanti']">
    <ng-container *ngFor="let button of environment.RmaAddOn['Pulsanti']">
      <button type="button" [ngClass]="button['class']" *ngIf="permission.HasCapacity(button['capacita']) && campoUtility.ButtonShow(button,selected)" (click)='customButton(button)'>
        <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
        <span [ngClass]="button['labelClass']"> {{button['nome'] | translate}} </span>
      </button>
    </ng-container>
  </ng-container>
  <button type="submit" *ngIf="!showAnagrafica && !permission.isOperativeRoles(['AG'])" (click)="avanti()" class=" btn  btn-success  waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.avanti' | translate}} </span>
  </button>
  <app-stampa [Stampe]="stampe" *ngIf="stampe?.length > 0"></app-stampa>
</div>
