import { Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform, TemplateRef, ViewChild } from "@angular/core";
import { CampoUtility, CheckObject } from "../models/campo-aggiuntivo";
import { Azienda } from "src/app/Admin/models/Azienda";
import { Console } from "console";
import { DynamicCrudService } from "../_services/dynamic-crud.service";

@Component({
    selector: 'app-dynamic-tabs',
    templateUrl: './dynamic-tabs.component.html'
})
export class DynamicTabsComponent implements OnInit {
  @Input() TaskSettings: {
    persona?: number, 
    cliente?: number, 
    clienteObject?: Azienda,
    hideFiltriFe?: boolean,
  } = {
    persona: null, 
    cliente: null, 
    clienteObject: null,
    hideFiltriFe: null,
  }
  @Input() CrudSetting: any = {ConcatParams: {}, filtro: {}};
  @Input() activeId: string = 'info';
  @Input() Padre: any;
  @Output() PadreChange = new EventEmitter<any>()
  @Output() OnChange = new EventEmitter<any>()
  @Output() CustomButtonClick = new EventEmitter<{Button: any, Item: any }>()
  @Input() Tabs: Tab[]
  @Input() AvailableTemplates: {
      id: string,
      template: TemplateRef<any> | any
    }[]
  @ViewChild('nontrovato', { static: true }) NotFound!: TemplateRef<any>;

  constructor (
      private campoUtility: CampoUtility,
      private crudService: DynamicCrudService
  ) {}
  GetFiltro(filter){
    return Object.assign(filter ?? {}, this.CrudSetting?.filtro ?? {});

  }
  GetTemplate(id: string){
      return this.AvailableTemplates?.find(x=>x.id == id)?.template ?? this.NotFound;
  }
  public ShowTab(tab: Tab){
    const result = this.campoUtility.Show(tab, Object.assign(this.GetFiltro(tab.filtro) ?? {}, this.Padre ?? {}), null );
    return result;
  }
  ngOnInit(): void {
      
  }
  customButton(item, button) {
    this.CustomButtonClick?.emit({Button: button, Item: item });
  }
}

@Pipe({
  name: 'ShowTabPipe',
  pure: false
})
export class ShowTabPipe implements PipeTransform {
  constructor(private tabComponent: DynamicTabsComponent){}
  transform(items: Tab[], filter: Object): any {
    if (!items) {return items;}
    var result = items?.filter(x=> this.tabComponent.ShowTab(x));
    return result;
  }
}

export class Tab{
  id: string;
  tipo: 'task' | 'crud' | 'campi' | 'sezioni' | 'template' | 'component' | 'tab';
  params?: any;
  titolo: string;
  component?: any;
  filtri?: any[];//campi 
  filtro?: {};
  hideif?: CheckObject;
  sezioni?: any[]; 
  campi?: any[];
  crud?: string;
  Azioni?:any[];
}