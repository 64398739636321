import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ScadenzeStoreService} from "../../Scadenzario/_services/scadenze-store.service";
import {RmaStoreService} from "../_services/rma-store.service";
import {RmaEditComponent} from "../rma-edit/rma-edit.component";
import {EditAttivitaRmaComponent} from "../edit-attivita-rma/edit-attivita-rma.component";
import {ApiRestService} from "../_services/api-rest.service";
import {environment} from "../../../environments/environment";
import {Permission} from "../../Login/_guards/Permission";
import {CampoUtility} from "../../shared/models/campo-aggiuntivo";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../api-paths.service";
import {Task} from "../../task/models/Task";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-attivita-rma',
  templateUrl: './attivita-rma.component.html',
  styleUrls: ['./attivita-rma.component.css']
})
export class AttivitaRmaComponent implements OnInit {
  @Input() ListaScadenze: Scadenza[] = [];
  @Output() ListaScadenzeChange= new EventEmitter<Scadenza[]>();
  @Input() Padre: any = null;
  @Output() AddButtonClick = new EventEmitter<Scadenza>();
  @Output() Updated = new EventEmitter<Scadenza>();
  @Input() hideAdd: boolean;
  environment = environment;
  constructor(
    private modalService: NgbModal,
    private scadenzeStore: ScadenzeStoreService,
    private RmaStore: RmaStoreService,
    private apirestservice: ApiRestService,
    public permission: Permission,
    public campoUtility: CampoUtility,
    public toastrService: ToastrService,
    private apiPaths: ApiPathsService,
    private AuthSvc: AuthenticationService
  ) { }



  ngOnInit(): void {
  }

  customButton(selected, button: { type?: 'GET'| 'POST', restRoute?: string, preMex?: string, successMex?: string, parameter?: any[], preAlert: string}) {
    if(selected && (!button?.preAlert || confirm(button.preAlert))){

        var filter ={};
        button.parameter?.forEach(param=>{
          var p = param?.parameter?.split('.');
          var parameter = Object.assign({}, selected)
          p?.forEach(el=>{
            parameter = this.getparam(parameter,el);
          })
          filter[param.nome] = param.value ?? parameter
        })
        if(button.preMex)
          this.toastrService.warning(button.preMex);
        if(button.type == 'GET' && button.restRoute)
          this.apiPaths.ClassicGet(button.restRoute).subscribe(data=>{
            if(button.successMex)
              this.toastrService.success(button.successMex);
            this.Updated.emit(data);

          })
        if(button.type == 'POST' && button.restRoute)
          this.apiPaths.ClassicPost(button.restRoute,filter).subscribe(data=>{
            if(button.successMex)
              this.toastrService.success(button.successMex);
            this.Updated.emit(data);
          })

    }

  }


  getparam(oggetto, param){
    return oggetto ? oggetto[param]: null;
  }
  headShow(campo): boolean{
    return true;
   /* if(!campo['filtertype'] && !campo['filternottype'])
      return true;
    var filterType = !campo['filtertype'] || (!Array.isArray(campo['filtertype']) &&  (campo['filtertype'] == this.type || campo['filtertype'] == this.filter)) || (Array.isArray(campo['filtertype']) && campo['filtertype']?.some(x=> x == this.type || x == this.filter))
    var filternotType = !campo['filternottype'] || (!Array.isArray(campo['filternottype']) &&  campo['filternottype'] != this.type && campo['filternottype'] != this.filter) || (Array.isArray(campo['filternottype']) && (campo['filternottype']?.every(x=> x != this.type && x != this.filter)))
    return filterType && filternotType;*/


  }
  tag(x: Task): boolean {
    return  x?.note?.includes('@' + this.AuthSvc.currentUserValue.username) && !x.data_fine_effettiva;
  }

  whoismyfather(){
    console.log(this.Padre)
    if (this.Padre && !this.Padre.rma)
      this.ScadModBtn(this.Padre)
    else if (this.Padre && this.Padre.rma) {
      this.RmaStore.updateSelected(this.Padre);
      const editmodal = this.modalService.open(RmaEditComponent, {
        centered: true,
        backdrop: 'static',
        size: 'xl',
      });
      (<RmaEditComponent>editmodal.componentInstance).IsModal = true;
      (<RmaEditComponent>editmodal.componentInstance).added.subscribe(data=>{});
      (<RmaEditComponent>editmodal.componentInstance).updated.subscribe(data=> {});
    }
  }

  ScadAddButton() {
    this.AddButtonClick.emit();
  }
  ScadModBtn(event) {
    const editmodal = this.modalService.open(EditAttivitaRmaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditAttivitaRmaComponent>editmodal.componentInstance).IsModal = true;
    (<EditAttivitaRmaComponent>editmodal.componentInstance).Item = Object.assign({},event);
    (<EditAttivitaRmaComponent>editmodal.componentInstance).added.subscribe(data=>{});
    (<EditAttivitaRmaComponent>editmodal.componentInstance).updated.subscribe(data=> {this.Updated.emit()});
  }

  Delete(scadenza) {
    if(confirm('Sei sicuro di voler eliminare questo elemento?'))
      this.apirestservice.DeleteSegnalazione(scadenza).subscribe( data=> this.Updated.emit())
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.ListaScadenze, event.previousIndex, event.currentIndex);

    this.apiPaths.ClassicPost('/rma/segnalazione/order',{ids: this.ListaScadenze?.map(x=> x.id)}).subscribe(()=> this.Updated.emit());
  }

}
