<div class="modal-header">
  <h5 class="modal-title" id="editUserLabel">{{Rma.padre ? 'Intervento' : 'RMA.title' | translate }} </h5>
  <button type="button" class="close" (click)="cancelClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="!environment.RmaAddOn['CampiRmaAdd']">
  <div class="card" *ngIf="Rma.acquirente && environment.RmaAddOn.Cliente.Enabled && !(this.permission.isOperativeRoles(['AC','C', 'AG']) && !environment.RmaAddOn.Cliente.Referente && !environment.RmaAddOn.Cliente.Chiusura && !environment.RmaAddOn.Cliente.Disponibilita )">
    <h4 class="card-title">{{'RMA.cliente.title' | translate}}</h4>
    <div class="card-body">
        <div class="row" >
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Referente" >
            <label for="referentea" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.referente' | translate}}:</label>
            <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="Rma.acquirente.referente" id="referentea" class="form-control">
            <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Nominativo && !this.permission.isOperativeRoles(['AC','C', 'AG'])" >
            <label for="nominativoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.nominativo' | translate}}:</label>
            <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="Rma.acquirente.nominativo" id="nominativoa" class="form-control">
            <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Indirizzo && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="indirizzoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.indirizzo' | translate}}:</label>
            <input type="text" #indirizzo="ngModel"  required  name="indirizzo" [(ngModel)]="Rma.acquirente.indirizzo" id="indirizzoa" class="form-control">
            <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Citta && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="cittaa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.citta' | translate}}:</label>
            <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="Rma.acquirente.citta" id="cittaa" class="form-control">
            <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Cap && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="capa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.cap' | translate}}:</label>
            <input type="text" #cap="ngModel"  name="cap" required [(ngModel)]="Rma.acquirente.cap" id="capa" class="form-control">
            <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Provincia && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="provinciaa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.provincia' | translate}}:</label>
            <input type="text" #provincia="ngModel"  name="provincia" required [(ngModel)]="Rma.acquirente.provincia" id="provinciaa" class="form-control">
            <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Telefono && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="telefonoa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.telefono' | translate}}:</label>
            <input type="text" #telefono="ngModel"  name="telefono" required [(ngModel)]="Rma.acquirente.telefono" id="telefonoa" class="form-control">
            <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Email && !this.permission.isOperativeRoles(['AC','C', 'AG'])">
            <label for="emaila" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.email' | translate}}:</label>
            <input type="text" #email="ngModel"  name="email" required [(ngModel)]="Rma.acquirente.email" id="emaila" class="form-control">
            <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Chiusura">
            <label for="chiusuraa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.chiusura' | translate}}:</label>
            <input type="text" #chiusura="ngModel"  name="chiusura" required [(ngModel)]="Rma.acquirente.chiusura" id="chiusuraa" class="form-control">
            <small class="form-text text-danger" *ngIf="!chiusura.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Cliente.Disponibilita">
            <label for="disponibilitaa" class="mb-0 text-dark control-label col-form-label">{{'RMA.cliente.disponibilita' | translate}}:</label>
            <input type="text" #disponibilita="ngModel"  name="disponibilita" required [(ngModel)]="Rma.acquirente.disponibilita" id="disponibilitaa" class="form-control">
            <small class="form-text text-danger" *ngIf="!disponibilita.valid">{{'RMA.required' | translate }}</small>
          </div>
        </div>
        <br>
        <br>

    </div>
  </div>
  <div class="card" *ngIf="Rma.ritiro && environment.RmaAddOn.Ritiro.Enabled">
    <h4 class="card-title">{{'RMA.ritiro.title' | translate}}</h4>
    <div class="card-body">
        <div class="row" >
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Referente" >
            <label for="referente" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.referente' | translate}}:</label>
            <input type="text" #referente="ngModel" name="referente" required [(ngModel)]="Rma.ritiro.referente" id="referente" class="form-control">
            <small class="form-text text-danger" *ngIf="!referente.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Nominativo" >
            <label for="nominativo" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.nominativo' | translate}}:</label>
            <input type="text" #nominativo="ngModel" name="nominativo" required [(ngModel)]="Rma.ritiro.nominativo" id="nominativo" class="form-control">
            <small class="form-text text-danger" *ngIf="!nominativo.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Indirizzo">
            <label for="indirizzo" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.indirizzo' | translate}}:</label>
            <input type="text" #indirizzo="ngModel"  required  name="indirizzo" [(ngModel)]="Rma.ritiro.indirizzo" id="indirizzo" class="form-control">
            <small class="form-text text-danger" *ngIf="!indirizzo.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Citta">
            <label for="citta" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.citta' | translate}}:</label>
            <input type="text" #citta="ngModel" name="citta" required [(ngModel)]="Rma.ritiro.citta" id="citta" class="form-control">
            <small class="form-text text-danger" *ngIf="!citta.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Cap">
            <label for="cap" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.cap' | translate}}:</label>
            <input type="text" #cap="ngModel"  name="cap" required [(ngModel)]="Rma.ritiro.cap" id="cap" class="form-control">
            <small class="form-text text-danger" *ngIf="!cap.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Provincia">
            <label for="provincia" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.provincia' | translate}}:</label>
            <input type="text" #provincia="ngModel"  name="provincia" required [(ngModel)]="Rma.ritiro.provincia" id="provincia" class="form-control">
            <small class="form-text text-danger" *ngIf="!provincia.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Telefono">
            <label for="telefono" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.telefono' | translate}}:</label>
            <input type="text" #telefono="ngModel"  name="telefono" required [(ngModel)]="Rma.ritiro.telefono" id="telefono" class="form-control">
            <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Email">
            <label for="email" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.email' | translate}}:</label>
            <input type="text" #email="ngModel"  name="email" required [(ngModel)]="Rma.ritiro.email" id="email" class="form-control">
            <small class="form-text text-danger" *ngIf="!email.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Chiusura">
            <label for="chiusura" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.chiusura' | translate}}:</label>
            <input type="text" #chiusura="ngModel"  name="chiusura" required [(ngModel)]="Rma.ritiro.chiusura" id="chiusura" class="form-control">
            <small class="form-text text-danger" *ngIf="!chiusura.valid">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Ritiro.Disponibilita">
            <label for="disponibilita" class="mb-0 text-dark control-label col-form-label">{{'RMA.ritiro.disponibilita' | translate}}:</label>
            <input type="text" #disponibilita="ngModel"  name="disponibilita" required [(ngModel)]="Rma.ritiro.disponibilita" id="disponibilita" class="form-control">
            <small class="form-text text-danger" *ngIf="!disponibilita.valid">{{'RMA.required' | translate }}</small>
          </div>
        </div>
        <br>
        <br>

    </div>
  </div>
  <div class="card" >
    <h4 class="card-title">{{'RMA.guasto' | translate}}</h4>
    <div class="card-body">
        <div class="row">
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.CircostanzaDifetto &&( permission.isOperativeRole(ruolocompleto)|| !environment.RmaAddOn.provaAcquisto)">
            <label for="categoria" class="control-label col-form-label">{{'RMA.cDifetto' | translate }}:</label>
            <ng-multiselect-dropdown [disabled]="Rma.id" required #categoria="ngModel" [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="categoria" name="categoria"
                                     [data]="DropdownSources.difettilist" [(ngModel)]="Difetti" (ngModelChange)="onDifettoChange()">
            </ng-multiselect-dropdown>
            <!--      <small class="form-text text-danger" *ngIf="!categoria.valid ">{{'RMA.required' | translate }}</small>-->
          </div>
          <div class="col-lg-3"*ngIf="environment.RmaAddOn.Brand &&( permission.isOperativeRole(ruolocompleto)|| !environment.RmaAddOn.provaAcquisto)">
            <label for="categoria" class="control-label col-form-label" >{{'RMA.brand' | translate }}:</label>
            <ng-multiselect-dropdown required #categoria="ngModel" [settings]="dropdownSettings" [placeholder]="'RMA.brand' | translate" id="brand" name="categoria"
                                     [data]="Brands" [(ngModel)]="Brand">
            </ng-multiselect-dropdown>
            <small class="form-text text-danger" *ngIf="!categoria.valid && (categoria.dirty || categoria.touched)">{{'RMA.required' | translate }}</small>
          </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Brand && Brand[0]?.id == 76 && (permission.isOperativeRole(ruolocompleto)|| !environment.RmaAddOn.provaAcquisto)">
            <label for="desc" class="control-label col-form-label">{{'RMA.branddesc' | translate}}</label>
            <input type="text" #bdesc="ngModel" [readOnly]="Rma.id" placeholder="{{'RMA.branddesc' | translate}}" name="desc" required [(ngModel)]="Rma.brandDesc" id="branddesc" class="form-control">
            <small class="form-text text-danger" *ngIf="!bdesc.valid">{{'RMA.required' | translate }}</small>
          </div>

          <div class="col-lg-6" *ngIf="DescRequired && environment.RmaAddOn.DescrizioneDifetto">
            <label for="desc" class="control-label col-form-label">Descrizione:</label>
            <textarea type="text" #desc="ngModel" [readOnly]="Rma.id" name="desc" required [(ngModel)]="Rma.difettoDesc" id="desc" class="form-control"></textarea>
            <small class="form-text text-danger" *ngIf="!desc.valid">{{'RMA.required' | translate }}</small>
          </div>

          <div class="col-lg-12" *ngIf="environment.RmaAddOn.Difetto">
            <app-field-viewer [(Campi)]="campiAggiuntiviService" [(Oggetto)]="Rma"></app-field-viewer>
          </div>
                    <div class="col-lg-12" *ngIf="environment.RmaAddOn.Difetto && (Rma['guasto'] == 79 || Rma['guasto'] == 562)">
                      <label for="note" class="mb-0 text-dark control-label col-form-label">{{'RMA.difetto' | translate }}:</label>
                      <textarea type="text" #note="ngModel" [readOnly]="Rma.id" required="required" name="note" [placeholder]="'RMA.difetto' | translate " [(ngModel)]="Rma.note" id="note" class="form-control"></textarea>
                      <small class="form-text text-danger" *ngIf="!note.valid ">{{'RMA.required' | translate }}</small>
                    </div>
          <div class="col-lg-3" *ngIf="environment.RmaAddOn.Telefono">
            <label for="telefonoq" class="mb-0 text-dark control-label col-form-label">{{'RMA.tel' | translate }}:</label>
            <input type="text" #telefono="ngModel" name="telefono" required [(ngModel)]="Rma.telefono" placeholder="{{'RMA.tel' | translate }}:" id="telefonoq" class="form-control">
            <!--      <small class="form-text text-danger" *ngIf="!telefono.valid">{{'RMA.required' | translate }}</small>-->
          </div>
          <div class="col-lg-2" *ngIf="environment.RmaAddOn.Garanzia">
            <label for="garanzia" class="mb-0 text-dark control-label col-form-label">{{'RMA.garanzia' | translate }}:</label>
            <input type="checkbox" #garanzia="ngModel" name="garanzia" [(ngModel)]="Rma.garanzia"  id="garanzia" class="form-control">
            <small class="form-text text-danger" *ngIf="!garanzia.valid ">{{'RMA.required' | translate }}</small>
          </div>
          <div class="row align-items-center">
            <a *ngIf="Rma && Rma.prodotto && Rma.prodotto.ricevuta && environment.RmaAddOn.Ricevuta" href="javascript:void(0)" class="link font-16 font-weight-bold" (click)='download(Rma.prodotto.ricevuta.id)'>
              <br>
              &nbsp;&nbsp;{{'RMA.downloadRicevuta' | translate }}
              <i-feather name="download" class="feather-sm"></i-feather>
            </a>
            <a *ngIf="Rma && Rma.estensione && environment.RmaAddOn.EstensioneGaranzia" href="javascript:void(0)" class="link font-16 font-weight-bold" (click)='download(Rma.estensione.id)'>
              <br>
              &nbsp;&nbsp;{{'RMA.downloadEgaranzia' | translate }}
              <i-feather name="download" class="feather-sm"></i-feather>
            </a>
          </div>
          <div class="col-sm-12 col-md-3" *ngIf="!Rma.id && environment.RmaAddOn.EstensioneGaranzia">
            <label class="mb-0 text-dark control-label col-form-label">{{'RMA.eGaranzia' | translate }}</label>
            <div class="">
              <input  type="file" class="custom-file-input" id="Estensione" placeholder="{{'RMA.eGaranzia' | translate }}" (change)="OnEstensioneFileChange($event)">
              <label class="custom-file-label" for="Estensione">{{Estensione?Estensione.name : 'RMA.eGaranzia' | translate}}</label>
            </div>
          </div>
        </div>
        <br>
        <br>

    </div>
  </div>
  <div class="card" *ngIf="!Rma.id && environment.RmaAddOn.Allegati">
    <div class="d-flex align-items-center bg-white" >
      <h4 class="card-title">{{'RMA.allegati' | translate}}</h4>
      <div class="ml-auto  mt-md-0">
        <button type="button" (click)="addAllegato()" class="btn btn-cyan " ><i
          class="fas fa-plus  font-14 mr-1 text-white">&nbsp;{{'RMA.allegati' | translate}}</i>
        </button>
      </div>
    </div>
    
    <div class="card-body">

          <div class="d-md-flex align-items-center bg-white p-2" >
            <h7 class="d-flex align-items-center p-2 ">{{'RMA.infoAllegati' | translate}}</h7>
          </div>
          <div class="table-responsive" *ngIf="Rma.photo">
            <table class="table bg-white table-hover align-middle mb-0 no-wrap">
              <thead>
              <tr>
                <th>File</th>
                <th scope="col">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let photo of Rma.photo; let i = index">
                <td>
                  <div class="custom-file">
                    <input  type="file" class="custom-file-input" [id]="'allegato' + i"  (change)="OnAllegatoFileChange($event,i)">
                    <label class="custom-file-label" for="ricevuta">{{ Rma?.photo && Rma?.photo[i]?Rma?.photo[i].name : "Nessun File Allegato"}}</label>
                  </div>
                  <!--            <div class="">-->
                  <!--              <input type="file"  (change)="OnAllegatoFileChange($event,i )">-->
                  <!--            </div>-->
                </td>
                <td class="align-middle">
                  <a href="javascript:void(0)" class="link font-16 text-danger font-medium mr-2" (click)="removeAllegato(i)">
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
      
        <br>
        <br>

    </div>
  </div>
</div>
<div class="modal-body" *ngIf="environment.RmaAddOn['CampiRmaAdd']">
  <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)" (OnChange)="OnChengeHandler($event)" [(Campi)]="environment.RmaAddOn['CampiRmaAdd']" [(Oggetto)]="Rma"></app-sector-viewer>
  <div *ngIf="environment.RmaAddOn['ProveAcquistoMultipleRma'] && Rma?.acquirente" >
    <div class="row">
      <div class="col-lg-9" *ngIf="Rma?.sedeId">
        <label for="categoria" class="control-label col-form-label">{{'provaAcquisto.title'|translate}}:</label>
        <ng-multiselect-dropdown [settings]="PAdropdownSettings" [placeholder]="'Seleziona'"
                                 [data]="ApprestamentiSelezionabili()" [(ngModel)]="selectedPA" >
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-3 d-flex align-items-end" *ngIf="Rma?.sedeId">
        <div class="d-flex align-items-end">
          <button type="submit" (click)="addPA(selectedPA)" class="ml-auto btn btn-outline-dark p-2 waves-effect waves-light">
            <span class="ml-1"> Aggiungi </span> &nbsp;
            <i class="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>
    </div>
    <br>
    <app-lista-prova-acquisto  [Headers]="environment.RmaAddOn['ListProvaAcquistoRmaField']"  [(List)]="Rma.proveObject" [Cliente]="Rma.acquirente" [isModifyDisabled]="true"  [Local]="true" [HasFilter]="false" [HasHeader]="false" [(Sede)]="Rma.sedeId" ></app-lista-prova-acquisto>
    <br><br><br><br><br><br><br><br><br><br><br>
  </div>

</div>

<div class="d-flex align-items-center justify-content-end m-t-10 m-b-10 m-r-10">
  <button type="submit"  [disabled]="!enableUploadBtn && activeId == 'ritiro'" (click)="save()" class=" btn  btn-success  waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.save' | translate}} </span>
    <span class="ml-1 text-white font-weight-bold" *ngIf="!enableUploadBtn">{{uploadPercent}}%</span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
