import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Scadenza} from "../Models/Scadenza";
import {Subscription} from "rxjs";
import {ScadenzeStoreService} from "../_services/scadenze-store.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiRestService} from "../_services/api-rest.service";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ClassicType} from "../../shared/models/ClassicType";
import {DatePipe} from "@angular/common";
import {environment} from "../../../environments/environment";
import {Persona} from "../../Admin/models/persona";
import {ApiRestService as PersonaApiRestService} from "../../Admin/_service/api-rest.service";

@Component({
  selector: 'app-edit-scadenza',
  templateUrl: './edit-scadenza.component.html'
})
export class EditScadenzaComponent implements OnInit {
  environment = environment;
  subscriptions: Subscription = new Subscription();
  @Input() IsModal: boolean = true;
  @Output() updated = new EventEmitter<Scadenza>();
  @Output() added = new EventEmitter<Scadenza>();
  @Input() hasScadenzeFiglie: boolean = environment.Modules.ScadenzeFiglie;
  selected: Scadenza = new Scadenza();
  TipiSelezionati: ClassicType[] = [];
  Tipi: ClassicType[] = [];
  TipiPeriodo: ClassicType[] = [{id:"days", label: "Giorni"},{id:"weeks", label: "Settimane"},{id:"months", label: "Mesi"},{id:"years", label: "Anni"}];
  TipiPeriodoSelezionati: ClassicType[] = [];
  giorno_mese_ordineList: ClassicType[] = [{id:"first", label: "Primo"},{id:"second", label: "Secondo"},{id:"third", label: "Terzo"},{id:"fourth", label: "Quarto"}];
  giorno_mese_ordineSelezionati: ClassicType[] = [];
  giorno_mese_giornoList: ClassicType[] = [{id:"monday", label: "Lunedi"},{id:"tuesday", label: "Martedi"},{id:"wednesday", label: "Mercoledi"},{id:"thursday", label: "Giovedi"},{id:"friday", label: "Venerdi"},{id:"saturday", label: "Sabato"},{id:"sunday", label: "Domenica"}];
  giorno_mese_giornoSelezionati: ClassicType[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField:'id',
    textField: 'label',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  userDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField:'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  managers: Persona[] = [];
  selectedManagers: Persona[] = [];
  constructor(
    private store: ScadenzeStoreService,
    private personaApiRestService : PersonaApiRestService,
    private ApiRestService: ApiRestService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.store.getTipi();
    this.subscriptions.add(this.store.$scadenzaSelezionata.subscribe(data => {
      this.selected = new Scadenza();
      if (data)
        this.compila(data);
    }))
    this.subscriptions.add(this.store.$tipi.subscribe(data => {
      this.Tipi = [];
      if (data)
        this.Tipi = data;
    }))
  }


  compila(item: Scadenza){
    console.log(item);
    this.selected = item;
    try {
      this.selected.data_inizio_prevista = item.data_inizio_prevista;//.split('.')[0]
      this.selected.data_fine_prevista = item.data_fine_prevista;//.split('.')[0]
    }catch (e){}
     if(item.tipoObject)
      this.TipiSelezionati = [item.tipoObject];
    if(item.manager)
      this.selectedManagers = [item.manager];
    if(item.managerObject)
      this.selectedManagers = [item.managerObject];
  }
  saveModalButton() {
    // try {
    //   this.selected.data_inizio_prevista = this.datepipe.transform(this.selected.data_inizio_prevista, 'yyyy-MM-ddTHH:mm');
    //   this.selected.data_fine_prevista = this.datepipe.transform(this.selected.data_fine_prevista, 'yyyy-MM-ddTHH:mm');
    // } catch (ex) { }
    this.selected.manager = this.selectedManagers?.length > 0 ? this.selectedManagers[0]?.id : undefined;
    if(this.TipiSelezionati && this.TipiSelezionati.length > 0 && this.TipiSelezionati[0])
      this.selected.tipo = this.TipiSelezionati[0].id.toString();
    if(this.TipiPeriodoSelezionati && this.TipiPeriodoSelezionati.length > 0 && this.TipiPeriodoSelezionati[0])
      this.selected.tipo_periodo = this.TipiPeriodoSelezionati[0].id;
    if(this.giorno_mese_ordineSelezionati && this.giorno_mese_ordineSelezionati.length > 0 && this.giorno_mese_ordineSelezionati[0])
      this.selected.giorno_mese_ordine = this.giorno_mese_ordineSelezionati[0].id;
    if(this.giorno_mese_giornoSelezionati && this.giorno_mese_giornoSelezionati.length > 0 && this.giorno_mese_giornoSelezionati[0])
      this.selected.giorno_mese_giorno = this.giorno_mese_giornoSelezionati[0].id;
    if(this.TipiPeriodoSelezionati && this.TipiPeriodoSelezionati.length > 0 && this.TipiPeriodoSelezionati[0])
      this.selected.tipo_periodo = this.TipiPeriodoSelezionati[0].id;
      this.selected.data_fine_prevista = this.selected.data_fine_prevista ?? this.selected.data_inizio_prevista;
    if (this.selected && !isNaN(this.selected.id)) {
      this.ApiRestService.updateScadenza(this.selected).subscribe(data => {
        this.updated.emit(data);
        this.closeBtnClick();
      });
    } else if (this.selected && (isNaN(this.selected.id) || this.selected.id < 1)) {
      this.ApiRestService.addScadenza(this.selected).subscribe(data => {
        this.added.emit(data);
        this.closeBtnClick();
      });
    }
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }

  TipoCambiato() {

  }

  TipoPeriodoCambiato() {

  }
  ScadAddButton() {
    const nuova = new Scadenza();
    nuova.padre = this.selected.id;
    this.store.updateScadenzaSelezionata(nuova);
    const editmodal = this.modalService.open(EditScadenzaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditScadenzaComponent>editmodal.componentInstance).IsModal = true;
    (<EditScadenzaComponent>editmodal.componentInstance).added.subscribe(data=>{
      this.closeBtnClick();
      this.updated.emit()
    });
    (<EditScadenzaComponent>editmodal.componentInstance).updated.subscribe(data=> {});
  }

  managerFilter(text:string) {
    this.personaApiRestService.getListaPersonale({page:0, size: 10, nome: text}).subscribe(data =>{
      this.managers = data?.data ?? [];
    })
  }
}
