<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title" >{{'RMA.attivita.title' | translate}}</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="Item && Item != null && IsModal" class="modal-body">
  <app-field-viewer [(Campi)]="environment.RmaAddOn['CampiEsito']" [(DropdownSources)]="DropdownSources" [(Oggetto)]="Item"></app-field-viewer>
<!--  <div class="row">-->
<!--    <div class="col-3">-->
<!--      <div class="form-group">-->
<!--        <label for="indirizzo" class="control-label col-form-label">{{'RMA.attivita.nome' | translate}}:</label>-->
<!--        <input type="text" required [(ngModel)]="Item.nome" class="form-control" id="indirizzo" placeholder="Nome">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-3" *ngIf="Item.id">-->
<!--      <div class="form-group">-->
<!--        <label for="data_inizio_prevista" class="control-label col-form-label">{{'RMA.attivita.dataip' | translate}}:</label>-->
<!--        <input type="datetime-local" readonly [(ngModel)]="Item.data_inizio_prevista" class="form-control" id="data_inizio_prevista">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-3">-->
<!--      <div class="form-group">-->
<!--        <label for="data_inizio_effettiva" class="control-label col-form-label">{{'Data Inizio' | translate}}:</label>-->
<!--        <input type="datetime-local"  [(ngModel)]="Item.data_inizio_effettiva" class="form-control" id="data_inizio_effettiva">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-3" >-->
<!--      <div class="form-group">-->
<!--        <label for="data_fine_effettiva" class="control-label col-form-label">{{'Data Fine' | translate}}:</label>-->
<!--        <input type="datetime-local"  [(ngModel)]="Item.data_fine_effettiva" class="form-control" id="data_fine_effettiva">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-lg-3" >-->
<!--      <label class="control-label col-form-label">Esito:</label>-->
<!--      <ng-multiselect-dropdown  [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="esito" name="esito" [data]="Tipi" [(ngModel)]="TipiSelezionati">-->
<!--      </ng-multiselect-dropdown>-->
<!--    </div>-->
<!--    <div class="col-lg-3" >-->
<!--      <label class="control-label col-form-label">Attività:</label>-->
<!--      <ng-multiselect-dropdown  [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="esito" name="esito" [data]="Tipi" [(ngModel)]="TipiSelezionati">-->
<!--      </ng-multiselect-dropdown>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="col-3" *ngIf="Item.id">-->
<!--      <div class="form-group">-->
<!--        <label for="data_fine_prevista" class="control-label col-form-label">{{'RMA.attivita.datafp' | translate}}:</label>-->
<!--        <input type="datetime-local" readonly [(ngModel)]="Item.data_fine_prevista" class="form-control" id="data_fine_prevista">-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-3" *ngIf="Item.id">-->
<!--      <div class="row">-->
<!--        <span for="chiuso" >{{'RMA.attivita.chiuso' | translate}}:</span>-->
<!--        <div class="col-3">-->
<!--          <div class="form-group">-->
<!--            <input type="checkbox" [(ngModel)]="Item.chiuso" class="form-control" id="chiuso">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>&ndash;&gt;-->
<!--    <div class="col-12">-->
<!--      <div class="form-group">-->
<!--        <label for="note" class="control-label col-form-label">{{'RMA.attivita.note' | translate}}:</label>-->
<!--        <textarea type="text" [(ngModel)]="Item.note" class="form-control" id="note" placeholder="Note"></textarea>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
<div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
  <!--<button (click)="CaricaModello()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Carica Modello </span>
    <i-feather name="upload" class="feather-md "></i-feather>
  </button>-->
  <button (click)="saveModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.attivita.salva' | translate}} </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
