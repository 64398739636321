import { Spesa } from './../Models/Spesa';
import { RitornoPaginazione } from './../../shared/models/RitornoPaginazione';
import { ClassicType } from './../../shared/models/ClassicType';
import { Categorie } from './../Models/Categorie';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { map } from 'rxjs/operators';
import {Fattura} from "../Models/fattura";
import { Bilancio } from '../Models/Bilancio';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  header() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  headerforfile() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
   options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  //#region Bilancio
  getstatiBilancio(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>( `${this.apiPaths.getBaseAPI()}/primanota/bilancio/stati`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getListaBilanci(value): Observable<RitornoPaginazione<Bilancio[]>> {
    return this.http.post<RitornoPaginazione<Bilancio[]>>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getBilancio(value): Observable<Bilancio> {
    return this.http.post<Bilancio>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addBilancio(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateBilancio(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteBilancio(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  generateBilancio(idProgetto: number): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/bilancio/generate`,
      JSON.stringify({id: idProgetto}),
      this.options()).pipe();
  }
  //#end Region
  //#region Fatture
  getstatiFattura(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>( `${this.apiPaths.getBaseAPI()}/primanota/fatture/stati`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getListaFatture(value): Observable<RitornoPaginazione<Fattura[]>> {
    return this.http.post<RitornoPaginazione<Fattura[]>>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getFattura(value): Observable<Fattura> {
    return this.http.post<Fattura>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addFattura(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateFattura(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteFattura(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  generateFattura(idProgetto: number): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/primanota/fatture/generate`,
      JSON.stringify({id: idProgetto}),
      this.options()).pipe();
  }
//#endregion
//#region Documenti
getTipiSpese(): Observable<ClassicType[]> {
  return this.http.get<ClassicType[]>( `${this.apiPaths.getBaseAPI()}/primanota/spese/types`,
    this.options()).pipe(map((data) => {
      return data;
    }));
}
getListaSpese(value): Observable<RitornoPaginazione<Spesa[]>> {
  return this.http.post<RitornoPaginazione<Spesa[]>>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/list`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
}
FilterListaSpese(value): Observable<RitornoPaginazione<Spesa[]>> {
  return this.http.post<RitornoPaginazione<Spesa[]>>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/list/list`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
}
getSpesa(value): Observable<Spesa> {
  return this.http.post<Spesa>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/get`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
}
addSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/add`,
    JSON.stringify(value),
    this.options()).pipe();
}
updateSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/update`,
    JSON.stringify(value),
    this.options()).pipe();
}
deleteSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/spese/delete`,
    JSON.stringify(value),
    this.options()).pipe();
}
//#endregion
//#region Categorie_Documenti
getListCategorieSpesa(): Observable<Categorie[]> {
  return this.http.get<Categorie[]>(
    `${this.apiPaths.getBaseAPI()}/primanota/categorie/list`,
    this.options()).pipe(map((data) => {
      return data;
    }));
}
searchCategorieSpesa(value): Observable<Categorie[]> {
  return this.http.post<Categorie[]>(
    `${this.apiPaths.getBaseAPI()}/primanota/categorie/list/list`,
    JSON.stringify(value),
    this.options()).pipe(map((data) => {
      return data;
    }));
}
addCategorieSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/categorie/add`,
    JSON.stringify(value),
    this.options()).pipe();
}
updateCategorieSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/categorie/update`,
    JSON.stringify(value),
    this.options()).pipe();
}
deleteCategorieSpesa(value): Observable<any> {
  return this.http.post<any>(
    `${this.apiPaths.getBaseAPI()}/primanota/categorie/delete`,
    JSON.stringify(value),
    this.options()).pipe();
}
//#endregion

}
