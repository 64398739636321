import { ProdottiStoreService } from '../../Progetti/_services/prodotti-store.service';
import { Stampa } from '../../task/models/date';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Progetto } from '../../Progetti/Models/Progetto';
import { AuthenticationService } from '../../Login/_services/authentication.service';
import { Persona } from '../../Admin/models/persona';
import { PersonaleStoreService } from '../../Admin/_service/personale-store.service';
import { InterventiStoreService } from '../_services/interventi-store.service';
import { ApiRestService } from '../_services/api-rest.service';
import { ApiRestService as ProdottiApiRestService} from './../../Progetti/_services/api-rest.service';
import { Intervento } from '../Models/Intervento';
import { ClassicType } from '../../shared/models/ClassicType';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { RicercaPaginazione } from 'src/app/Admin/models/sendpersonale';
import { DatePipe } from '@angular/common';
import { Prodotto } from 'src/app/Progetti/Models/Prodotto';
import {FileStoreService} from "../../shared/_services/file-store.service";
import {Permission} from "../../Login/_guards/Permission";
import {environment} from "../../../environments/environment";
import {ApiPathsService} from "../../api-paths.service";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";

@Component({
  selector: 'app-intervento',
  templateUrl: './intervento.component.html'
})
export class InterventoComponent implements OnInit {
  constructor(
    private ApiRestService: ApiRestService,
    private ApiPaths: ApiPathsService,
    private ProdottiApiRestService: ProdottiApiRestService,
    private personaleStore: PersonaleStoreService,
    private InterventiStore: InterventiStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private datepipe: DatePipe,
    public authSvc: AuthenticationService,
    private activemodalService: NgbActiveModal,
    private prodottiStore: ProdottiStoreService,
    private filestore: FileStoreService,
    public permission: Permission
  ) { }
  canAddTempi: boolean = true;
  @Input() IsModal: boolean = true;
  @Output() IsModalChange = new EventEmitter<boolean>() ;
  @Output() Refresh = new EventEmitter<Intervento>() ;
  offerta: Progetto;
  offerte: Progetto[] = [];
  offerteSelezionate: Progetto[] = [];
  stati: ClassicType[] = [];
  tipiIntervento: ClassicType[] = [];
  Responsabili: Persona[] = [];
  prodotti: Prodotto[] = [];
  prodottiSelezionati: Prodotto[] = [];
  qtaParti: number = 1;
  environment= environment;

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: false,
    allowRemoteDataSearch: true,
  };
  speseDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
    singleOutput: true,
    searchPlaceholderText: "Cerca",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  offerteDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'oggetto',
  };
  userDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  MultipleuserDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  subscriptions: Subscription = new Subscription();
  isAddable: boolean;
  isEditable: boolean;
  @Input() InterventoSelezionato: Intervento;
  Stampe: Stampa[];
  isModifyDisabled:boolean;
  personeSelezionate: Persona[] | number[] = [];
  Spesa: any;
  Spese: any[];


  ngOnInit() {
  //   this.subscriptions.add(this.InterventiStore.$interventoSelezionato.subscribe((item) => {
  //   if (item) {
  //     console.log(item)
  //     this.CompilaDati(item);
  //     this.filestore.SetListaFile(item.files);
  //     this.filestore.SelectSection('fasi');
  //   }
  // }));
        this.CompilaDati(this.InterventoSelezionato);
        this.filestore.SetListaFile(this.InterventoSelezionato.files);
        this.filestore.SelectSection('fasi');
    this.InterventiStore.getStatiIntervento();
    this.InterventiStore.getTipiIntervento();
   // this.subscriptions.add(this.InterventiStore.$isModal.subscribe(x=> this.IsModal = x));
    this.subscriptions.add(this.InterventiStore.$stampe.subscribe(x=> this.Stampe = x));
    this.subscriptions.add(this.InterventiStore.$showAddForm.subscribe(x => this.isAddable = x));
    this.subscriptions.add(this.InterventiStore.$editable.subscribe(x => this.isEditable = x));
    this.subscriptions.add(this.InterventiStore.$tipiIntervento.subscribe(x => this.tipiIntervento = x));
    this.subscriptions.add(this.InterventiStore.$statiIntervento.subscribe(x => this.stati = x));
    this.subscriptions.add(this.InterventiStore.$offertaSelezionata.subscribe(x => this.offerta = x));
    if(!this.offerta?.id && !this.InterventoSelezionato?.id && !this.InterventoSelezionato?.padre) {
      this.subscriptions.add(this.ProdottiApiRestService.getProgetti({
        page: 0,
        size: 10,
        periodo: false,
        stato_prog: 'T'
      }).subscribe(x => this.offerte = x?.data ?? []));
    }
    this.prodottiStore.$prodotti.subscribe(x => this.prodotti = x.data);
    this.subscriptions.add(this.personaleStore.$listaPersonale.subscribe(x => {
      if (x && x.data && x.data.length > 0)
        this.Responsabili = x.data
    }));
    if(this.environment.Modules.Prima_Nota)
      this.GetSpese();

  }
  CompilaDati(item: Intervento) {
    item.tipiObject = [];
    item.statiObject = [];
    item.managers = [];

    if(item && item.persone && Array.isArray(item.persone))
      this.canAddTempi = item.persone.find(t => t.id == this.authSvc.currentUserValue.id) !== undefined
    if (item.tipoObject !== null)
      item.tipiObject.push(item.tipoObject);
    if (item.statoObject !== null)
      item.statiObject.push(item.statoObject);
    if (item.manager !== null)
      item.managers.push(item.manager);
    if (item.persone)
      this.personeSelezionate = item.persone;
    if (item.data_inizio_prevista)
      item.data_inizio_prevista = this.datepipe.transform(item.data_inizio_prevista, 'yyyy-MM-ddTHH:mm');
    if (item.data_fine_prevista)
      item.data_fine_prevista = this.datepipe.transform(item.data_fine_prevista, 'yyyy-MM-ddTHH:mm');
    if (item.data_inizio_effettiva)
      item.data_inizio_effettiva = this.datepipe.transform(item.data_inizio_effettiva, 'yyyy-MM-ddTHH:mm');
    if (item.data_fine_effettiva)
      item.data_fine_effettiva = this.datepipe.transform(item.data_fine_effettiva, 'yyyy-MM-ddTHH:mm');
      console.log(item);
      this.InterventoSelezionato = item;
  }
  saveClick(close?:boolean) {
    this.InterventoSelezionato.progetto =
      this.InterventoSelezionato?.padre ?
        this.InterventoSelezionato?.progetto :
        (this.offerta?.id ?
          this.offerta.id :
          (this.offerteSelezionate?.length > 0 ? this.offerteSelezionate[0]?.id : undefined)
        );
    if (this.InterventoSelezionato?.tipiObject?.length > 0)
      this.InterventoSelezionato.tipo = parseInt(this.InterventoSelezionato?.tipiObject[0]?.id);
    if (this.InterventoSelezionato?.managers?.length > 0)
      this.InterventoSelezionato.id_responsabile = this.InterventoSelezionato?.managers[0]?.id;
    if (this.InterventoSelezionato?.statiObject?.length > 0)
      this.InterventoSelezionato.stato = parseInt(this.InterventoSelezionato?.statiObject[0]?.id);
    if (this.personeSelezionate?.length > 0) {
      this.InterventoSelezionato.persone = [];
      this.personeSelezionate?.forEach(persona =>{
        this.InterventoSelezionato?.persone?.push(persona?.id);
      })
    }
    this.InterventoSelezionato.data_fine_prevista = this.InterventoSelezionato.data_fine_prevista ?? this.InterventoSelezionato.data_inizio_prevista;
    if (!this.InterventoSelezionato.id) {
      this.ApiRestService.addinterventi(this.InterventoSelezionato).subscribe(added => {
        if(close)
        this.goBack();
        else
          this.InterventiStore.updateInterventoSelezionato({id:added.id});
      });
    } else {
      this.ApiRestService.updateinterventi(this.InterventoSelezionato).subscribe(() => {
        this.InterventiStore.updateInterventoSelezionato(this.InterventoSelezionato);
        this.toastService.success("Modifiche apportate","Success!!")
        if(close)
        this.goBack();
      });
    }
  }
  filtroPersonale(item: string) {
    if (item.length >= 3) {
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 0, 1));
    }else if (item.length == 0)
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 1, 10));
  }
  GetSpese(){
    this.ApiPaths.ClassicPost(`/primanota/spese/list`,{page:0,size:10}).subscribe(x => {
      this.Spese = x?.data?.map(x => {
        x.label = `${x.fattura} - ${x.nominativo}`;
        return x;
      } ) ?? [];
    })
  }
  closeBtnClick() {
    this.activemodalService.dismiss();
  }
  goBack() {
    this.InterventiStore.updateEditable(false);
    this.InterventiStore.updateShowAddForm(false);
    this.Refresh.emit(this.InterventoSelezionato);
    this.closeBtnClick();
  }
  add() {
    if (this.prodottiSelezionati && this.prodottiSelezionati.length > 0)
      this.ProdottiApiRestService.getInfoProdotto(this.prodottiSelezionati[0]).subscribe(data => {
        this.InterventoSelezionato.prodotti.push({
          nome:data.prodotto,
          qta: this.qtaParti,
          id:data.id,
          page: undefined,
          size: undefined
        });
        this.qtaParti = 1;
        this.prodottiSelezionati = [];
      });
  }
  delete(item){
    const idx: number = this.InterventoSelezionato.prodotti.findIndex(x => x == item);
    if ( idx > -1) {
      this.InterventoSelezionato.prodotti.splice(idx, 1);
    }
  }
  onFileChanged() {
    this.filestore.SetListaFile(this.InterventoSelezionato.files);
    this.filestore.SelectSection('fasi');
    this.InterventiStore.updateInterventoSelezionato(this.InterventoSelezionato);
  }

  delSpesa(item: any) {
    this.InterventoSelezionato.speseObject.splice(this.InterventoSelezionato.speseObject.indexOf(item), 1);
  }


  addSpesa() {
    if(!this.InterventoSelezionato.speseObject)
      this.InterventoSelezionato.speseObject = [];
    this.InterventoSelezionato.speseObject?.push(this.Spesa)
    this.Spesa = null;
  }
}
