<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title" >{{'RMA.attivita.title' | translate}}</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="Item && IsModal" class="modal-body">
  <div class="row">
    <div class="col-3">
      <div class="form-group">
        <label for="indirizzo" class="control-label col-form-label">{{'RMA.attivita.nome' | translate}}:</label>
        <input type="text" required [(ngModel)]="Item.nome" class="form-control" id="indirizzo" placeholder="Nome">
      </div>
    </div>
    <div class="col-3" *ngIf="Item.id">
      <div class="form-group">
        <label for="data_inizio_prevista" class="control-label col-form-label">{{'RMA.attivita.dataip' | translate}}:</label>
        <input type="datetime-local" readonly [(ngModel)]="Item.data_inizio_prevista" class="form-control" id="data_inizio_prevista">
      </div>
    </div>
    <div class="col-3" *ngIf="!environment.RmaAddOn['hideSegnalazionidie']">
      <div class="form-group">
        <label for="data_inizio_effettiva" class="control-label col-form-label">{{'Data Inizio' | translate}}:</label>
        <input type="datetime-local"  [(ngModel)]="Item.data_inizio_effettiva" class="form-control" id="data_inizio_effettiva">
      </div>
    </div>
    <div class="col-3" *ngIf="!environment.RmaAddOn['hideSegnalazionidfe']">
      <div class="form-group">
        <label for="data_fine_effettiva" class="control-label col-form-label">{{'Data Fine' | translate}}:</label>
        <input type="datetime-local"  [(ngModel)]="Item.data_fine_effettiva" class="form-control" id="data_fine_effettiva">
      </div>
    </div>
<!--    <div class="col-3" *ngIf="Item.id">
      <div class="form-group">
        <label for="data_fine_prevista" class="control-label col-form-label">{{'RMA.attivita.datafp' | translate}}:</label>
        <input type="datetime-local" readonly [(ngModel)]="Item.data_fine_prevista" class="form-control" id="data_fine_prevista">
      </div>
    </div>
    <div class="col-3" *ngIf="Item.id">
      <div class="row">
        <span for="chiuso" >{{'RMA.attivita.chiuso' | translate}}:</span>
        <div class="col-3">
          <div class="form-group">
            <input type="checkbox" [(ngModel)]="Item.chiuso" class="form-control" id="chiuso">
          </div>
        </div>
      </div>
    </div>-->
    <div class="col-12">
      <div class="form-group">
        <label for="note" class="control-label col-form-label">{{'RMA.attivita.note' | translate}}:</label>
        <textarea type="text" [(ngModel)]="Item.note" class="form-control" id="note" placeholder="Note"></textarea>
      </div>
    </div>
  </div>
  <app-field-viewer [Campi]="environment.RmaAddOn['campiScadenza']" (OnChange)="ChangeHandler($event)" [(Oggetto)]="Item"></app-field-viewer>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
  <button type="submit" (click)="saveModalButton()" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" (click)="saveModalButton(true)" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva e chiudi </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" (click)="closeBtnClick()"  style="margin: 0.75px;" class=" btn btn-danger  waves-effect waves-light">
    <span class="mr-1 text-white "> Chiudi </span>
    <i class="far fa-window-close text-white"></i>
  </button>
</div>
