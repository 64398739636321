import { Valutazione } from './../models/Valutazione';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiRestService } from './api-rest.service';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import {Stampa} from "../../Stampe/Models/Stampa";
@Injectable({
  providedIn: 'root'
})
export class ValutazioniStoreService {
  //#region BehaviorSubjects
  private LISTA = new BehaviorSubject<RitornoPaginazione<Valutazione[]>>(null);
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private SELECTED = new BehaviorSubject<Valutazione>(null);
  private STAMPE = new BehaviorSubject<Stampa[]>([]);
  //#endregion
  //#region constructor
  constructor(private apiRestService: ApiRestService) {
  }
  //#endregion
  //#region dataStore
  private dataStore: {
    Lista: RitornoPaginazione<Valutazione[]>,
    Editable: boolean,
    ShowAddForm: boolean,
    Selected: Valutazione,
    Stampe: Stampa[],
  } = {
      Lista: null,
      Editable: false,
      ShowAddForm: false,
      Selected: null,
      Stampe: []
    }
  //#endregion
  //#region readonly
  readonly $lista = this.LISTA.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $selected = this.SELECTED.asObservable();
  readonly $stampe = this.STAMPE.asObservable();
  //#endregion
  //#region refresh
  refreshLista = () => this.LISTA.next(Object.assign({}, this.dataStore).Lista);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshSelected = () => this.SELECTED.next(Object.assign({}, this.dataStore).Selected);
  refreshStampe= () => this.STAMPE.next(Object.assign({}, this.dataStore).Stampe);
  //#region gets
  getLista(value) {
    return this.apiRestService.getListaValutazioni(value).subscribe(data => {
      this.dataStore.Lista = data;
      this.refreshLista();
      return this.$lista;
    })
  }
  get(value) {
    return this.apiRestService.getValutazione(value).subscribe(data => {
      this.dataStore.Selected = data.data;
      this.refreshSelected();
      this.dataStore.Stampe = data.stampe;
      this.refreshStampe();
      return this.$selected;
    })
  }
  //#endregion
  //#region updates
  updateSelected(value: any) {
    if(value.id)
      this.apiRestService.getValutazione(value).subscribe(data => {
        this.dataStore.Selected = data.data;
        this.refreshSelected();
        this.dataStore.Stampe = data.stampe;
        this.refreshStampe();
      })
    else {
      this.dataStore.Selected = value;
      this.refreshSelected();
      this.dataStore.Stampe = [];
      this.refreshStampe();
    }
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
  this.dataStore.ShowAddForm = value;
  this.refreshShowAddForm();
  }
  //#endregion
}
