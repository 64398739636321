import { DatePipe } from '@angular/common';
import { Paginazione } from './../../shared/models/paginazione';
export class FiltriInterventi extends Paginazione{
  datepipe = new DatePipe('it-it');
  cliente?: number;
  orderByData?: 'asc' | 'desc' | undefined;
  manager?: number;
  periodo?: boolean;
  inizio: string;
  fine: string;
  progetto?: number;
  lat?: number;
  lon?: number;
  raggio?: number;//in km
  constructor(page?: number, size?: number, progetto?: number, cliente?: number, orderByData?:'asc' | 'desc' | undefined, manager?: number, periodo?: boolean, inizio?: string, fine?: string) {
    super(page || 0, size || 1);
    this.cliente = cliente;
    this.progetto = progetto;
    this.orderByData = orderByData;
    this.manager = manager;
    this.periodo = periodo || false;
    if(inizio || inizio != null)
      this.inizio = this.datepipe.transform(inizio, 'yyyy-MM-dd');
    if (fine || fine != null)
      this.fine = this.datepipe.transform(fine, 'yyyy-MM-dd');
  }
}
