import {Component, Input, OnInit} from '@angular/core';
import {ApiRestService} from "../_services/api-rest.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormazioneStoreService} from "../_services/formazione-store.service";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe, Location} from "@angular/common";
import {Subscription} from "rxjs";
import { Attivita } from '../_Models/attivita';
import {RicercaPaginazione} from "../../Admin/models/sendpersonale";
import {Incarico} from "../_Models/incarico";
import {Edizione} from "../_Models/edizione";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {Persona} from "../../Admin/models/persona";
import {Paginazione} from "../../shared/models/paginazione";
import {Aula} from "../_Models/aula";
import {Permission} from "../../Login/_guards/Permission";
import {MultimediaCourse} from "../_Models/multimedia-course";

@Component({
  selector: 'app-attivita-edit',
  templateUrl: './attivita-edit.component.html'
})
export class AttivitaEditComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'nome',
  };

  UserDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    allowRemoteDataSearch:true,
    allowSearchFilter: true,
    searchPlaceholderText: "inserire almeno 3 caratteri",
  };
  subscriptions: Subscription = new Subscription();
  isAddable: boolean = false;
  isEditable: boolean = false;
  @Input() CalendarItem: boolean = false;
  Attivita: Attivita;
  Incarico: Incarico;
  Edizione: Edizione;
  Manager: Persona[] = [];
  managerSelezionati: Persona[] = [];
  Aule: Aula[] = [];
  auleSelezionate: Aula[] = [];
  Tutors: Persona[] = [];
  tutorsSelezionati: Persona[] = [];
  CorsiAggiungibili: MultimediaCourse[];
  SelPadre: Attivita[] = [];
  constructor(
    private apiRestService: ApiRestService,
    private modalService: NgbModal,
    private Store: FormazioneStoreService,
    private personaleStore: PersonaleStoreService,
    private filestore: FileStoreService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private datePipe: DatePipe,
    public  permission : Permission
  ) { }

  ngOnInit(): void {
    if(this.permission.isPermitted('gestione-formazione')){
      const filter = {page: 0, size: 15}
      this.apiRestService.getMediaList(filter).subscribe(data => this.CorsiAggiungibili = data.data)
    }
    if( this.permission.isPermitted('gestione-formazione'))
    this.Store.getAule(new Paginazione(0,10));
    this.subscriptions.add(this.Store.$edizione.subscribe((item) => this.Edizione = item));
    this.subscriptions.add(this.Store.$aule.subscribe((item) => this.Aule = item?.data));
    this.subscriptions.add(this.personaleStore.$listaPersonale.subscribe((x) => this.Tutors = x?.data));
    this.subscriptions.add(this.Store.$incarico.subscribe((item) => {
      this.Incarico = item;
      this.Manager = item?.responsabili;
    }));
    this.subscriptions.add(this.Store.$attivita.subscribe((item) => {
      if (item) {
        this.isEditable = item?.id != undefined;
        this.isAddable = item?.id == undefined;
        this.Attivita = item;
        this.CompilaDati(item);
        this.filestore.SetListaFile(item.files);
        this.filestore.SelectSection('attivita');
      }
    }));
  }

  CompilaDati(item: Attivita){
    /*this.Attivita.data_inizio_prevista = this.datePipe.transform(item.data_inizio_prevista, 'yyyy-MM-dd HH:mm')
    this.Attivita.data_fine_prevista = this.datePipe.transform(item.data_fine_prevista, 'yyyy-MM-dd HH:mm')*/
    this.auleSelezionate = item?.aulaObject ? [{id:parseInt(item.aulaObject.id), nome: item.aulaObject.label}]:[]
    this.tutorsSelezionati = item?.tutorObject ? [item.tutorObject] : []
    this.SelPadre = item?.padreObject ? [item.padreObject] : []
    this.managerSelezionati = item?.manager && typeof(item.manager) == "object" ? [item.manager]: []
  }
  Getter(): Attivita {
    if(this.Attivita.async){
      this.Attivita.data_inizio_prevista = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.Attivita.data_fine_prevista = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
    }
    if(this.Incarico)
      this.Attivita.incarico = this.Incarico.id;
    if(this.managerSelezionati && this.managerSelezionati[0])
      this.Attivita.manager = this.managerSelezionati[0].id;
    if(this.auleSelezionate && this.auleSelezionate[0])
      this.Attivita.aula = this.auleSelezionate[0].id;
    if(this.tutorsSelezionati && this.tutorsSelezionati[0])
      this.Attivita.tutor = this.tutorsSelezionati[0].id
    this.Attivita.multimedia = this.Attivita.multimediaObject
    this.Attivita.padre = this.SelPadre ? this.SelPadre[0]?.id : null
    return this.Attivita;
  }

  LezioniEscluseSelezionata(){
    return this.Incarico?.attivita?.filter(x=>x.id != this.Attivita.id) ?? [];
  }

  saveClick(){
    if (this.isAddable) {
      this.apiRestService.AddAttivita(this.Getter()).subscribe(() => {
        this.Store.updateIncarico(this.Incarico);
        this.Store.updateEdizione(this.Edizione);
        this.closeBtnClick();
      });
    } else if (this.isEditable) {
      this.apiRestService.UpdateAttivita(this.Getter()).subscribe((data) => {
        //this.Store.updateAttivita(data); //tolta perche tanto si chiude la modale
        this.Store.updateIncarico(this.Incarico);
        this.Store.updateEdizione(this.Edizione);
        //this.location.back();
        //this.toastService.success("Modifiche apportate","Success!!")
        this.closeBtnClick();
      });
    }
  }
  closeBtnClick() {
    this.Store.updateAttivita(undefined);
    this.modalService.dismissAll();
  }
  filtroPersone(item: string) {
    if (item.length >= 3)
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 0, 1));
  }
  onFileChanged() {
    this.Store.updateIncarico(this.Incarico);
    this.Store.updateEdizione(this.Edizione);
    this.Store.updateAttivita(this.Attivita);
  }

  Segui(multimediaObject: MultimediaCourse[]) {
    var item = multimediaObject.find(x=>true);
    if(!item) return;
    this.open(item);
    this.closeBtnClick();
  }
  open(item: MultimediaCourse) {
    var type = item.tipoObject.id.toString().toUpperCase();
    var _url = 'formazione/' + (type == "V" ? 'video' : (type == "P" ? 'pdf': (type == "Q" ? 'questionario' : '') )) + '/' + item.id.toString() + (this.Attivita ? '/'+ this.Attivita.id : '')
    console.log(_url);
    this.router.navigate( [this.route.snapshot.queryParams[_url] || _url,]).then();
  }
}
