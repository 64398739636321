import { Injectable } from '@angular/core';
import {ApiRestService} from "./api-rest.service";
import {BehaviorSubject} from "rxjs";
import {RitornoPaginazione} from "../../shared/models/RitornoPaginazione";
import {Edizione} from "../_Models/edizione";
import {EdizioniFilter} from "../_Models/edizioni-filter";
import {Incarico} from "../_Models/incarico";
import {ClassicType} from "../../shared/models/ClassicType";
import {Materia} from "../_Models/materia";
import {Attivita} from "../_Models/attivita";
import {Aula} from "../_Models/aula";
import {Sede} from "../../Admin/models/Sede";
import {SedeCorso} from "../_Models/sede-corso";
import {MultimediaCourse} from "../_Models/multimedia-course";

@Injectable({
  providedIn: 'root'
})
export class FormazioneStoreService {
  constructor(private apiRestService: ApiRestService) { }
  //#region Edizioni
  private IS_EDITABLE = new BehaviorSubject<boolean>(false);
  private IS_ADDABLE = new BehaviorSubject<boolean>(false);
  private EDIZIONI = new BehaviorSubject<RitornoPaginazione<Edizione[]>>({data:[], total:0});
  private EDIZIONE = new BehaviorSubject<Edizione>(null);
  private MEDIA = new BehaviorSubject<MultimediaCourse>(null);
  private dataStore:{
    isEditable: boolean,
    isAddable: boolean,
    Edizioni: RitornoPaginazione<Edizione[]>,
    Edizione: Edizione,
    Media: MultimediaCourse
  } = {
    isEditable: false,
    isAddable: false,
    Edizioni: {data:[], total:0},
    Edizione: null,
    Media:null,
  }
  readonly $isEditable = this.IS_EDITABLE.asObservable();
  readonly $isAddable = this.IS_ADDABLE.asObservable();
  readonly $edizioni = this.EDIZIONI.asObservable();
  readonly $edizione = this.EDIZIONE.asObservable();
  readonly $media = this.MEDIA.asObservable();

  refreshIsEditable = () => this.IS_EDITABLE.next(Object.assign({}, this.dataStore).isEditable);
  refreshIsAddable = () => this.IS_ADDABLE.next(Object.assign({}, this.dataStore).isAddable);
  refreshEdizioni = () => this.EDIZIONI.next(Object.assign({}, this.dataStore).Edizioni);
  refreshEdizione = () => this.EDIZIONE.next(Object.assign({}, this.dataStore).Edizione);
  refreshMedia = () => this.MEDIA.next(Object.assign({}, this.dataStore).Media);

  getEdizioniCliente(ClienteId: number) {
    this.apiRestService.getEdizioniCliente({id: ClienteId})
      .subscribe(data => {
        this.dataStore.Edizioni = data;
        this.refreshEdizioni();
        return this.$edizioni;
      });
  }

  getEdizioni(value: EdizioniFilter) {
    this.apiRestService.getEdizioni(value)
      .subscribe(data => {
        this.dataStore.Edizioni = data;
        this.refreshEdizioni();
        return this.$edizioni;
      });
  }
  updateEdizione(value: Edizione) {
    if(value && value.id) {
      this.apiRestService.getInfoEdizione(value).subscribe(data => {
        this.dataStore.Edizione = data;
        this.refreshEdizione();
      });
    }else {
      this.dataStore.Edizione = value;
      this.refreshEdizione();
    }
  }
  updateMedia(value) {
    if(value && value.id) {
      this.apiRestService.getMedia(value).subscribe(data => {
        this.dataStore.Media = data;
        this.refreshMedia();
      });
    }else {
      this.dataStore.Media = value;
      this.refreshMedia();
    }
  }
  updateStoredEdizione() {
    this.apiRestService.getInfoEdizione(this.dataStore.Edizione).subscribe(data => {
      this.dataStore.Edizione = data;
      this.refreshEdizione();
    });
  }
  updateIsAddable(value: boolean) {
    this.dataStore.isAddable = value;
    this.refreshIsAddable();
  }
  updateIsEditable(value: boolean) {
    this.dataStore.isEditable = value;
    this.refreshIsEditable();
  }
  //#endregion
  //#region Incarichi
  private INCARICO_IS_EDITABLE = new BehaviorSubject<boolean>(false);
  private INCARICO_IS_ADDABLE = new BehaviorSubject<boolean>(false);
  private INCARICO = new BehaviorSubject<Incarico>(null);
  private INCARICO_TYPES = new BehaviorSubject<ClassicType[]>([]);
  private dataStoreIncarico:{
    incaricoIsEditable: boolean,
    incaricoIsAddable: boolean,
    incarico: Incarico,
    incaricoTypes: ClassicType[],
  } = {
    incaricoIsEditable: false,
    incaricoIsAddable: false,
    incarico: null,
    incaricoTypes: [],
  }
  readonly $incaricoIsEditable = this.INCARICO_IS_EDITABLE.asObservable();
  readonly $incaricoIsAddable = this.INCARICO_IS_ADDABLE.asObservable();
  readonly $incarico = this.INCARICO.asObservable();
  readonly $incaricoTypes = this.INCARICO_TYPES.asObservable();

  refreshIncaricoIsEditable = () => this.INCARICO_IS_EDITABLE.next(Object.assign({}, this.dataStoreIncarico).incaricoIsEditable);
  refreshIncaricoIsAddable = () => this.INCARICO_IS_ADDABLE.next(Object.assign({}, this.dataStoreIncarico).incaricoIsAddable);
  refreshIncarico = () => this.INCARICO.next(Object.assign({}, this.dataStoreIncarico).incarico);
  refreshIncaricoTypes = () => this.INCARICO_TYPES.next(Object.assign({}, this.dataStoreIncarico).incaricoTypes);

  getIncaricoTypes(){
    this.apiRestService.getTipiIncarico().subscribe(data => {
      this.dataStoreIncarico.incaricoTypes = data;
      this.refreshIncaricoTypes();
    });
  }

  updateIncarico(value: Incarico) {
    if(value && value.id) {
      this.apiRestService.getInfoIncarico(value).subscribe(data => {
        this.dataStoreIncarico.incarico = data;
        this.refreshIncarico();
      });
    }else{
      this.dataStoreIncarico.incarico = value;
      this.refreshIncarico();
    }
  }
  updateIncaricoIsAddable(value: boolean) {
    this.dataStoreIncarico.incaricoIsAddable= value;
    this.refreshIncaricoIsAddable();
  }
  updateIncaricoIsEditable(value: boolean) {
    this.dataStoreIncarico.incaricoIsEditable = value;
    this.refreshIncaricoIsEditable();
  }
  //#endregion
  //#region Materie
  private MATERIA_IS_EDITABLE = new BehaviorSubject<boolean>(false);
  private MATERIA_IS_ADDABLE = new BehaviorSubject<boolean>(false);
  private MATERIE = new BehaviorSubject<RitornoPaginazione<Materia[]>>({data:[], total:0});
  private MATERIA = new BehaviorSubject<Materia>(null);
  private dataStore_materie:{
    materiaIsEditable: boolean,
    materiaIsAddable: boolean,
    materie: RitornoPaginazione<Materia[]>,
    materia: Materia,
  } = {
    materiaIsEditable: false,
    materiaIsAddable: false,
    materie: {data:[], total:0},
    materia: null,
  }
  readonly $materiaIsEditable = this.MATERIA_IS_EDITABLE.asObservable();
  readonly $materiaIsAddable = this.MATERIA_IS_ADDABLE.asObservable();
  readonly $materie = this.MATERIE.asObservable();
  readonly $materia = this.MATERIA.asObservable();

  refreshMateriaIsEditable = () => this.MATERIA_IS_EDITABLE.next(Object.assign({}, this.dataStore_materie).materiaIsEditable);
  refreshMateriaIsAddable = () => this.MATERIA_IS_ADDABLE.next(Object.assign({}, this.dataStore_materie).materiaIsAddable);
  refreshMaterie = () => this.MATERIE.next(Object.assign({}, this.dataStore_materie).materie);
  refreshMateria = () => this.MATERIA.next(Object.assign({}, this.dataStore_materie).materia);

  getMaterie(value) {
    this.apiRestService.getMeterie(value)
      .subscribe(data => {
        this.dataStore_materie.materie = data;
        this.refreshMaterie();
        return this.$materie;
      });
  }
  updateMateria(value: Materia) {
      this.dataStore_materie.materia = value;
      this.refreshMateria();
  }
  updateMateriaIsAddable(value: boolean) {
    this.dataStore_materie.materiaIsAddable = value;
    this.refreshMateriaIsAddable();
  }
  updateMateriaIsEditable(value: boolean) {
    this.dataStore_materie.materiaIsEditable = value;
    this.refreshMateriaIsEditable();
  }
  //#endregion
  //#region Attivita
  private ATTIVITA = new BehaviorSubject<Attivita>(null);
  private dataStoreAttivita:{
    attivita: Attivita,
  } = {
    attivita: null,
  }
  readonly $attivita = this.ATTIVITA.asObservable();

  refreshAttivita = () => this.ATTIVITA.next(Object.assign({}, this.dataStoreAttivita).attivita);

  updateAttivita(value: Attivita) {
    if(value && value.id) {
      this.apiRestService.getInfoAttivita(value).subscribe(data => {
        this.dataStoreAttivita.attivita = data;
        this.refreshAttivita();
      });
    }else{
      this.dataStoreAttivita.attivita = value;
      this.refreshAttivita();
    }
  }
  //#endregion
  //#region Aule
  private AULE = new BehaviorSubject<RitornoPaginazione<Aula[]>>({data:[], total:0});
  private AULA = new BehaviorSubject<Aula>(null);
  private dataStore_aule:{
    aule: RitornoPaginazione<Aula[]>,
    aula: Aula,
  } = {
    aule: {data:[], total:0},
    aula: null,
  }
  readonly $aule = this.AULE.asObservable();
  readonly $aula = this.AULA.asObservable();

  refreshAule = () => this.AULE.next(Object.assign({}, this.dataStore_aule).aule);
  refreshAula = () => this.AULA.next(Object.assign({}, this.dataStore_aule).aula);

  getAule(value) {
    this.apiRestService.getAule(value)
      .subscribe(data => {
        this.dataStore_aule.aule = data;
        this.refreshAule();
        return this.$aule;
      });
  }
  updateAula(value: Aula) {
    this.dataStore_aule.aula = value;
    this.refreshAula();
  }
  //#endregion
  //#region Sedi
  private SEDI = new BehaviorSubject<RitornoPaginazione<SedeCorso[]>>({data:[], total:0});
  private SEDE = new BehaviorSubject<SedeCorso>(null);
  private dataStore_sedi:{
    sedi: RitornoPaginazione<SedeCorso[]>,
    sede: SedeCorso,
  } = {
    sedi: {data:[], total:0},
    sede: null,
  }
  readonly $sedi= this.SEDI.asObservable();
  readonly $sede = this.SEDE.asObservable();

  refreshSedi = () => this.SEDI.next(Object.assign({}, this.dataStore_sedi).sedi);
  refreshSede = () => this.SEDE.next(Object.assign({}, this.dataStore_sedi).sede);

  getSedi(value, all?:boolean) {
    this.apiRestService.getSedi(value, all)
      .subscribe(data => {
        this.dataStore_sedi.sedi = data;
        this.refreshSedi();
        return this.$sedi;
      });
  }
  updateSede(value: SedeCorso) {
    if(value && value.id) {
      this.apiRestService.getInfoSede(value).subscribe(data => {
        this.dataStore_sedi.sede = data;
        this.refreshSede();
      });
    }else {
      this.dataStore_sedi.sede = value;
      this.refreshSede();
    }

  }
  //#endregion

}
