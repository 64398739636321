<ng-container *ngIf="Vertical">
</ng-container>
<ng-container *ngIf="!Vertical">
  <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
    <ng-template
      [ngIf]="campo.inputType != 'checkbox' && campo.inputType != 'singleDropdown' && campo.inputType != 'multiselectDropdown' && campo.type != 'ArrayCampiAggiuntivi' && campo.type != 'checkboxArray'&& campo.type != 'Partecipanti'">
      <span *ngIf="campo.inputType != 'date' && campo.inputType != 'datetime-local' && campo.inputType != 'euro'"
            [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) }}{{campo.suffix ?? ''}}</span>
      <span *ngIf="campo.inputType == 'date'" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy'}}{{campo.suffix ?? ''}}</span>
      <span *ngIf="campo.inputType == 'datetime-local'" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | date: 'dd/MM/yyyy HH:mm'}}{{campo.suffix ?? ''}}</span>
      <span *ngIf="campo.inputType == 'euro'" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{getObject(campo, i) | number: '2.2-2'}}&nbsp;€{{campo.suffix ?? ''}}</span>
      <ng-template [ngIf]="campo.type == 'replace' ">
        {{campoUtility.Replace(campo,Oggetto, oggetto)}} &nbsp;
      </ng-template>
    </ng-template>
    <ng-template
      [ngIf]="campo.type != 'object' && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
    <span  *ngIf="campo.internalOutput && Oggetto[campo.internalOutput]" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.internalOutput][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
      <span  *ngIf="campo.externalOutput" [class]="campo.inputClass"
              [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{DropdownSources[campo.externalOutput][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
    </ng-template>
    <ng-template
      [ngIf]="campo.type == 'object'  && Oggetto[oggetto] && (campo.inputType == 'singleDropdown' || campo.inputType == 'multiselectDropdown')">
    <span  *ngIf="campo.externalOutput" [class]="campo.inputClass"
            [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.externalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
      <span  *ngIf="campo.internalOutput" [class]="campo.inputClass"
              [ngStyle]="campo.inputStyle">{{campo.prefix ?? ''}}{{Oggetto[campo.internalOutput][campo.field[i]][campo.settings.textField]}}{{campo.suffix ?? ''}}</span>
    </ng-template>
    <ng-template [ngIf]="campo.inputType == 'checkbox'">
      <span [style]="{color: getObject(campo,i) == null ? 'gray':getObject(campo,i) == true? 'green':'red'}">
      {{campo.prefix ?? ''}}
        <i [class.far]="getObject(campo,i) == false"  [class.fa-times-circle]="getObject(campo,i) == false" [class.fa-minus-circle]="getObject(campo,i) == null"
            [class.fas]="getObject(campo,i) == null || getObject(campo,i) == true" [class.fa-check-circle]="getObject(campo,i) == true"></i>
        {{campo.suffix ?? ''}}
    </span>
    </ng-template>
    <ng-template [ngIf]="campo.type == 'ArrayCampiAggiuntivi'">
      <app-field-viewer *ngIf="!campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                        [(Campi)]="Oggetto[oggetto]" [(Oggetto)]="Oggetto" [isModifyDisabled]="isModifyDisabled"
                        (OnChange)="OnChange.emit($event)" (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
      <app-field-viewer *ngIf="campo.campiAggiuntivi" [(DropdownSources)]="DropdownSources"
                        [(Campi)]="campo.campiAggiuntivi" [(Oggetto)]="Oggetto"
                        [isModifyDisabled]="isModifyDisabled" (OnChange)="OnChange.emit($event)"
                        (OnFilter)="OnFilter.emit($event)"></app-field-viewer>
    </ng-template>
    <ng-template [ngIf]="campo.type == 'checkboxArray'">
      <app-checkbox-array-viewer [(Oggetto)]="Oggetto" (OnChange)="OnChange.emit($event)" [(CheckList)]="Oggetto[oggetto]"></app-checkbox-array-viewer>
    </ng-template>
    <ng-template [ngIf]="campo.type == 'Partecipanti'">
      <app-partecipanti-list [class]="campo.inputClass" [ngStyle]="campo.inputStyle" type="file" [List]="Oggetto[oggetto]" [hasAddButton]="false" [ModificheAbilitate]="false" [Padre]="Oggetto" ></app-partecipanti-list>
    </ng-template>
  </ng-container>
</ng-container>

