import { ApiRestService } from '../../Progetti/_services/api-rest.service';
import { ApiRestService as ScadenzarioApi } from './api-rest.service';
import { Scadenza } from '../Models/Scadenza';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClassicType } from 'src/app/shared/models/ClassicType';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';

@Injectable({
  providedIn: 'root'
})
export class ScadenzeStoreService {

  constructor(private ApiRestService: ApiRestService, private scadenzarioApi: ScadenzarioApi) { }
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private ADDABLE= new BehaviorSubject<boolean>(false);
  private TIPI_SCADENZA = new BehaviorSubject<ClassicType[]>([]);
  private SCADENZE = new BehaviorSubject<RitornoPaginazione<Scadenza[]>>(null);
  private SCADENZA_SELEZIONATA = new BehaviorSubject<Scadenza>(null);
  private dataStore: {
    Editable: boolean,
    Addable: boolean,
    tipiScadenza: ClassicType[],
    Scadenze: RitornoPaginazione<Scadenza[]>,
    ScadenzaSelezionata: Scadenza,
  } = {
      Editable: false,
      Addable: false,
      tipiScadenza: [],
      Scadenze: null,
      ScadenzaSelezionata: null,
    }
  readonly $editable = this.EDITABLE.asObservable();
  readonly $addable = this.ADDABLE.asObservable();
  readonly $tipi = this.TIPI_SCADENZA.asObservable();
  readonly $scadenze = this.SCADENZE.asObservable();
  readonly $scadenzaSelezionata = this.SCADENZA_SELEZIONATA.asObservable();
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshAddable = () => this.ADDABLE.next(Object.assign({}, this.dataStore).Addable);
  refreshTipi = () => this.TIPI_SCADENZA.next(Object.assign({}, this.dataStore).tipiScadenza);
  refreshScadenze = () => this.SCADENZE.next(Object.assign({}, this.dataStore).Scadenze);
  refreshScadenza = () => this.SCADENZA_SELEZIONATA.next(Object.assign({}, this.dataStore).ScadenzaSelezionata);
  getTipi() {
    this.ApiRestService.getTipiScadenze().subscribe(data => {
      this.dataStore.tipiScadenza = data;
      this.refreshTipi();
    })
  }
  getScadenze(value) {
    this.ApiRestService.getListaScadenze(value).subscribe(data => {
      this.dataStore.Scadenze = data;
      this.refreshScadenze();
    })
  }
  updateScadenzaSelezionata(value: Scadenza) {
    if(value?.id)
      this.scadenzarioApi.getScadenza(value).subscribe(data=>{
        this.dataStore.ScadenzaSelezionata = data;
        this.refreshScadenza();
      });
    else {
      this.dataStore.ScadenzaSelezionata = value;
      this.refreshScadenza();
    }
  }
  updateAddable(value: boolean) {
    this.dataStore.Addable = value;
    this.refreshAddable();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
}
