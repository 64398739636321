import { Component, OnInit } from '@angular/core';
import {ServerPaginationService} from "../_services/server-pagination.service";
import {ApiPathsService} from "../../api-paths.service";
import {Files} from "../models/File";
import {AuthenticationService} from "../../Login/_services/authentication.service";

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit {
  page = 1;
  pageSize = 50;
  pagesizeserver = 4 // pagine da precaricare da lserver
  collectionSize = 0; // numero totale di elementi nell'array
  newpagegenerated = 1; // pagina per cui è stata fatta la richiesta al server
  items: any[]=[];
  constructor(private paginationService: ServerPaginationService, public Api:ApiPathsService,  public authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.getPage(1)
  }
  getPage(page: number) {
    this.page = this.paginationService.paginadellalista(page, this.pagesizeserver);
    const pagetosend = this.paginationService.getPage(page, this.newpagegenerated, this.pagesizeserver);
    if ((pagetosend - 1) % this.pagesizeserver === 0) {
      this.newpagegenerated = page;
      this.refreshTable(pagetosend);
    }
  }
  refreshTable(page:  number){
    this.Api.ClassicPost('/file/list', {page: (page - 1) / this.pagesizeserver +1, size: this.pagesizeserver * this.pageSize}).subscribe(data => {
      this.collectionSize = data.total;
      this.newpagegenerated = page
      this.items = data.data;
    })
  }
  includesExtensions(item: Files, extensions: string[]){
    return !extensions.every(x=> !item.file.includes(x));
  }
}
