import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Task } from "../models/Task";
import { ApiPathsService } from "../../api-paths.service";
import { FileStoreService } from "../../shared/_services/file-store.service";
import { DatePipe } from "@angular/common";
import { environment } from "../../../environments/environment";
import { CampoAggiuntivo, CampoUtility, Sezione } from "../../shared/models/campo-aggiuntivo";
import { ToastrService } from "ngx-toastr";
import { Permission } from 'src/app/Login/_guards/Permission';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { ApiRestService as RmaApiRestService } from "../../rma/_services/api-rest.service";
import { Subscription } from 'rxjs';
import { TaskService } from '../_service/task.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() IsModal: boolean = true;
  @Input() Item: any = {};
  @Input() ExternalAdd: boolean = false;
  @Input() HideDelete: boolean = false;
  @Input() FromLista: boolean = false;
  @Input() HideButtons: boolean = false;
  @Output() updated = new EventEmitter<Task>();
  @Input() DynamicFields: {
    type?: string,
    addButton?: boolean,
    restRoute?: string,
    Label?: string,
    Azioni?: any[],
    campi?: any[]
    sezioni?: any[],
    managerLoggato?: any,
    initObj?: any[]
  };
  environment = environment;
  DropdownSources: any = {};
  ResetFields: boolean = false;
  Stampe: any;
  constructor(
    private ngbActiveModal: NgbActiveModal,
    private apiPaths: ApiPathsService,
    private filestore: FileStoreService,
    private datepipe: DatePipe,
    private toastrService: ToastrService,
    public campoUtility: CampoUtility,
    public permission: Permission,
    private authSvc: AuthenticationService,
    private modalService: NgbModal,
    private rmaApiRest: RmaApiRestService,
    private taskService: TaskService
  ) { }

  ngOnInit(): void {
    this.Refresh();
  }

  Refresh() {
    if (this.Item?.id) {
      this.ResetFields = true
      this.apiPaths.ClassicPost(`${this.DynamicFields.restRoute}/get`, { id: this.Item.id }).subscribe(data => {
        if (!data) return;
        this.Item = data?.data ?? data;
        this.Stampe = data?.stampe;
        this.Item['ripetizione'] = this.Item['ripetizione'] ?? { nGiorni: null };
        this.Item['categoria'] = this.Item['categorie']?.find(x => true)?.id;
        this.taskService.InitObject(this.Item, this.DynamicFields);
        if (this.Item['somministrazione'])
          this.Item['somministrazione']['somministrazioni'] = this.Item['orari']?.length;
        this.Item['alert_inizio_task'] = this.Item['alert_inizio_task'] ?? 0;
        this.Item['alert_fine_task'] = this.Item['alert_fine_task'] ?? 0;

        if (this.Item['clientiObject']?.length > 0 && this.Item.type == "E" && this.Item.tipo == "LA")
          this.Item['clientiObject']?.forEach(
            cliente => {
              if (this.Item.figli?.length > 0)
                this.Item.figli.forEach(
                  colonna => {
                    if (colonna.esiti.findIndex(x => x.cliente == cliente.id) < 0)
                      colonna.esiti.push({ cliente: cliente.id, esiti: [0, 0, 0] })
                  }
                )
            }
          )

        this.FormBuilder();
        this.filestore.SetListaFile(this.Item.files);
        this.filestore.SelectSection(this.DynamicFields.restRoute);
        setTimeout(() => {
          this.ResetFields = false;
        }, 100)
      })
    }
    else {

      this.Item = this.Item ?? {};
      this.Item['ripetizione'] = { nGiorni: null };
      this.taskService.InitObject(this.Item, this.DynamicFields);
      this.Item['alert_inizio_task'] = this.Item['alert_inizio_task'] ?? 0;
      this.Item['alert_fine_task'] = this.Item['alert_fine_task'] ?? 0;
      this.Item['ora_inizio'] = this.Item['ora_inizio'] ?? '00:00';
      this.Item['ora_fine'] = this.Item['ora_fine'] ?? '23:59';
      this.Item.data_inizio_prevista = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
      this.setManager();
    }
  }
  setManager() {
    if (this.campoUtility.Show({ hideif: this.DynamicFields.managerLoggato, modificabile: true }, this.Item, true))
      this.Item.managerObject = this.Item.managerObject ?? this.authSvc.currentUserValue;
  }

  FormBuilder() {
    this.DynamicFields?.sezioni?.forEach(sezione => {
      sezione.campi?.forEach(campo => {
        this.SetCampoSource(this.Item, campo);
      })
    })
    this.DynamicFields?.campi?.forEach(campo => {
      this.SetCampoSource(this.Item, campo);
    })
    this.setManager();

  }

  SetCampoSource(item, campo) {
    if ((campo['type'] == 'checkboxArray' || campo['type'] == 'checkboxArrayMEXC') && (!campo['filter'] || item?.sedeObject?.clienteObject?.id)) {
      console.log(item[campo['oggetti'][0]]);
      this.rmaApiRest.GetCampiAggiuntiviRmas(item[campo['oggetti'][0]]?.filter(x => typeof x == 'number' ? true : x.risposta) ?? [], campo['oggetti'][0], campo['filter'] ? { id: item?.sedeObject?.clienteObject?.id } : null).subscribe(data => {
        item[campo['oggetti'][0]] = data;
      });
    }
    if (campo['source'] == 'PrioritaSource')
      this.apiPaths.ClassicGet('/rma/rma/priorita').subscribe(data => this.DropdownSources.PrioritaSource = data ?? []);

    if (campo['source'] == 'PeriodiSource') {
      this.apiPaths.ClassicGet('/rma/rma/periodi').subscribe(data => {
        this.DropdownSources.PeriodiSource = data;
      })
    }
  }
  close() {
    this.ngbActiveModal.dismiss();
  }
  save(close?: boolean) {
    this.taskService.save(this.Item, this.DynamicFields, close, this.ExternalAdd, (x) => {
      console.info("SAVE BUTTON END", { X: x, Item: this.Item, DynamicFields: this.DynamicFields, close: close, ExternalAdd: this.ExternalAdd })
      this.saved(x.Item, x.close)
    });
    // var  subscriptions: Subscription = new Subscription();
    // subscriptions.add(  this.taskService.updated.subscribe(x=> {
    //   subscriptions.unsubscribe();
    //   this.updated.emit(x);
    // }));
    // subscriptions.add(  this.taskService.Refresh.subscribe(x=> {
    //   this.saved(x, close)
    //   subscriptions.unsubscribe();
    // }));
  }
  saved(data, close?: boolean, external?: boolean) {
    //console.log("Error", this.ValidateError(data))
    if (!external)
      this.Item.id = data.id;
    this.updated.emit(this.Item);
    this.Refresh();
    if (close)
      this.close();
  }
  onFileChanged() {
    this.Refresh();
  }

  ChangeHandler(item: { OnChange: string; Event: any, CampoAggiuntivo?: CampoAggiuntivo }) {
    console.log("Changehandler: ", item);
    switch (item.OnChange) {
      case 'OnFilesChange':
        this.Refresh();
        break;
      case 'AddCatCom':
        if (!this.Item['clientiObject']) this.Item['clientiObject'] = [];
        if (this.Item['addCatCom']) {
          this.apiPaths.ClassicPost('/anagrafica/clienti/list', { page: 0, size: 10 }).subscribe(data => {
            var clientiCatCom = data?.data?.filter(x => x.tipiObject?.find(y => y.id == this.Item['addCatCom']?.id))
            console.log(`Clienti di categoria ${this.Item['addCatCom']?.nome}`, clientiCatCom);
            console.log(`Task: `, this.Item);
            this.Item['clientiObject'] = [...this.Item['clientiObject'], ...clientiCatCom];
            setTimeout(() => { this.Item['addCatCom'] = null; }, 300);
          })

        }
        break;
      case 'AddAllCatCom':
        if (!this.Item['clientiObject']) this.Item['clientiObject'] = [];
        this.apiPaths.ClassicPost('/anagrafica/clienti/list', { page: 0, size: 10 }).subscribe(data => {
          var clientiCatCom = data?.data?.filter(x => x.tipiObject)
          this.Item['clientiObject'] = [...this.Item['clientiObject'], ...clientiCatCom];
          setTimeout(() => { this.Item['addCatCom'] = null; }, 300);
        })


        break;
      case 'OnDelete':
        this.Item['clientiObject'] = this.Item['clientiObject'].filter(x => x.id != item.Event.id);
        break;
      case 'somministrazionichange':
        console.log('Somministrazione Change', this.Item)
        if (!this.Item['somministrazione']['somministrazioni']) break;
        this.Item['orari'] = this.Item['orari'] ?? [];
        const attualeNumSomministrazioni = this.Item['orari']?.length ?? 0;

        if (this.Item['somministrazione']['somministrazioni'] > attualeNumSomministrazioni) {
          for (let i = 0; i < this.Item['somministrazione']['somministrazioni'] - attualeNumSomministrazioni; i++) {
            this.Item['orari'].push({});
          }
        } else if (this.Item['somministrazione']['somministrazioni'] < attualeNumSomministrazioni) {
          for (let i = 0; i < attualeNumSomministrazioni - this.Item['somministrazione']['somministrazioni']; i++) {
            this.Item['orari'].pop();
          }
        }
        break;
      case 'OnTableEdit':
        console.log('Item ChangeHandler', item)
        var dynamicFields = this.campoUtility.getDynamicTaskSctructure(item?.CampoAggiuntivo?.tasktype ?? item?.Event?.type, item?.CampoAggiuntivo["taskfilter"]);
        // var dynamicFields = this.environment.TaskAddon['DynamicFields']?.find(x => x.type.toLocaleUpperCase() == (item?.CampoAggiuntivo?.tasktype ?? item?.Event?.type)?.toLocaleUpperCase());
        if (!dynamicFields) return;
        let Newsmodal = this.modalService.open(NewsComponent, {
          centered: true,
          backdrop: 'static',
          size: 'xl',
        });
        (<NewsComponent>Newsmodal.componentInstance).DynamicFields = dynamicFields;
        (<NewsComponent>Newsmodal.componentInstance).IsModal = true;
        (<NewsComponent>Newsmodal.componentInstance).Item = item.Event ?? new Task();
        (<NewsComponent>Newsmodal.componentInstance).updated.subscribe(data => {
          this.Refresh()
        });
        break;

      case 'OnDeleteObiettivo':

        break;
      case 'OnPartecipantiChange':
        // if(this.Item.partecipantiObject)
        // this.Item.partecipanti = this.Item.partecipantiObject.map(x=>x.id);
        break;

    }
  }
  getparam(oggetto, param) {
    return oggetto ? oggetto[param] : null;
  }
  customButton(selected, button) {
    this.Item.type = this.Item.type ?? this.DynamicFields.type;
    this.taskService.HandleTaskCustomButtonClick(this.Item, button, false, (x) => {
      this.saved(x, button.close, true)
    });
  }



  isTaskType(tipi: string[]) {
    return tipi?.includes(this.Item.type);
  }
  // addDynamicButton(dynamicField) {
  //   var cliente ;
  //   if(this.Item['clientiObject'])
  //     cliente = this.Item['clientiObject'].map(x=>x.id);
  //   const editmodal = this.modalService.open(NewsComponent, {
  //     centered: true,
  //     backdrop: 'static',
  //     size: 'xl',
  //   });
  //   (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
  //   (<NewsComponent>editmodal.componentInstance).IsModal = true;
  //   (<NewsComponent>editmodal.componentInstance).Item = {
  //     cliente:  this.Item.cliente,
  //     clienti: cliente,
  //     clienteObject: this.Item.clienteObject,
  //     valutazione: this.Item.type == "V" ? this.Item.id : null,
  //     padre: ((this.Item.type == "O" && dynamicField.type == 'G') || this.Item.type == "E" || (dynamicField.type == "Y"))  ? this.Item.id : null,
  //     rma: this.Item.type == "R" ? this.Item.id : null,
  //     obiettivo: this.Item.type == "O" ? this.Item.id : null,
  //     scadenza: this.Item.type == "S"  ? this.Item.id : null,
  //     nome: this.Item.type == "E"  ? this.Item.nome : "",
  //     tipo: dynamicField.tipo,
  //   };
  //   (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
  //     this.Refresh();
  //   });
  // }
  FilterHandler($event: { OnFilter: string; Event: any }) {

  }




  UpdateAttivitas() {
    this.Item?.figli?.forEach(figlio => {
      this.apiPaths.ClassicPost(`${this.campoUtility.getDynamicTaskSctructure(figlio.type, figlio?.tipo ?? null)?.restRoute}/update`, figlio).subscribe()
    })
  }
}
