import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {ApiPathsService} from "../../api-paths.service";
import {Observable} from "rxjs";
import {ClassicType} from "../../shared/models/ClassicType";
import {map} from "rxjs/operators";
import {RitornoPaginazione} from "../../shared/models/RitornoPaginazione";
import {Rma} from "../_Models/rma";
import { Categorie } from 'src/app/Progetti/Models/Categorie';
import {Segnalazione} from "../_Models/segnalazione";
import {Azienda} from "../../Admin/models/Azienda";
import {ProvaAcquisto} from "../_Models/prova-acquisto";
import {CampoAggiuntivo} from "../../shared/models/campo-aggiuntivo";
import {RmaData} from "../RmaData";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
    private rmaData: RmaData
  ) { }
  options() {
    return {
      headers: this.rmaData.getRmaHeaders()
    };
  }
  link = this.rmaData.getRmaLink() ?? this.apiPaths.getBaseAPI();
  GetRmaTypes(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.link}/rma/rma/types`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  GetRmaList(value, all?:boolean,Calendar?: boolean): Observable<RitornoPaginazione<Rma[]>> {
    console.log("Rma List Filter: ", value);
    if(value?.list == 'rma')
      return this.http.post<RitornoPaginazione<Rma[]>>(
        `${this.link + '/rma/rma/list'}`,
        JSON.stringify(value),
        this.options()).pipe(map((data) => {
        return data;
      })) ;
      else
      return this.http.post<RitornoPaginazione<Rma[]>>(
        `${this.link + (Calendar? '/task/task/calendar' : '/task/task/list')}`,
        JSON.stringify(value),
        this.options()).pipe(map((data) => {
        return data;
      })) ;

  }
  GetRma(value): Observable<RitornoPaginazione<Rma>> {
    return this.http.post<RitornoPaginazione<Rma>>(
      `${this.link}/rma/rma/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  UpdateRma(value): Observable<any> {
    return this.http.post<any>(
      `${this.link}/rma/rma/update`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  AddRma(value): Observable<any> {
    return this.http.post<any>(
      `${this.link}/rma/rma/add`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  //#region ProdottiKey
  getProveAcquistoSede(filter){
    return this.http.post<RitornoPaginazione<ProvaAcquisto[]>>(
      `${this.link}/rma/register/sede`,
      JSON.stringify(filter),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getProveAcquisto(filter): Observable<RitornoPaginazione<ProvaAcquisto[]>> {
    return this.http.post<RitornoPaginazione<ProvaAcquisto[]>>(
      `${this.link}/rma/register/list`,
      JSON.stringify(filter),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getProveAcquistoCliente(filter): Observable<RitornoPaginazione<ProvaAcquisto[]>> {
    return this.http.post<RitornoPaginazione<ProvaAcquisto[]>>(
      `${this.link}/rma/register/cliente`,
      JSON.stringify(filter),
      this.options()).pipe(map((data) => {
        data.data.forEach(provaAcquisto => {
          if(provaAcquisto?.sedeObject)
          provaAcquisto.sedeObject.fullAddress = provaAcquisto.sedeObject.label ?? provaAcquisto.sedeObject.fullAddress;
          if(provaAcquisto?.prodottoObject)
          provaAcquisto.prodottoObject.prodotto = provaAcquisto.prodottoObject.label ?? provaAcquisto.prodottoObject.prodotto;
        })
      return data;
    }));
  }
  getProveAcquistoProgetto(filter): Observable<RitornoPaginazione<ProvaAcquisto[]>> {
    return this.http.post<RitornoPaginazione<ProvaAcquisto[]>>(
      `${this.link}/rma/register/progetto`,
      JSON.stringify(filter),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  addProvaAcquisto(value): Observable<any> {
    return this.http.post<any>(`${this.link}/rma/register/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  editProvaAcquisto(value): Observable<any> {
    return this.http.post<any>(`${this.link}/rma/register/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteProvaAcquisto(value): Observable<any> {
    return this.http.post<any>(`${this.link}/rma/register/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  getProdottiKeyTypes(): Observable<ClassicType[]>{
    return this.http.get<ClassicType[]>(
      `${this.link}/rma/register/types`,
      this.options()).pipe(map((data) => {
        const elencoTipi: ClassicType[] = data;
        return elencoTipi;
      })
    );
  }
  registra_prodotto(value): Observable<any> {
    return this.http.post<any>(`${this.link}/rma/register/licenza`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  registra_prodottoWithID(value): Observable<any> {
    return this.http.post<any>(`${this.link}/rma/register/licenza/prodotto`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  getCategoriaSeriale(value): Observable<Categorie> {
    return this.http.post<Categorie>(
      `${this.link}/rma/register/categoria`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  //#endregion
  //#region segnalazione
  GetSegnalazione(value: Segnalazione): Observable<Segnalazione> {
    return this.http.post<Segnalazione>(
      `${this.link}/rma/segnalazione/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  UpdateSegnalazione(value): Observable<any> {
    return this.http.post<any>(
      `${this.link}/rma/segnalazione/update`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  AddSegnalazione(value): Observable<any> {
    return this.http.post<any>(
      `${this.link}/rma/segnalazione/add`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  DeleteSegnalazione(value): Observable<any> {
    return this.http.post<any>(
      `${this.link}/rma/segnalazione/delete`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  //#endregion
  GetCA(value): Observable<RitornoPaginazione<Azienda[]>> {
    return this.http.post<RitornoPaginazione<Azienda[]>>(
      `${this.link}/rma/rma/assistenza`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  GetCampiAggiuntiviRmas(attivita:  any[], type: string, filter?):Observable<{check?:string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string}[]>{
    console.log("attivita", attivita);
    if(filter) {
      return this.http.post<{ check: string, id: number, risposta: boolean, label?: string, codice: string, fields: { label: string, name: string, type: 'number' | 'dropdown' | 'string', values: any[], tariffe?: { max?: number, min: number, prezzo: number }[] }[] }[]>(
        `${this.link}/rma/rma/${type}`,
        JSON.stringify(filter),
        this.options()).pipe(map((data) => {
        const ret: { check?: string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string }[] = [];
        data.forEach(check => {
          if(typeof attivita?.find(x=>true) == 'number' )
            ret.push({
              check: check.check ?? check.label,
              id: check.id,
              risposta: attivita?.find(x => x == check.id) != undefined,
              fields: this.mapCustomField(check),
              codice: check.codice
            })
          else {
            var att = attivita.find(x=> x.id == check.id);
            console.log("att",att)
            if(att){
              att.check = check.check ?? check.label
                att.id= check.id
                att.codice= check.codice
              ret.push(att);
            }else{
              ret.push({
                check: check.check ?? check.label,
                id: check.id,
                risposta: attivita?.find(x => x == check.id) != undefined,
                fields: this.mapCustomField(check),
                codice: check.codice
              })
            }
          }
        })

        return ret;
      }));
    }
    else
      return this.http.get<{check:string, id: number, risposta: boolean, label?: string, codice: string, fields: {label:string, name: string, type: 'number' | 'dropdown' | 'string', values: any[], tariffe?: {max?:number, min: number, prezzo: number}[]}[]}[]>(
        `${this.link}/rma/rma/${type}`,
        this.options()).pipe(map((data) => {
        const ret: {check?:string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string}[] = [];
        data.forEach(check => {
          if(typeof attivita?.find(x=>true) == 'number' )
            ret.push({
              check: check.check ?? check.label,
              id: check.id,
              risposta: attivita?.find(x => x == check.id) != undefined,
              fields: this.mapCustomField(check),
              codice: check.codice
            })
          else {
            var att = attivita?.find(x=> x.id == check.id);
            console.log("att",att)
            if(att){
              att.check = check.check ?? check.label
              att.id= check.id
              att.codice= check.codice
              ret.push(att);
            }else{
              ret.push({
                check: check.check ?? check.label,
                id: check.id,
                risposta: attivita?.find(x => x == check.id) != undefined,
                fields: this.mapCustomField(check),
                codice: check.codice
              })
            }
          }
        })
        return ret;
      }));
  }




  mapCustomField(check:{check:string, id: number, fields: {label:string, name: string, type: 'number' | 'dropdown' | 'string', values: any[], tariffe?: {max?:number, min: number, prezzo: number}[]}[]} ){
    return check?.fields?.map(field => {
      const campo: CampoAggiuntivo = {
        nome: field.label,
        oggetti:[field.name],
        modificabile: true,
        OnChange: "getPrice",
        class: check.fields.length > 1 ? 'col-lg-6' : 'col-12',
        tariffe: field.tariffe
      }
      switch (field.type)
      {
        case 'dropdown':
          campo.inputType = 'singleDropdown';
          campo.source = field.values;
          campo.internalOutput = field.name;
          campo.OutputObject = field.name+"Object";
          campo.settings = {
            singleSelection: true,
            closeDropDownOnSelection: true,
            singleOutput: true,
            idField: 'id',
            textField: 'label',
          }

          break;
        case "number":
          campo.inputType = 'number';
          break;
        case "string":
          campo.inputType = 'text';
          break;
      }
      return campo
    })
  }
}
