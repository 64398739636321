import { Pipe, PipeTransform } from '@angular/core';

import {IDropdownSettings, ListItem} from './multiselect.model';

@Pipe({
    name: 'multiSelectFilter',
    pure: false
})
export class ListFilterPipe implements PipeTransform {
    transform(items: ListItem[], filter: ListItem, _settings: IDropdownSettings): ListItem[] {
        if (!items || !filter) return items;
        return items?.filter((item: ListItem) => this.applyFilter(item, filter, _settings)) ?? [];
    }

    public applyFilter(item: ListItem, filter: ListItem, _settings: IDropdownSettings): boolean {
      var filtered = false;
        if (typeof item[_settings?.textField ?? 'text'] === 'string' && typeof filter[_settings?.textField ?? 'text'] === 'string') {
          filtered = !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toLowerCase().indexOf(filter[_settings?.textField ?? 'text'].toLowerCase()) === -1);
        } else {
          filtered= !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toString().toLowerCase().indexOf(filter.text.toString().toLowerCase()) === -1);
        }
        return filtered && (item[_settings?.idField ?? 'id'] || item[_settings?.idField ?? 'id'] == false)  && !this.isExcludedId(_settings, item);

    }

    public isExcludedId(_settings: IDropdownSettings, item: ListItem): boolean {
      return _settings?.excludeIds?.includes(item[_settings?.idField ?? 'id'])
    }
}
