<div class="d-md-flex align-items-center justify-content-between bg-white p-2">
    <span class="d-flex align-items-center p-2 ">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Totale:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Lista.length}}</span>
    </span>
<!--  <div class="ml-auto mt-3 mt-md-0">-->
<!--    <label class="control-label col-form-label">Tipo</label>-->
<!--    <ng-multiselect-dropdown style="width: 400px;" [settings]="classicTypeDropdownSettings" [placeholder]="'Selezionare il tipo__'" [data]="Types" [(ngModel)]="SelectedTypes" (ngModelChange)="refreshTable(1)"></ng-multiselect-dropdown>-->
<!--  </div>-->
  <div class="ml-auto mt-3 mt-md-0" *ngIf="cat_id != 0">
    <button type="button" (click)="add()"  class="btn btn-cyan rounded-pill p-2" ><i
      class="fas fa-plus  font-14 mr-1 text-white"></i>
      <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table bg-white table-hover align-middle mb-0 ">
    <thead>
    <tr>
      <th >Id</th>
      <th scope="col">Ordine</th>
      <th scope="col">Titolo</th>
      <th scope="col">Risposta</th>
      <th scope="col">Con Note</th>
      <th scope="col">Aperta</th>
      <th scope="col">Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of Lista | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <td>
        <!-- <img [src]='co.Imagepath' class="rounded-circle mr-2" alt="" height="45" width="45"> -->
        <span class="text-dark font-medium font-16">
          {{ item.id}}
          </span>
      </td>
<!--      <td class="align-middle">-->
<!--        <span *ngIf="item.modifica">-->
<!--          <ng-multiselect-dropdown style="width: 400px;" [settings]="classicTypeDropdownSettings" [placeholder]="'Selezionare il tipo__'" [data]="categorie" [(ngModel)]="item.categorieSelezionate" (ngModelChange)="refreshTable(1)"></ng-multiselect-dropdown>-->
<!--          <input [(ngModel)]="item.codice" placeholder="codice" type="text" name="codice" class="form-control">-->
<!--        </span>-->
<!--      </td>-->
      <td class="align-middle">
        <span *ngIf="!item.modifica">
          {{ item.codice}}
        </span>
        <span *ngIf="item.modifica">
          <input [(ngModel)]="item.codice" placeholder="Ordine" type="text" name="codice" class="form-control">
        </span>
      </td>
      <td class="align-middle " >
        <span *ngIf="!item.modifica">
          {{ item.check}}
        </span>
        <span *ngIf="item.modifica">
          <input [(ngModel)]="item.check" placeholder="titolo" type="text" name="check" class="form-control">
        </span>
      </td>
      <td class="align-middle " >
        <span *ngIf="!item.modifica">
          {{ item.risposta}}
        </span>
        <span *ngIf="item.modifica">
          <input [(ngModel)]="item.risposta" placeholder="check" type="checkbox" name="check" class="form-control">
        </span>
      </td>
      <td class="align-middle " >
        <span *ngIf="!item.modifica">
          {{ item.conNote}}
        </span>
        <span *ngIf="item.modifica">
          <input [(ngModel)]="item.conNote" placeholder="check" type="checkbox" name="check" class="form-control">
        </span>
      </td>
      <td class="align-middle " >
        <span *ngIf="!item.modifica">
          {{ item.isNota}}
        </span>
        <span *ngIf="item.modifica">
          <input [(ngModel)]="item.isNota" placeholder="check" type="checkbox" name="check" class="form-control">
        </span>
      </td>
      <td class="align-middle" >
        <a *ngIf="!item.modifica"  href="javascript:void(0)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2"
           (click)='edit(item)'>
          <i-feather name="edit-3" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="item.modifica" href="javascript:void(0)" ngbTooltip="Salva" class="link font-16 text-success font-medium mr-2" (click)='edit(item)'>
          <i-feather name="check" class="feather-sm"></i-feather>
        </a>
<!--        *ngIf="permission.isPermitted('gestione-formazione')">-->
        <a href="javascript:void(0)" class="link text-danger font-16 font-weight-bold" (click)='delete(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center p-2 pt-3 bg-white border-top">
  <ngb-pagination (pageChange)="getPage($event)" id="paginationComponent" [maxSize]="10" [collectionSize]="Lista.length" [pageSize]="pageSize"></ngb-pagination>
</div>
