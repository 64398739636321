
<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title" >{{'RMA.ca.title' | translate}}</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="{'modal-body': IsModal}">
  <div class="row p-0" style="height: 100%;">
<!--  <div class="col-4">
    <app-lista-clienti [(Clienti)]="ListaClienti"></app-lista-clienti>
  </div>-->
  <div class="col-12"  >
    <div class="card"  style="height: 600px;">
      <div class="card-body" style="height: 100%;">
        <app-maps
          [centerMaplat]="centerMaplat"
          [centerMaplon]="centerMaplon"
          [markers]="Markers"
          [circle]="circleMarker"
          (onCircleDrag)="CircleDrag($event)"
          (onCircleMarkerChange)="onCircleMarkerChange.emit($event)"
          (onMarkerClick)="onMarkerClick($event)"
        ></app-maps>
      </div>
    </div>
  </div>
</div>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
  <button type="submit" (click)="save()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Seleziona </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
