import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {ApiPathsService} from "../../api-paths.service";
import {Observable} from "rxjs";
import {ClassicType} from "../../shared/models/ClassicType";
import {map} from "rxjs/operators";
import {RitornoPaginazione} from "../../shared/models/RitornoPaginazione";
import {Edizione} from "../_Models/edizione";
import {EdizioniFilter} from "../_Models/edizioni-filter";
import {RespIncarico} from "../_Models/resp-incarico";
import {Incarico} from "../_Models/incarico";
import {Materia} from "../_Models/materia";
import {Attivita} from "../_Models/attivita";
import {Presenze} from "../_Models/presenze";
import {Aula} from "../_Models/aula";
import {Sede} from "../../Admin/models/Sede";
import {SedeCorso} from "../_Models/sede-corso";
import {Categorie} from "../../Progetti/Models/Categorie";
import {MultimediaCourse} from "../_Models/multimedia-course";
import {CheckList} from "../_Models/CheckList";
import {Check} from "../_Models/Check";
import {Categoria} from "../_Models/categoria";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  headerforfile() {
    return new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  fileHeader() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  //#region Edizioni
  getEdizioni(value: EdizioniFilter): Observable<RitornoPaginazione<Edizione[]>> {
    return this.http.post<RitornoPaginazione<Edizione[]>>(
      `${this.apiPaths.getBaseAPI()}/servizi/edizioni/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getEdizioniCliente(param: { id: number }) {
    return this.http.post<Edizione[]>(
      `${this.apiPaths.getBaseAPI()}/servizi/edizioni/alunno`,
      JSON.stringify(param),
      this.options()).pipe(map((data) => {
      return {data: data, total: data?.length ?? 0} ;
    }));
  }
  getInfoEdizione(value: Edizione): Observable<Edizione> {
    return this.http.post<Edizione>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/get `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  getAuleEdizione(value: Edizione): Observable<Aula[]> {
    return this.http.post<Aula[]>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/aule `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddEdizione(value: Edizione): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateEdizione(value: Edizione): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/update `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteEdizione(value: Edizione): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Incarico
  getInfoIncarico(value: Incarico): Observable<Incarico> {
    return this.http.post<Incarico>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/get `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  getTipiIncarico(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/types `,
      this.options()).pipe();
  }
  AddIncarico(value: Incarico): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateIncarico(value: Incarico): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/update `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteIncarico(value: Incarico): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region ResponsabileIncarico
  AddRespIncarico(value: RespIncarico): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/responsabile/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteRespIncarico(value: RespIncarico): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/incarichi/responsabile/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Meterie
  getMeterie(value): Observable<RitornoPaginazione<Materia[]>> {
    return this.http.post<RitornoPaginazione<Materia[]>>(`${this.apiPaths.getBaseAPI()}/servizi/materie/list `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddMeterie(value: Materia): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/materie/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateMeterie(value: Materia): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/materie/update `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteMeterie(value: Materia): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/materie/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Attivita
  getInfoAttivita(value: Attivita): Observable<Attivita> {
    return this.http.post<Attivita>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/get `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddAttivita(value: Attivita): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateAttivita(value: Attivita): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/update `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteAttivita(value: Attivita): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  PresenzeAttivita(value: Presenze): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/presenze `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Aula
  getAule(value): Observable<RitornoPaginazione<Aula[]>> {
    return this.http.post<RitornoPaginazione<Aula[]>>(`${this.apiPaths.getBaseAPI()}/servizi/aule/list `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddAula(value: Aula): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/aule/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateAula(value: Aula): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/aule/update `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteAula(value: Aula): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/aule/delete `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region alunno ed
  AddStudenteEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/alunno/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteStudenteEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/alunno/del`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region tutor ed
  AddTutorEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/tutor/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteTutorEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/edizioni/tutor/del`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region sedi
  getSedi(value, All?:boolean): Observable<RitornoPaginazione<SedeCorso[]>> {
    if(All)
    return this.http.post<RitornoPaginazione<SedeCorso[]>>(
      `${this.apiPaths.getBaseAPI()}/servizi/sedi/listAll`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
    else
      return this.http.post<RitornoPaginazione<SedeCorso[]>>(
        `${this.apiPaths.getBaseAPI()}/servizi/sedi/list`,
        JSON.stringify(value),
        this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getInfoSede(value: Sede): Observable<SedeCorso> {
    return this.http.post<SedeCorso>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/get `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddSedi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateSedi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteSedi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/del`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddSediEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/edizione/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteSediEdizione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/sedi/edizione/del`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //# region media
  getMediaTypes():Observable<ClassicType[]>{
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/formazione/corsi/types`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getMediaList(value):Observable<RitornoPaginazione<MultimediaCourse[]>>{
    return this.http.post<RitornoPaginazione<MultimediaCourse[]>>(
      `${this.apiPaths.getBaseAPI()}/formazione/corsi/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getMedia(value):Observable<MultimediaCourse>{
    return this.http.post<MultimediaCourse>(
      `${this.apiPaths.getBaseAPI()}${value.id_task ?'/servizi/attivita/multimedia':'/formazione/corsi/get'}`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getMediaCategorie(): Observable<Categorie[]> {
    return this.http.get<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/formazione/categorie/list`,
      this.options()).pipe(map((data) => {
      const elencoTipi: Categorie[] = [];
      data.forEach((tipo) => {
        elencoTipi.push(tipo);
      })
      return elencoTipi;
    }));
  }
  getMediaCategorieSearch(value?): Observable<Categorie[]> {
    return this.http.post<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/formazione/categorie/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      const elencoTipi: Categorie[] = [];
      data.forEach((tipo) => {
        elencoTipi.push(tipo);
      })
      return elencoTipi;
    }));
  }
  updateMediaCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/categorie/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addMediaCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/categorie/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteMediaCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/categorie/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddStudenteMediaCorso(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/corsi/partecipante/add `,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteStudenteMediaCorso(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/corsi/partecipante/del`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateAvanzamentoStudenteMediaCorso(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/formazione/corsi/partecipante/avanzamento`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateAvanzamentoMediaCorso(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/avanzamento`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //# region questionario
  getQuestionario(value){
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/servizi/attivita/domande`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getchecklist(value): Observable<CheckList> { //"checks": [], "outcomes": []
    return this.http.post<CheckList>(`${this.apiPaths.getBaseAPI()}/formazione/corsi/domande`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/formazione/risposte/add`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  updateCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/formazione/risposte/update`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  deleteCheck(updateData: Check) {
    return this.http.post<Check[]>(`${this.apiPaths.getBaseAPI()}/formazione/risposte/delete`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }
  getChecks(value) {
    return this.http.post<RitornoPaginazione<Check[]>>(`${this.apiPaths.getBaseAPI()}/formazione/risposte/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addCheckListCategoria(updateData: Categoria) {
    return this.http.post<Categoria[]>(`${this.apiPaths.getBaseAPI()}/formazione/domande/add`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  updateCheckListCategoria(updateData: Categoria) {
    return this.http.post<Categoria[]>(`${this.apiPaths.getBaseAPI()}/formazione/domande/update`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  deleteCheckListCategoria(updateData: Categoria) {
    return this.http.post<Categoria[]>(`${this.apiPaths.getBaseAPI()}/formazione/domande/delete`,
      JSON.stringify(updateData),
      this.options()).pipe();
  }

  getCheckListCategorie() {
    return this.http.get<Categoria[]>(`${this.apiPaths.getBaseAPI()}/formazione/domande/list`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  getCheckListCategorieSearch(value) {
    return this.http.post<Categoria[]>(`${this.apiPaths.getBaseAPI()}/formazione/domande/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  //#endregion

}
