<div class="top-navbar">
  <div class="social">
      <a href="https://www.instagram.com/klimaitalia_global" target="_blank"><img src="https://www.klimaitalia.it/wp-content/uploads/2021/09/in.png" alt="IG"></a>
      <a href="https://it.linkedin.com/company/klimaitalia" target="_blank"><img src="https://www.klimaitalia.it/wp-content/uploads/2021/09/lk.png" alt="LK"></a>
      <a href="https://www.facebook.com/klimaitaliaglobal" target="_blank"><img src="https://www.klimaitalia.it/wp-content/uploads/2021/09/fb.png" alt="FB"></a>
  </div>
  <div class="contatti">
      <a href="mailto:info@klimaitalia.it"><img src="https://www.klimaitalia.it/wp-content/uploads/2021/09/mail.png" alt="MAIL" width="19" height="15">info@klimaitalia.it</a>
      <a href="tel:+390804971444"><img src="https://www.klimaitalia.it/wp-content/uploads/2021/09/phone.png" alt="PHONE" width="20" height="21">+39 080 497 1444</a>
  </div>

  <div class="lingue">
      <i *ngIf="selectedLanguage !== 'us'" style="cursor: pointer;" class="flag-icon flag-icon-us" (click)="changeLanguage('us')"></i>
      <i *ngIf="selectedLanguage !== 'fr'" style="cursor: pointer;" class="flag-icon flag-icon-fr" (click)="changeLanguage('fr')"></i>
      <i *ngIf="selectedLanguage !== 'de'" style="cursor: pointer;" class="flag-icon flag-icon-de" (click)="changeLanguage('de')"></i>
      <i *ngIf="selectedLanguage !== 'es'" style="cursor: pointer;" class="flag-icon flag-icon-es" (click)="changeLanguage('es')"></i>
      <i *ngIf="selectedLanguage !== 'it'" style="cursor: pointer;" class="flag-icon flag-icon-it" (click)="changeLanguage('it')"></i>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" (click)="toggleMenu()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="javascript:void(0);" (click)="navigate('ecommerce/cat')">
    <img src="assets/images/KlimaNuovo.png" alt="Logo" width="auto" height="52" class="d-inline-block align-top">
  </a>

  <div #navbarTogglerDemo03 class="collapse navbar-collapse" id="navbarTogglerDemo03">
    <ul style="font-size: 18px;" class="navbar-nav ml-auto mt-2 mt-lg-0">
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/cat')">{{'HOME'|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/products')">{{'PRODOTTI'|translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/assistenza')">{{'ASSISTENZA'|translate}}</a>
      </li>
      <li class="nav-item" id="profilo">
          <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/profilo')">{{'PROFILO'|translate}}</a>
      </li>
      <li *ngIf="isLogged()" class="nav-item" id="profilo">
        <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/orders')">{{'ORDINI'|translate}}</a>
      </li>
      <li *ngIf="isLogged()" class="nav-item" id="profilo">
        <a class="nav-link" href="javascript:void(0);" (click)="navigate('ecommerce/cart')">{{'CARRELLO'|translate}}</a>
      </li>
      <li *ngIf="isLogged()" class="nav-item" id="profilo">
        <a class="nav-link" href="javascript:void(0);" (click)="logout()">{{'LOGOUT'|translate}}</a>
      </li>
      
    </ul>
    <i class="fa fa-shopping-cart" *ngIf="isLogged()" (click)="navigate('ecommerce/cart')" style="font-size: 20px; color: white; padding-left: 10px; cursor: pointer;"></i>
    <span class="navbar-text dropdown">
      <i class="fas fa-user" style="font-size: 20px; cursor: pointer;" (click)="toggleDropdown()"></i>
      <div *ngIf="isLogged()" class="dropdown-menu" [class.show]="dropdownOpen">
        <a class="dropdown-item" (click)="navigateAndClose('ecommerce/orders')"  href="javascript:void(0);">{{'ORDINI' | translate}}</a>
        <a class="dropdown-item" (click)="navigateAndClose('ecommerce/profilo')" href="javascript:void(0);">{{'PROFILO' | translate}}</a>
        <a class="dropdown-item" (click)="logout()" href="javascript:void(0);">{{'LOGOUT' | translate}}</a>
      </div>
    </span>
  </div>
</nav>
<app-breadcrumb-site></app-breadcrumb-site>
<app-spinner></app-spinner>
<router-outlet></router-outlet>
