<div style="height: 100%;">
  <agm-map style="height: 100%;" [latitude]="centerMaplat" [longitude]="centerMaplon" (centerChange) ="mapCenterChange($event)" (idle)="mapCenterChanged()" [zoom]="zoom"
    [disableDefaultUI]="false" [zoomControl]="true" (mapClick)="mapClicked($event)">
    <agm-marker *ngFor="let m of markers; let i = index" (markerDblClick)="clickedMarker(m)" [latitude]="m.lat"
      [longitude]="m.lng" [label]="m.label" [iconUrl]="m.iconUrl" [markerDraggable]="m.draggable"
      (dragEnd)="markerDragEnd(m, $event)" [visible]="m.visible" >
      <agm-info-window>
        <span style="font-weight: bold">{{'cliente.nominativo' | translate}}:</span> {{m?.interventoObject?.nominativo}} <br>
        <span style="font-weight: bold">{{'cliente.indirizzo' | translate}}:</span> {{m?.interventoObject?.indirizzo}} <br>
        <span style="font-weight: bold">{{'cliente.provincia' | translate}}:</span> {{m?.interventoObject?.provincia}} <br>
        <span style="font-weight: bold">{{'cliente.cap' | translate}}:</span> {{m?.interventoObject?.cap}} <br>
        <span style="font-weight: bold">{{'cliente.citta' | translate}}:</span> {{m?.interventoObject?.citta}} <br>
        <ngb-rating [(rate)]="m.interventoObject.rate"></ngb-rating>
        <div class="m-t-10">Rate: <b>{{m?.interventoObject?.rate}}</b></div>
      </agm-info-window>
    </agm-marker>
    <agm-circle *ngIf="hasCircle"
     [latitude]="circle.latitude"
     [longitude]="circle.longitude"
     [radius]="circle.radius"
     [fillColor]="circle.fillColor"
     [circleDraggable]="circle.circleDraggable"
     [editable]="circle.editable"
     [usePanning]="true"
     (centerChange)="circleMarkerCenterChange($event)"
     (radiusChange)="rangeCircleMarkerChanged($event)"
     (circleClick)= "clickedCircleMarker($event)"
     (dragEnd)="circleMarkerDragEnd()">
    </agm-circle>
  </agm-map>
</div>
