import { Sede } from './../models/Sede';
import { Attivita } from './../models/Attivita';
import { ClassicType } from './../../shared/models/ClassicType';
import { Azienda } from './../models/Azienda';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRestService } from './api-rest.service';
import { Paginazione } from 'src/app/shared/models/paginazione';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AziendeStoreService {
  constructor(private apiRestService: ApiRestService,
    private router: Router,
    private route: ActivatedRoute) { }
  //#region definitions
  private LISTA_AZIENDE = new BehaviorSubject<RitornoPaginazione<Azienda[]>>(null);
  private TIPLOGIE = new BehaviorSubject<ClassicType[]>([]);
  private SELECTED_AZIENDA = new BehaviorSubject<Azienda>(null);
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private TIPOLOGIE_ATTIVITA = new BehaviorSubject<ClassicType[]>([]);
  private ATTIVITA = new BehaviorSubject<Attivita[]>([]);
  private ATTIVITA_SELEZIONATA = new BehaviorSubject<Attivita>(null);
  private TIPOLOGIE_SEDI = new BehaviorSubject<ClassicType[]>([]);
  private SEDI = new BehaviorSubject<Sede[]>([]);
  private dataStore: {
    ListaAziende: RitornoPaginazione<Azienda[]>,
    Tipologie: ClassicType[],
    SelectedAzienda: Azienda,
    Editable: boolean,
    ShowAddForm: boolean,
    TipiAttivita: ClassicType[],
    Attivita: Attivita[],
    AttivitaSelezionata: Attivita,
    TipologieSedi: ClassicType[],
    Sedi: Sede[],
  } = {
      ListaAziende: null,
      Tipologie: [],
      SelectedAzienda: null,
      Editable: false,
      ShowAddForm: false,
      TipiAttivita: [],
      Attivita: [],
      AttivitaSelezionata: null,
      TipologieSedi: [],
      Sedi: [],
    }
  readonly $listaAziende = this.LISTA_AZIENDE.asObservable();
  readonly $selectedAzienda = this.SELECTED_AZIENDA.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $tipologie = this.TIPLOGIE.asObservable();
  readonly $tipiAttivita = this.TIPOLOGIE_ATTIVITA.asObservable();
  readonly $attivita = this.ATTIVITA.asObservable();
  readonly $attivitaSelezionata = this.ATTIVITA_SELEZIONATA.asObservable();
  readonly $tipologieSedi = this.TIPOLOGIE_SEDI.asObservable();
  readonly $sedi = this.SEDI.asObservable();
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshListaAziende = () => this.LISTA_AZIENDE.next(Object.assign({}, this.dataStore).ListaAziende);
  refreshSelectedAzienda = () => this.SELECTED_AZIENDA.next(Object.assign({}, this.dataStore).SelectedAzienda);
  refreshTipologie = () => this.TIPLOGIE.next(Object.assign({}, this.dataStore).Tipologie);
  refreshTipiAttivita = () => this.TIPOLOGIE_ATTIVITA.next(Object.assign({}, this.dataStore).TipiAttivita);
  refreshAttivita = () => this.ATTIVITA.next(Object.assign({}, this.dataStore).Attivita);
  refreshAttivitaSelezionata = () => this.ATTIVITA_SELEZIONATA.next(Object.assign({}, this.dataStore).AttivitaSelezionata);
  refreshSedi = () => this.SEDI.next(Object.assign({}, this.dataStore).Sedi);
  refreshTipologieSedi = () => this.TIPOLOGIE_SEDI.next(Object.assign({}, this.dataStore).TipologieSedi);
  //#endregion
  //#region get
  getListaAziende(value) {
    this.apiRestService.getListaAziende(value)
      .subscribe(data => {
        this.dataStore.ListaAziende = data;
        this.refreshListaAziende();
      });
    return this.$listaAziende;
  }
  getTipologieClienti() {
    this.apiRestService.getTipologieClienti()
      .subscribe(data => {
        this.dataStore.Tipologie = data;
        this.refreshTipologie();
      });
    return this.$tipologie;
  }
  getTipologieClientiAsString(): string[] {
    const elenco: string[] = [];
    this.dataStore.Tipologie.forEach(item => {
      elenco.push(item.label);
    })
    return elenco
  }
  getTipologieAtivita() {
    this.apiRestService.getTipologieAttivita()
      .subscribe(data => {
        this.dataStore.TipiAttivita = data;
        this.refreshTipiAttivita();
      });
    return this.$tipiAttivita;
  }
  getTipologieSedi() {
    this.apiRestService.getTipiSedi()
      .subscribe(data => {
        this.dataStore.TipologieSedi = data;
        this.refreshTipologieSedi();
      });
    return this.$tipologieSedi;
  }
  //#endregion
  //#region update
  updateSelectedAzienda(value: Azienda) {
    if(value && value.id)
      this.apiRestService.getInfoAziende(value).subscribe(data => {
        this.dataStore.SelectedAzienda = data;
        this.refreshSelectedAzienda();
        if (environment.ClientiAddOn.attivita) {
          this.dataStore.Attivita = data.attivita;
          this.refreshAttivita();
        }
        if(environment.ClientiAddOn.sedi){
          this.dataStore.Sedi = data.sedi;
          this.refreshSedi();
        }
      });
    else {
      this.dataStore.SelectedAzienda = value;
      this.refreshSelectedAzienda();
      if (environment.ClientiAddOn.attivita && value) {
        this.dataStore.Attivita = value.attivita;
        this.refreshAttivita();
      }
      if(environment.ClientiAddOn.sedi && value){
        this.dataStore.Sedi = value.sedi;
        this.refreshSedi();
      }
    }
  }
  updateSelectedAttivita(value: Attivita) {
    if (value?.id)
      this.apiRestService.getInfoAttivita(value).subscribe(data=>{
        this.dataStore.AttivitaSelezionata = data;
        this.refreshAttivitaSelezionata();
      })
    else{
      this.dataStore.AttivitaSelezionata = value;
      this.refreshAttivitaSelezionata();
    }

  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
    this.dataStore.ShowAddForm = value;
    this.refreshShowAddForm();
  }
  updateCompany(value: Azienda) {
    this.apiRestService.updateCompany(value).subscribe((data) => {
      this.getListaAziende(new Paginazione(1, 1000));
      this.router.navigate([this.route.snapshot.queryParams[' listautenti/aziende '] ||'listautenti/aziende',])
    });
  }
  updateAttivita(value: Attivita, cliente: Azienda) {
    this.apiRestService.updateAttivita(value).subscribe(() => {
      this.Refresh(cliente);
    });
  }
  updateSedi(value: Sede, cliente: Azienda) {
    this.apiRestService.updateSedi(value).subscribe(() => {
      this.Refresh(cliente);
    });
  }
  //#endregion
  //#region add
  addNewCompany(value: Azienda) {
    this.apiRestService.addNewCompany(value).subscribe((data) => {
      this.getListaAziende(new Paginazione(1, 1000));
      this.router.navigate([this.route.snapshot.queryParams[' listautenti/aziende '] || 'listautenti/aziende',])
      this.updateShowAddForm(false);
      this.updateEditable(false);
    });
  }
  addNewSede(value: Sede, cliente: Azienda) {
    this.apiRestService.addNewSedi(value).subscribe(() => {
      this.Refresh(cliente);
    });
  }
  Refresh(cliente: Azienda) {
    this.apiRestService.getInfoAziende(cliente).subscribe(data => {
      this.dataStore.SelectedAzienda = data;
      this.refreshSelectedAzienda();
      this.dataStore.Attivita = data.attivita;
      this.refreshAttivita();
    });

  }
  Refresh_data() {
    if(this.dataStore?.SelectedAzienda?.id)
      this.apiRestService.getInfoAziende(this.dataStore.SelectedAzienda).subscribe(data => {
        this.dataStore.SelectedAzienda = data;
        this.refreshSelectedAzienda();
        this.dataStore.Attivita = data.attivita;
        this.refreshAttivita();
      });

  }
  //#endregion
  //#region delete
  deleteCompany(value: Azienda) {
    this.apiRestService.deleteCompany(value).subscribe(() => {
      this.getListaAziende(new Paginazione(1,1000));
    });
  }
  deleteAttivita(value: Attivita, cliente: Azienda) {
    this.apiRestService.deleteAttivita(value).subscribe(() => {
      this.Refresh(cliente);
    });
  }
  deleteSede(value: Sede, cliente: Azienda) {
    this.apiRestService.deleteSede(value).subscribe(() => {
      this.Refresh(cliente);
    });
  }
  //#endregion
  //#region utility
  nominativo(cliente: Azienda): string {
    return cliente.nominativo;
  }
  clienteByNominativo(nominativo: string): Azienda {
    return this.dataStore.ListaAziende.data.find(x => x.nominativo === nominativo);
  }
  listaNominativi(): string[] {
    const result: string[] = [];
    this.dataStore.ListaAziende.data.forEach(cliente => result.push(cliente.nominativo));
    return result;
  }
  //#endregion
}
