import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {Persona} from "../../Admin/models/persona";
import {RicercaPaginazione} from "../../Admin/models/sendpersonale";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {ToastrService} from "ngx-toastr";
import {ApiRestService} from "../_services/api-rest.service";
import {ApiPathsService} from "../../api-paths.service";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";


@Component({
  selector: 'app-partecipanti-list',
  templateUrl: './partecipanti-list.component.html',
  styleUrls: ['./partecipanti-list.component.css']
})
export class PartecipantiListComponent implements OnInit, OnChanges{
  @Input() List: Persona[] = [];
  @Output() ListChange = new EventEmitter<Persona[]>();
  @Input() Padre: any = undefined;
  @Input() hasHeader: Boolean = true;
  @Input() hasFilter: Boolean = false;
  @Input() hasAddButton: Boolean = false;
  @Input() ModificheAbilitate: boolean = true;
  @Input() EditInModal: boolean=true;
  @Input() filtri: any = null;
  @Output() onChange = new EventEmitter();
  @Input() rest;
  @Input() titolo: string = 'Partecipanti';
  @Input() Typed: Boolean;
  @Input() Types:any[];

  Responsabili: Persona[] = [];
  personeSelezionate: Persona[] = [];
  userDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };

  tipoDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    singleOutput: true,
    idField: 'id',
    textField: 'label',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  constructor(
    private apiRestService: ApiRestService,
    private personaleStore: PersonaleStoreService,
    private ToastService: ToastrService,
    private apiPaths:ApiPathsService
  ) { }

  ngOnInit(): void {
    this.personaleStore.$listaPersonale.subscribe(x => {
      if (x && x.data && x.data.length > 0)
        this.Responsabili = x.data
    });
    this.GetTypes();
  }

  GetTypes(){
    if (!Array.isArray(this.List)) return;
    if(!this.Types && this.Typed && this.List) {
      this.apiPaths.ClassicGet('/auth/roles').subscribe(data => {
        this.Types = data ?? [];

        console.log('Lista Partecipanti', this.List);
      });
    }
    else if (this.Typed && this.List){

      console.log('Lista Partecipanti', this.List);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
   this.GetTypes()
  }


  add() {
    if(!this.List?.find(x=>x.id == undefined)){
        const toBeAdded = new Persona();
        toBeAdded.InModifica = true;
        this.List?.push(toBeAdded)
    }else
      this.ToastService.warning("Attenzione", "Salvare l'utente inserito prima di inserirne uno nuovo.")
  }
  save(item: Persona) {
    if (!this.personeSelezionate || !this.personeSelezionate[0] || !this.personeSelezionate[0].id)
      this.ToastService.warning("Attenzione", "Selezionare un Utente.")
    else if (this.List?.find(x => x.id == this.personeSelezionate[0].id))
      this.ToastService.warning("Attenzione", "Questo utente è già stato associato.")
    else if (!this.Padre)
      this.ToastService.warning("Attenzione", "ID padre non trovato")
    else if(this.rest)
      this.apiRestService.AddPartecipante({id: this.Padre.id, partecipante: this.personeSelezionate[0].id}).subscribe(()=> {
         this.onChange.emit();
         item.id = this.personeSelezionate[0].id;
         item.fullName = this.personeSelezionate[0].fullName;
         item.InModifica = false;
      })
    else{
      item.id = this.personeSelezionate[0].id;
      item.fullName = this.personeSelezionate[0].fullName;
      item['tipo'] = item['tipoObject']?.id;
      item.InModifica = false;
    }

  }
  delete(item: Persona) {
    var idx =-1;
      idx = this.List.findIndex(x => x == item);
      if (!isNaN(idx))
        this.List.splice(idx, 1)
  }
  filtroPersonale(item: string) {
    if (item.length >= 3) {
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 0, 1));
    }
  }

  lista(){
    return this.List?.map(x=>{
      x['tipoObject'] = this.Types?.find(y=> y.id == x['tipo'])
      return x;
    })
  }

  tipoChange(item: Persona) {
    item['tipo'] = item['tipoObject']?.id;
  }
}
