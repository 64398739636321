<ng-container *ngIf="true" >
  <span class="font-14"
   [ngClass]="{'badge badge-pill px-3': campoUtility.Colore(campo,item)}" 
   [ngStyle]="{'color': campoUtility.Colore(campo,item)?  LightenColor(campoUtility.Colore(campo,item)) : '', 'background-color': campoUtility.Colore(campo,item)}">
    <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
      <app-field-viewer *ngIf="!CampoIs(campo, ['objArray', 'replace'])" [Campi]="[campo]"  [hideLabel]="true" [Oggetto]="item"></app-field-viewer>

      <!-- <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'date' && item[oggetto]">
        {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy'}} &nbsp;
      </ng-template>
      <ng-template
        [ngIf]="campo.type == 'object' && campo.inputType == 'date' && campoUtility.getObj(item, oggetto) ">
        {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy'}} &nbsp;
      </ng-template>
      <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'datetime-local' && item[oggetto]">
        {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
      </ng-template>
      <ng-template
        [ngIf]="campo.type == 'object' && campo.inputType == 'datetime-local' && campoUtility.getObj(item, oggetto)">
        {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
      </ng-template>
       <ng-template
         [ngIf]="campo.type!= 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && campo.type != 'replace' && campo.type != 'objArray' && campo.inputType != 'singleDropdown'">
        {{campoUtility.getObj(item, oggetto)}} &nbsp;
      </ng-template>
      <ng-template
        [ngIf]="campo.type == 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && item[oggetto] && campoUtility.getObj(item, oggetto)">
        {{campoUtility.getObj(item, oggetto)[campo.field[i]]}} &nbsp;
      </ng-template>
      <ng-template
        [ngIf]="campo.type == 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && !item[oggetto] && campo.oggettisec && campo.oggettisec[i] && item[campo.oggettisec[i]]">
        {{item[campo.oggettisec[i]][campo.field[i]]}} &nbsp;
      </ng-template> -->
      <ng-template [ngIf]="campo.type == 'replace' ">
        {{campoUtility.Replace(campo, item, oggetto)}} &nbsp;
      </ng-template>
      <ng-template [ngIf]="campo.type == 'objArray' ">
        <ng-container *ngFor="let obg of campoUtility.getObj(item, oggetto)">
          {{obg[campo.field[i]]}}&nbsp;
        </ng-container>
      </ng-template>

   </ng-container>
  </span>
</ng-container>