<div *ngIf="CheckList && CheckList.todo">
  <div class="card"  *ngFor="let todo of CheckList.todo">
    <div class="card-body p-0" >
      <h4 class="card-title">{{todo.categoria}}</h4>
      <div class="table-responsive" *ngIf="todo.foglie?.length>0">
        <table class="table bg-white table-hover align-middle p-0" style="margin-bottom: 70px;">
          <tbody>
          <tr *ngFor="let item of todo.foglie">
            <td >
            <span >
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i-feather name="info" class="feather-sm"></i-feather>
              </span>
              {{ item.check }}
            </span>
            </td>
            <!--<td class="align-middle" >
            <span *ngIf="!item.modifica">
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i-feather name="list" class="feather-sm"></i-feather>
              </span>
              {{ item.esito}}
            </span>
              <span *ngIf="item.modifica ">
              <select [(ngModel)]="item.esito" placeholder="Esito" name="outcome" class="custom-select">
                <option *ngFor="let label of OutcomeList" [value]="label" >{{label}}</option>
              </select>
            </span>
            </td>-->
            <td class="align-middle" >
            <span *ngIf="!item.modifica">
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                
              </span>
               <!--{{ item.valore}}-->
            </span>
            <!--<span *ngIf="item.modifica">
              <input [(ngModel)]="item.valore" type="number" min="0"  placeholder="valore" name="" class="custom-select">
            </span>  [(ngModel)]="ripetizione.frequency"
                  <select id="value"  name="value" class="form-input" required>
                    <option>NV = Area non valutabile o non pertinente</option>
                    <option value="0">0 = Assenza di Problemi</option>
                    <option value="1">1 = Presenza di un Problema (intervento effettuato)</option>
                    <option value="2">2 = Presenza di un Problema (progetto di intervento effettuato)</option>
                    <option value="3">3 = Intervento in Corso</option>
                    <option value="4">4 = Intervento Concluso (problema in tutto o in parte risolto)</option>
                  </select>-->

                  <ng-multiselect-dropdown *ngIf="item" [settings]="dropdownSettings" [placeholder]="'Seleziona'" name="value"
                                             [data]="valueList" [(ngModel)]="item.valoreObject" (ngModelChange)="value(item)">
                  </ng-multiselect-dropdown>
            </td>
            
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="padding-inline: 30px" class="card-body" *ngFor="let figlio of todo.figli">
      <h4 class="card-title">{{figlio.categoria}}</h4>
      <div class="table-responsive">
        <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">
          <thead>
          <!--<tr>
            <th>Check</th>
            <th scope="col">Valore</th>
            
          </tr>-->
          </thead>
          <tbody>
          <tr *ngFor="let item of figlio.foglie">
            <td >
            <span >
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i-feather name="info" class="feather-sm"></i-feather>
              </span>
              {{ item.check }}
            </span>
            </td>
            <!--<td class="align-middle" >-->
<!--            <span *ngIf="!item.modifica">-->
<!--              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">-->
<!--                <i-feather name="list" class="feather-sm"></i-feather>-->
<!--              </span>-->
<!--              {{ item.esito}}-->
<!--            </span>-->
              <!--<span >--> <!--*ngIf="item.modifica ">-->
             <!-- <select [(ngModel)]="item.esito" (ngModelChange)="onChange(item)" placeholder="Esito" name="outcome" class="custom-select">
                <option *ngFor="let label of OutcomeList" [value]="label" >{{label}}</option>
              </select>
            </span>
            </td>
            <td class="align-middle" >-->
<!--            <span *ngIf="!item.modifica">-->
<!--              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">-->
<!--                <i-feather name="list" class="feather-sm"></i-feather>-->
<!--              </span>-->
<!--              {{ item.valore}}-->
<!--            </span>-->
              <!-- <span > *ngIf="item.modifica ">
              <input [(ngModel)]="item.valore" (ngModelChange)="onChange(item)" type="number" min="0"  placeholder="valore" name="" class="custom-select">
            </span>
            </td>-->
<!--            <td class="align-middle" *ngIf="!isModifyDisabled" >-->
<!--              <a *ngIf="!item.modifica"  href="javascript:void(0)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2"-->
<!--                 (click)='edit(item)'>-->
<!--                <i-feather name="edit-3" class="feather-sm"></i-feather>-->
<!--              </a>-->
<!--              <a *ngIf="item.modifica" href="javascript:void(0)" ngbTooltip="Salva" class="link font-16 text-success font-medium mr-2" (click)='edit(item)'>-->
<!--                <i-feather name="check" class="feather-sm"></i-feather>-->
<!--              </a>-->
<!--              &lt;!&ndash; <a href="javascript:void(0)" ngbTooltip="Elimina"-->
<!--                class="link font-16 text-danger font-weight-bold" (click)='delete(item)'>-->
<!--                <i-feather name="trash-2" class="feather-sm"></i-feather>-->
<!--              </a> &ndash;&gt;-->
<!--            </td>-->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
