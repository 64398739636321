import { Prodotto } from './../Models/Prodotto';
import { RitornoPaginazione } from '../../shared/models/RitornoPaginazione';
import { ClassicType } from '../../shared/models/ClassicType';
import { Categorie } from '../Models/Categorie';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRestService } from './api-rest.service';
@Injectable({
  providedIn: 'root'
})
export class ProdottiStoreService {
  constructor(private apiRestService: ApiRestService){}
  private UNITA_DI_MISURA = new BehaviorSubject<ClassicType[]>([]);
  private CATEGORIE_PRODOTTI = new BehaviorSubject<Categorie[]>([]);
  private TYPES = new BehaviorSubject<ClassicType[]>([]);
  private CATEGORIE_PRODOTTI_LINEARE = new BehaviorSubject<Categorie[]>([]); // in pratica una lista di tutte le categorie senza figli per la visualizzazione nelle select
  private PRODOTTI = new BehaviorSubject<RitornoPaginazione<Prodotto[]>>(new RitornoPaginazione<Prodotto[]>());
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private SELECTED_PRODOTTO = new BehaviorSubject<Prodotto>(null);
  private SHOWALLPRODOTTI = new BehaviorSubject<boolean>(false);

  private dataStore: {
    CategorieProdotti: Categorie[],
    CategorieProdottiLineare: Categorie[],
    UnitaDiMisura: ClassicType[],
    Types: ClassicType[],
    Prodotti: RitornoPaginazione<Prodotto[]>,
    Editable: boolean,
    ShowAddForm: boolean,
    SelectedProdotto: Prodotto,
    ShowAllProdotti: boolean,
  } = {
      CategorieProdotti: [],
      CategorieProdottiLineare: [],
      UnitaDiMisura:[],
      Types: [],
      Prodotti: new RitornoPaginazione<Prodotto[]>(),
      Editable: false,
      ShowAddForm: false,
      SelectedProdotto: null,
      ShowAllProdotti: false,
    }
  readonly $categorieProdotti = this.CATEGORIE_PRODOTTI.asObservable();
  readonly $categorieProdottiLineare = this.CATEGORIE_PRODOTTI_LINEARE.asObservable();
  readonly $unitaDiMisura = this.UNITA_DI_MISURA.asObservable();
  readonly $types = this.TYPES.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $prodotti = this.PRODOTTI.asObservable();
  readonly $selectedProdotto = this.SELECTED_PRODOTTO.asObservable();
  readonly $showAllProdotti = this.SHOWALLPRODOTTI.asObservable();
  refreshCategorieProdotti = () => this.CATEGORIE_PRODOTTI.next(Object.assign({}, this.dataStore).CategorieProdotti);
  refreshCategorieProdottiLineare = () => this.CATEGORIE_PRODOTTI_LINEARE.next(Object.assign({}, this.dataStore).CategorieProdottiLineare);
  refreshUnitaDiMisura = () => this.UNITA_DI_MISURA.next(Object.assign({}, this.dataStore).UnitaDiMisura);
  refreshTypes = () => this.TYPES.next(Object.assign({}, this.dataStore).Types);
  refreshprodotti = () => this.PRODOTTI.next(Object.assign({}, this.dataStore).Prodotti);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshSelectedProdotto = () => this.SELECTED_PRODOTTO.next(Object.assign({}, this.dataStore).SelectedProdotto);
  refreshShowAllProdotti = () => this.SHOWALLPRODOTTI.next(Object.assign({}, this.dataStore).ShowAllProdotti);
  getCategorieProdotti() {
    this.apiRestService.getCategorie()
      .subscribe(data => {
      this.dataStore.CategorieProdotti = data;
      this.refreshCategorieProdotti();
      return this.$categorieProdotti;
    })
  }
  getCategorieListanofigli(value) {
    this.apiRestService.getCategorieListanofigli(value)
      .subscribe(data => {
      this.dataStore.CategorieProdottiLineare = data;
      this.refreshCategorieProdottiLineare();
      return this.$categorieProdottiLineare;
    })
  }
  getUnitaDiMisura(){
    this.apiRestService.getUnitaDiMisura()
      .subscribe(data => {
      this.dataStore.UnitaDiMisura = data;
      this.refreshUnitaDiMisura();
      return this.$unitaDiMisura;
    });
  }
  getTypes(){
    this.apiRestService.getProdottiTypes()
      .subscribe(data => {
      this.dataStore.Types = data;
      this.refreshTypes();
      return this.$types;
    });
  }
  getProdotti(value) {
    if(!this.dataStore.ShowAllProdotti){
      this.apiRestService.getProdotti(value)
        .subscribe(data => {
        this.dataStore.Prodotti = data;
        this.refreshprodotti();
        return this.$prodotti;
      })
    } else {
      return this.getAllProdotti(value);
    }
  }
  getAllProdotti(value) {
    this.apiRestService.getAllProdotti(value)
      .subscribe(data => {
      this.dataStore.Prodotti = data;
      this.refreshprodotti();
      return this.$prodotti;
    })
  }

  updateShowAllProdotti(value: boolean) {
    this.dataStore.ShowAllProdotti = value;
    this.refreshShowAllProdotti();
  }
  updateSelectedProdotto(value: Prodotto) {
    if(value !== null){
      this.apiRestService.getInfoProdotto(value).subscribe(data=>{
        this.dataStore.SelectedProdotto = data;
        this.refreshSelectedProdotto();
      }, error => {
        this.dataStore.SelectedProdotto = value;
        this.refreshSelectedProdotto();
      });
    }
    else {
      this.dataStore.SelectedProdotto = value;
      this.refreshSelectedProdotto();
    }
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
    this.dataStore.ShowAddForm = value;
    this.refreshShowAddForm();
  }
  updateItems(getvalue ,Postvalue: Prodotto) {
    this.apiRestService.updateProdotti(Postvalue).subscribe((data) => {
      this.getProdotti(getvalue);
    });
  }
  addNewItem(value: Prodotto) {
    return this.apiRestService.addProdotti(value)
  }
  deleteItem(getvalue ,Postvalue: Prodotto) {
    this.apiRestService.deleteProdotti(Postvalue).subscribe((data) => {
       this.getProdotti(getvalue);
    });
  }
  renewItem(getvalue ,Postvalue: Prodotto) {
    this.apiRestService.restoreProdotti(Postvalue).subscribe((data) => {
       this.getProdotti(getvalue);
    });
  }

  UMSelezionate(value: Prodotto): ClassicType[] {
    const unitaDiMisura = []
    value.prezzi?.forEach(prezzo => unitaDiMisura.push({ id: prezzo.um.toString(), label: prezzo.umlabel }));
    return unitaDiMisura;
  }






}
