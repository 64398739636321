<div *ngFor="let Source of Sources">
    <div class=" p-2" *ngIf="HasAdd" style="background-color: rgba(21, 0, 254, 0.502);">
        <div class="d-flex justify-content-end">
          <a href="javascript:void(0)" (click)="AbilitaModifiche()" *ngIf="permission.HasCapacity(Capacita)" class="btn bg-white text-success py-1 px-2 link"
            placement="bottom" ngbTooltip="Abilita Modifiche">
            <i class="fas fa-pencil-alt"></i>
          </a>
          <a href="javascript:void(0)" *ngIf="permission.HasCapacity(Capacita)" (click)="openModal(editTicketModal, {listType: Source.tipo}, null)" placement="bottom"
            ngbTooltip="Aggiungi" class="btn bg-white text-info py-1 px-2 link ">
            <i-feather name="plus" class="feather-sm"></i-feather>
          </a>
        </div>
    </div>
    <div class="card">
        <h5 class="card-title">{{Source.nome | translate}}</h5>
        <div class="card-body">
            <ul class="list-group custom-group message-center" id="sidebar">
                <li class=" list-group-item " style="padding-right: 0; padding-bottom: 0; border:none;" *ngFor="let sidebarnavItem of Categorie | CatListPipe: Source.tipo">
                    <a href="javascript:void(0)" (click)="clicked(sidebarnavItem)"
                    class="message-item d-flex align-items-center border-0 font-weight-normal">
                    <i *ngIf="sel_cat_id === sidebarnavItem.id" class=" text-info fa-folder fas font-18 mr-2"></i>
                    <i *ngIf="sel_cat_id !== sidebarnavItem.id" class="fa-folder fas font-18 mr-2"></i>
                    {{sidebarnavItem.categoria}}
                    <div *ngIf="modificheAbilitate" class="ml-auto">
                        <a href="javascript:void(0)" (click)="openModal(editTicketModal, sidebarnavItem, null)"
                        class="btn bg-white text-success py-1 px-2 link" placement="bottom" ngbTooltip="Modifica">
                        <i class="fas fa-pencil-alt"></i>
                        </a>
                        <a href="javascript:void(0)" (click)="openModal(DeleteCategoriaModal, sidebarnavItem, null)"
                        class="btn bg-white text-danger py-1 px-2 link" placement="bottom" ngbTooltip="Elimina">
                        <i-feather name="trash-2" class="feather-sm"></i-feather>
                        </a>
                    </div>
                    </a>
                <app-categorie-nav 
                    [(Categorie)]="sidebarnavItem.figli"
                    [Sublist]="true"
                    (Refresh)="RefreshList()" 
                    (Save)="Salva($event)"
                    (Navigate)="Navigate.emit($event)" 
                    [(modificheAbilitate)]="modificheAbilitate"
                    [(sel_cat_id)]="sel_cat_id"
                    [Capacita]="Capacita"
                    (DeleteClick)="Delete($event)"
                    [(CatFull)]="CatFull"
                     ></app-categorie-nav>
               </li>
            </ul>
        </div>
    </div>
</div>


<ng-template #editTicketModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel">Categoria</h5>
      <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="row">
  
        <div class="input-group col-12" >
          <div class="">
            <label class="input-group-text" >Nome Categoria:</label>
          </div>
          <input type="text" [(ngModel)]="LabelCategoria" name="nome" class="form-control">
        </div>
        <div class="col-12">
          <label  class="control-label col-form-label">Categorie Padre:</label>
          <ng-multiselect-dropdown style="width: 80%; " [placeholder]="'Seleziona'" name="categorie" [data]="CatFull"
                                   [(ngModel)]="CategorieSelezionate" [settings]="categoriedropdownSettings"
                                   (onFilterChange)="filtroCategoria($event)">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-12" *ngIf="permission.HasCapacity(['cat_color'])">
          <div class="">
            <label class="input-group-text" >Colore:</label>
          </div>
          <input type="color" [(ngModel)]="CategoriaDaModificare.colore" name="nome" class="form-control">
        </div>

        <div class="input-group col-12" *ngIf="Env.ProdottiAddOn.cat_Sort">
          <div class="">
            <label class="input-group-text">Ordine:</label>
          </div>
          <input type="number" [(ngModel)]="Ordine" name="nome" class="form-control">
        </div>
        <div class="col-12" *ngIf="Env.ProdottiAddOn.cat_image">
          <h5 class="card-title m-t-20">{{'prodotti.UploadImage' | translate}}</h5>
          <div class="el-element-overlay">
            <div class="el-card-item">
              <div class="el-card-avatar el-overlay-1">
                <img [src]="CategoriaDaModificare?.immagine ? CategoriaDaModificare?.immagine : apiPaths.getBaseAPI()+'/file/image/'+CategoriaDaModificare?.id_immagine?.toString()" alt="user" />
                <div class="el-overlay">
                  <ul class="list-style-none el-info">
                    <li class="el-item">
                      <a class="btn default btn-outline image-popup-vertical-fit el-link" [href]="CategoriaDaModificare?.immagine ? CategoriaDaModificare?.immagine : apiPaths.getBaseAPI()+'/file/image/'+CategoriaDaModificare?.id_immagine?.toString()">
                        <i class="icon-magnifier"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="btn btn-info waves-effect waves-light">
            <span>Upload Anonther Image</span>
            <input type="file" class="upload" (change)="immaginecambiata($event)"> </div>
        </div>
      </div>
      <div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
        <button type="submit" (click)="saveModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
          <span class="ml-1 text-white font-weight-bold"> Salva </span>
          <i-feather name="save" class="feather-md "></i-feather>
        </button>
      </div>
    </div>
  </ng-template>
  
  <ng-template #DeleteCategoriaModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="editUserLabel">Elimina Categoria</h5>
      <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <label for="categorie" class="control-label col-form-label">Categorie a cui assegnare le categorie
            figlie:</label>
          <ng-multiselect-dropdown style="width: 80%; " [placeholder]="'Seleziona'" name="categorie"
            [data]="CategorieLineare" [(ngModel)]="CategorieSelezionate" [settings]="categoriedropdownSettings"
            (onFilterChange)="filtroCategoria($event)">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="d-md-flex align-items-center p-3" style="padding-right: 25px; margin-top: 25px;">
        <button type="submit" (click)="deleteModalButton()"
          class="ml-auto btn btn-rounded btn-danger p-2 waves-effect waves-light">
          <span class="ml-1 text-white font-weight-bold"> Elimina </span>
          <i-feather name="trash-2" class="feather-md "></i-feather>
        </button>
      </div>
    </div>
  </ng-template>