import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ClassicType} from "../../shared/models/ClassicType";
import {Rma} from "../_Models/rma";
import {ApiRestService} from "./api-rest.service";
import {RitornoPaginazione} from "../../shared/models/RitornoPaginazione";
import {RmaFilter} from "../_Models/rma-filter";
import {RmaData} from "../RmaData";
import {ApiPathsService} from "../../api-paths.service";

@Injectable({
  providedIn: 'root'
})
export class RmaStoreService {

  constructor(private apiRestService: ApiRestService, private rmaData: RmaData, private apiPaths: ApiPathsService){}
  private TYPES = new BehaviorSubject<ClassicType[]>([]);
  private PRODOTTI = new BehaviorSubject<RitornoPaginazione<Rma[]>>({total: 0, data:[]});
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private SELECTED_PRODOTTO = new BehaviorSubject<RitornoPaginazione<Rma>>({total: 0, data: new Rma()});

  private dataStore: {
    Types: ClassicType[],
    Prodotti: RitornoPaginazione<Rma[]>,
    Editable: boolean,
    ShowAddForm: boolean,
    SelectedProdotto: RitornoPaginazione<Rma>,
  } = {
    Types: [],
    Prodotti: {total: 0, data:[]},
    Editable: false,
    ShowAddForm: false,
    SelectedProdotto: {total: 0, data: new Rma()},
  }
  readonly $types = this.TYPES.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $prodotti = this.PRODOTTI.asObservable();
  readonly $selectedProdotto = this.SELECTED_PRODOTTO.asObservable();
  refreshTypes = () => this.TYPES.next(Object.assign({}, this.dataStore).Types);
  refreshprodotti = () => this.PRODOTTI.next(Object.assign({}, this.dataStore).Prodotti);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshSelectedProdotto = () => this.SELECTED_PRODOTTO.next(Object.assign({}, this.dataStore).SelectedProdotto);
  GetTypes(){
    this.apiPaths.ClassicGet('/rma/rma/types', { headers: this.rmaData.getRmaHeaders() },this.rmaData.getRmaLink()).subscribe(data => {
      this.dataStore.Types = data;
      this.refreshTypes();
      return this.$types;
    });
  }
  getList(value: RmaFilter, all?:boolean, Calendar?: boolean ) {
    console.log(value);
    this.apiRestService.GetRmaList(value,all,Calendar)
      .subscribe(data => {
        this.dataStore.Prodotti = data;
        this.refreshprodotti();
        return this.$prodotti;
      })
  }
  updateSelected(value: Rma) {
    if(value?.id)
      this.apiPaths.ClassicPost('/rma/rma/get',{id: value.id}, { headers: this.rmaData.getRmaHeaders() },this.rmaData.getRmaLink()).subscribe(data => {
        this.UpdateSelectedProdotto(data);
      });
    else
      this.UpdateSelectedProdotto(value);

  }
  UpdateSelectedProdotto(value){
    this.dataStore.SelectedProdotto = {data: value.data ?? value, stampe: value.stampe};
    this.refreshSelectedProdotto();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
    this.dataStore.ShowAddForm = value;
    this.refreshShowAddForm();
  }
}
