import { Documento } from './../Models/Documento';
import { RitornoPaginazione } from './../../shared/models/RitornoPaginazione';
import { Scadenza } from '../../Scadenzario/Models/Scadenza';
import { ClassicType } from './../../shared/models/ClassicType';
import { Prodotto } from './../Models/Prodotto';
import { Categorie } from './../Models/Categorie';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { map } from 'rxjs/operators';
import { Progetto } from '../Models/Progetto';
import {DettaglioProdotto} from "../Models/dettaglio-prodotto";
import {Destinazione} from "../Models/destinazione";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  header() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue?.access_token}`,
    });
  }
  headerforfile() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue?.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue?.access_token}`,
      }),
    };
  }
  //#region Progetti
  getStatiProgetto(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/progetti/progetti/stati`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getProgetti(value, restRoute? ): Observable<RitornoPaginazione<Progetto[]>> {
    return this.http.post<RitornoPaginazione<Progetto[]>>(
      `${this.apiPaths.getBaseAPI()}${restRoute ?? '/progetti/progetti'}/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {

        return data;
      }));
  }
  getAllProgetti(value, restRoute?): Observable<RitornoPaginazione<Progetto[]>> {
    return this.http.post<RitornoPaginazione<Progetto[]>>(
      `${this.apiPaths.getBaseAPI()}${restRoute ?? '/progetti/progetti'}/listAll`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Progetto[]> = new RitornoPaginazione<Progetto[]>();
        prodotti.total = data.total;
      prodotti.stampe = data.stampe;
        prodotti.data = data.data;
        return prodotti;
      }));
  }
  getInfoProgetto(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addProgetti(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateProgetti(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  softDeleteProgetti(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  hardDeleteProgetti(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/delete/force`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  restoreProgetti(value, restroute?): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}${restroute ?? '/progetti/progetti'}/restore`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Prodotti
  getCategorie(value?): Observable<Categorie[]> {
    return this.http.post<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/categorie/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const elencoTipi: Categorie[] = [];
        data.forEach((tipo) => {
          tipo.UrlImmagine = !isNaN(tipo.id_immagine)? `${this.apiPaths.getBaseAPI()}/file/image/${tipo.id_immagine.toString()}`: undefined;
          elencoTipi.push(tipo);
        })
        return elencoTipi;
      }));
  }
  getCategorieListanofigli(value): Observable<Categorie[]> {
    return this.http.post<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/categorie/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const elencoTipi: Categorie[] = [];
        data.forEach((tipo) => {
          tipo.UrlImmagine = !isNaN(tipo.id_immagine)? `${this.apiPaths.getBaseAPI()}/file/image/${tipo.id_immagine.toString()}`: undefined;
          elencoTipi.push(tipo);
        })
        return elencoTipi;
      }));
  }
  getUnitaDiMisura(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/um`,
      this.options()).pipe(map((data) => {
        const elencoTipi: ClassicType[] = data;
        return elencoTipi;
      }));
  }
  getProdottiTypes(): Observable<ClassicType[]>{
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/types`,
      this.options()).pipe(map((data) => {
        const elencoTipi: ClassicType[] = data;
        return elencoTipi;
      })
    );
  }
  getProdotti(value): Observable<RitornoPaginazione<Prodotto[]>> {
    return this.http.post<RitornoPaginazione<Prodotto[]>>(
      `${this.apiPaths.getBaseAPI()}/prodotti/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Prodotto[]> = new RitornoPaginazione<Prodotto[]>();
        prodotti.total = data.total;
        data.data.forEach(prodotto => {
          prodotto.qty = 1;
          prodotto.UrlImmagine = !isNaN(prodotto.id_immagine)? `${this.apiPaths.getBaseAPI()}/file/image/${prodotto.id_immagine.toString()}`: undefined;
          prodotto.Urlscheda = !isNaN(prodotto.id_scheda)? `${this.apiPaths.getBaseAPI()}/file/image/${prodotto.id_scheda.toString()}`: undefined;
          prodotto.Urldettagli = !isNaN(prodotto.id_dettaglio)? `${this.apiPaths.getBaseAPI()}/file/image/${prodotto.id_dettaglio.toString()}`: undefined;
          prodotto.Urlscheda_e = !isNaN(prodotto.id_scheda_e)? `${this.apiPaths.getBaseAPI()}/file/image/${prodotto.id_scheda_e.toString()}`: undefined;
          prodotto['Urllogo'] = !isNaN(prodotto['id_logo'])? `${this.apiPaths.getBaseAPI()}/file/image/${prodotto['id_logo'].toString()}`: undefined;
        })
        prodotti.data = data.data;

        return prodotti;
      }));
  }
  getAllProdotti(value): Observable<RitornoPaginazione<Prodotto[]>> {
    return this.http.post<RitornoPaginazione<Prodotto[]>>(
      `${this.apiPaths.getBaseAPI()}/prodotti/listAll`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Prodotto[]> = new RitornoPaginazione<Prodotto[]>();
        prodotti.total = data.total;
        prodotti.data = data.data;
        prodotti.stampe = data.stampe;
        return prodotti;
      }));
  }

  getInfoProdotto(value): Observable<Prodotto> {
    return this.http.post<Prodotto>(`${this.apiPaths.getBaseAPI()}/prodotti/get`,
      JSON.stringify(value),
      this.options()).pipe(map(data=>{
      data.UrlImmagine = !isNaN(data.id_immagine)? `${this.apiPaths.getBaseAPI()}/file/image/${data.id_immagine.toString()}`: undefined;
      data.Urlscheda = !isNaN(data.id_scheda)? `${this.apiPaths.getBaseAPI()}/file/image/${data.id_scheda.toString()}`: undefined;
      data.Urldettagli = !isNaN(data.id_dettaglio)? `${this.apiPaths.getBaseAPI()}/file/image/${data.id_dettaglio.toString()}`: undefined;
      data.Urlscheda_e = !isNaN(data.id_scheda_e)? `${this.apiPaths.getBaseAPI()}/file/image/${data.id_scheda_e.toString()}`: undefined;
      data['Urllogo'] = !isNaN(data['id_logo'])? `${this.apiPaths.getBaseAPI()}/file/image/${data['id_logo'].toString()}`: undefined;
      return data;
    }));
  }


  updateProdotti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  addProdotti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteProdotti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  restoreProdotti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/restore`,
      JSON.stringify(value),
      this.options()).pipe();
  }


  updateCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/categorie/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/categorie/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteCategoria(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/categorie/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region  scadenze
  getTipiScadenze(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/types`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getListaScadenze(value): Observable<RitornoPaginazione<Scadenza[]>> {
    return this.http.post<RitornoPaginazione<Scadenza[]>>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  addScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Documenti
  getTipiDocumenti(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/types`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getListaDocumenti(value): Observable<RitornoPaginazione<Documento[]>> {
    return this.http.post<RitornoPaginazione<Documento[]>>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getDocumento(value): Observable<Documento> {
    return this.http.post<Documento>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  addDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/documenti/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region Categorie_Documenti
  getListCategorieDocumento(): Observable<Categorie[]> {
    return this.http.get<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/documenti/categorie/list`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  searchCategorieDocumento(value): Observable<Categorie[]> {
    return this.http.post<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/documenti/categorie/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  addCategorieDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/categorie/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateCategorieDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/categorie/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteCategorieDocumento(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/documenti/categorie/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion

  //#region Parti
  getCategorieVariazione(): Observable<Categorie[]> {
    return this.http.get<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/variazioni/list`,
      this.options()).pipe(map((data) => {
        const elencoTipi: Categorie[] = [];
        data.forEach((tipo) => {
          elencoTipi.push(tipo);
        })
        return elencoTipi;
      }));
  }
  getCategorieVariazioneListanofigli(value): Observable<Categorie[]> {
    return this.http.post<Categorie[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/variazioni/list/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const elencoTipi: Categorie[] = [];
        data.forEach((tipo) => {
          elencoTipi.push(tipo);
        })
        return elencoTipi;
      }));
  }
  getParti(value): Observable<RitornoPaginazione<Prodotto[]>> {
    return this.http.post<RitornoPaginazione<Prodotto[]>>(
      `${this.apiPaths.getBaseAPI()}/prodotti/parti/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Prodotto[]> = new RitornoPaginazione<Prodotto[]>();
        prodotti.total = data.total;
        data.data.forEach(prodotto => prodotto.qty = 1)
        prodotti.data = data.data;

        return prodotti;
      }));
  }
  getPartiTypes(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/prodotti/types`,
      this.options()).pipe(map((data) => {
        const elencoTipi: ClassicType[] = data;
        return elencoTipi;
      })
      );
  }
  getAllParti(value): Observable<RitornoPaginazione<Prodotto[]>> {
    return this.http.post<RitornoPaginazione<Prodotto[]>>(
      `${this.apiPaths.getBaseAPI()}/prodotti/parti/listAll`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Prodotto[]> = new RitornoPaginazione<Prodotto[]>();
        prodotti.total = data.total;
        prodotti.data = data.data;
        return prodotti;
      }));
  }

  getInfoParte(value): Observable<Prodotto> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/parti/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }


  updateParti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/parti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  addParti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/parti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteParti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/parti/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  restoreParti(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/parti/restore`,
      JSON.stringify(value),
      this.options()).pipe();
  }


  updateCategoriaVariazione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/variazioni/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addCategoriaVariazione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/variazioni/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteCategoriaVariazione(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/variazioni/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion

  //#region Dettaglio
  UpdateDettaglio(item: DettaglioProdotto) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/dettaglio/update`,
      JSON.stringify(item),
      this.options()).pipe();
  }
  AddDettaglio(item: DettaglioProdotto) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/dettaglio/add`,
      JSON.stringify(item),
      this.options()).pipe();
  }

  DeleteDettaglio(value: DettaglioProdotto) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/dettaglio/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  OrderProdotti(Prodotti: Prodotto[]) {
    var value: number[] = [];
    Prodotti.forEach(data=>{
      value.push(data.id);
    })
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/prodotti/order`,
      JSON.stringify({ids: value}),
      this.options()).pipe();
  }

  GetTipiProdotto() {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/prodotti/tipi`,
      this.options()).pipe();
  }

  GetClassiEnergetiche() {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/prodotti/classe/energetica`,
      this.options()).pipe();
  }
  //#region Destinazioni
  GetDestinazione(value: Destinazione) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  GetDestinazioniList(value: any) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/list`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  AddDestinazione(value: Destinazione) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  UpdateDestinazione(value: Destinazione) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeleteDestinazione(value: Destinazione) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  GetVociProgetto(value) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/progetti/voci`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DestinazioniSearch(value) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/search`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DestinazioniOther(value) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/other`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DestinazioniSposta(value) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/destinazioni/sposta`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  //#endregion

  PrendiinCarico(param: any) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/progetti/incarico`,
      JSON.stringify(param),
      this.options()).pipe();
  }
}
