import { Attivita } from '../models/Attivita';
import { ClassicType } from '../../shared/models/ClassicType';
import { Azienda } from '../models/Azienda';
import { Ruolo } from '../models/Ruolo';
import { map } from 'rxjs/operators';
import { Persona } from '../models/persona';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPathsService } from '../../api-paths.service';
import { AuthenticationService } from '../../Login/_services/authentication.service';
import { Observable } from 'rxjs';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { Sede } from '../models/Sede';
import {CampoAggiuntivo} from "../../shared/models/campo-aggiuntivo";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    if(this.authenticationService && this.authenticationService.currentUserValue &&this.authenticationService.currentUserValue.access_token)
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
        }),
      };
    else
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
  }
  getProfiloCliente(): Observable<Azienda> {
    return this.http.get <any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/profilo/cliente`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  //#region personale
  getTuttiRuoliAsString(): Observable<string[]> {
    return this.http.get <any[]>(
      `${this.apiPaths.getBaseAPI()}/auth/roles`,
      this.options()).pipe(map((data) => {
        const elencoRuoli: string[] = [];
        data.forEach((ruolo) => {
          elencoRuoli.push(ruolo.role);
        })
        return elencoRuoli;
      }));
  }
  getTuttiRuoliAsObject(): Observable<Ruolo[]> {
    return this.http.get<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/persone/roles`,
      //`${this.apiPaths.getBaseAPI()}/auth/roles`,
      this.options()).pipe(map((data) => {
        const elencoRuoli: Ruolo[] = [];
        data.forEach((ruolo) => {
          elencoRuoli.push({id:ruolo.id, label:ruolo.label});
        })
        return elencoRuoli;
      }));
  }
  getListaPersonaleWOAdmin(value, all?): Observable<RitornoPaginazione<Persona[]>> {
    return this.http.post<RitornoPaginazione<Persona[]>>(all?`${this.apiPaths.getBaseAPI()}/anagrafica/persone/all/persone` : `${this.apiPaths.getBaseAPI()}/anagrafica/persone/list/persone`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getListaTuttoPersonale(value): Observable<RitornoPaginazione<Persona[]>> {
    return this.http.post<RitornoPaginazione<Persona[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/all`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  searchPersonale(value): Observable<RitornoPaginazione<Persona[]>> {
    return this.http.post<RitornoPaginazione<Persona[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/list`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getListaPersonale(value): Observable<RitornoPaginazione<Persona[]>> {
    return this.http.post<RitornoPaginazione<Persona[]>>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/list`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getListaTecnici(value): Observable<RitornoPaginazione<Persona[]>> {
    return this.http.post<Persona[]>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/tecnici`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        const elencoPersonale: RitornoPaginazione<Persona[]> = new RitornoPaginazione<Persona[]>();
        elencoPersonale.total = data.length;
        elencoPersonale.data = data;
        return elencoPersonale;
      }));
  }
  getInfoPersonale(personale): Observable<Persona> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/get`,
      JSON.stringify(personale),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  getInfoProfilo(personale): Observable<Persona> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/persona`,
      JSON.stringify(personale),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  addNewUser(dipendente: Persona): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/add`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  updateUser(dipendente: Persona): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/update`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  updateProfilo(dipendente: Persona): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/persona/update`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  deleteUser(dipendente: Persona): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/delete`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  restoreUser(dipendente: Persona): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/persone/restore`,
      JSON.stringify(dipendente),
      this.options()).pipe();
  }
  //#endregion
  //#region aziende
  SendToAdhoc(cliente){
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/add/adhoc`,
      JSON.stringify(cliente),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getListaAziende(paginazione): Observable<RitornoPaginazione<Azienda[]>> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/list`,
      JSON.stringify(paginazione),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  enableAziende(paginazione): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/enable`,
      JSON.stringify(paginazione),
      this.options())
      .pipe(map((data) => {
        return data
      }));
  }
  disableAziende(paginazione): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/disable`,
      JSON.stringify(paginazione),
      this.options())
      .pipe(map((data) => {
        return data
      }));
  }
  getInfoAziende(azienda): Observable<Azienda> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/get`,
      JSON.stringify(azienda),
      this.options()
    ).pipe(map((data) => {
        return data;
    }));
  }
  getInfoAttivita(attivita): Observable<Attivita> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/get`,
      JSON.stringify(attivita),
      this.options()
    ).pipe(map((data) => {
      return data;
    }));
  }
  getProfiloAziende(): Observable<Azienda> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/cliente`,
      JSON.stringify([]),
      this.options()
    ).pipe(map((data) => {
      return data;
    }));
  }
  getRuoloClienti(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/roles`,
      this.options()
    ).pipe(map((data) => {
      console.log(data);
      return data;
    }));
  }
  getTipologieClienti(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco:ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push(item);
        });
        return Elenco;
      }));
  }
  getTipologieAttivita(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco: ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push({id: item.id.toString(), label: item.label});
        });
        return Elenco;
      }));
  }
  getTipologieClientiAsString(): Observable<String[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco:string[] = [];
        data.data.forEach((item) => {
          Elenco.push(item.label);
        });
        return Elenco;
      }));
  }

  updateCompany(value: Azienda): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateCompanyProfile(value: Azienda): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/profilo/cliente/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addNewCompany(value: Azienda |any ): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteCompany(value: Azienda): Observable<any> {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addNewAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteAttivita(value: Attivita): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/attivita/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
  //#region sedi
  getSediCliente(value) {
    return this.http.post<Sede[]>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sedi`,
      JSON.stringify(value),
      this.options())
      .pipe(map((data) => {
        return data;
      }));
  }
  getTipiSedi(): Observable<ClassicType[]> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/types`,
      this.options())
      .pipe(map((data) => {
        const Elenco: ClassicType[] = [];
        data.forEach((item) => {
          Elenco.push({ id: item.id, label: item.label });
        });
        return Elenco;
      }));
  }
  addNewSedi(value: Sede) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateSedi(value: Sede): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteSede(value: Sede): Observable<any> {
    return this.http.post<ClassicType>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/sede/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion

  //region Referenti
  getreferenti(value: Persona) {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/referenti/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addreferente(value: Persona) {
    return this.http.post<Persona>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/referenti/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updatereferente(value: Persona): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/referenti/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deletereferente(value: Persona): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/referenti/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  restorereferente(value: Persona): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/referenti/restore`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //endregion
  getRuoliList(ruolo?: string): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/list`,
      JSON.stringify({ruolo: ruolo}),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getRuolo(ruolo: number): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/get`,
      JSON.stringify({id: ruolo}),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getCapacita(): Observable<any>{
    return this.http.get<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/capacita`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  AddRuolo(ruolo: Ruolo): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/add`,
      JSON.stringify(ruolo),
      this.options()).pipe();
  }
  UpdateRuolo(ruolo: Ruolo): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/update`,
      JSON.stringify(ruolo),
      this.options()).pipe();
  }
  DeleteRuolobyid(id: number): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/delete`,
      JSON.stringify({id: id}),
      this.options()).pipe();
  }
  DeleteRuolo(ruolo: Ruolo): Observable<any>{
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/anagrafica/ruoli/delete`,
      JSON.stringify(ruolo),
      this.options()).pipe();
  }



  getCoordinate(indirizzo: string): Observable<any>{
    var addr = indirizzo.split(' ').join('+');
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = `${this.apiPaths.getBaseAPI()}/maps/api/geocode/json?address=${addr}&key=AIzaSyAtHf7gRjLvTbRzwUy4GQ-87hNa--almW0`; // site that doesn’t send Access-Control-*
    return this.http.get(url).pipe(map((data) => {
      console.log(data);
      return data;
    }));
  }


    GetCampiAggiuntiviRmas(attivita:  any[], type: string, filter?):Observable<{check?:string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string}[]>{
      console.log("attivita", attivita);
      return this.http.get<{check:string, id: number, risposta: boolean, label?: string, codice: string, fields: {label:string, name: string, type: 'number' | 'dropdown' | 'string', values: any[], tariffe?: {max?:number, min: number, prezzo: number}[]}[]}[]>(
        `${this.apiPaths.getBaseAPI()}/rma/rma/${type}`,
        this.options()).pipe(map((data) => {
        const ret: {check?:string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[], codice: string}[] = [];
        data.forEach(check => {
          if(typeof attivita?.find(x=>true) == 'number' )
            ret.push({
              check: check.check ?? check.label,
              id: check.id,
              risposta: attivita?.find(x => x == check.id) != undefined,
              fields: [],
              codice: check.codice
            })
          else {
            var att = attivita?.find(x=> x.id == check.id);
            console.log("att",att)
            if(att){
              att.check = check.check ?? check.label
              att.id= check.id
              att.codice= check.codice
              ret.push(att);
            }else{
              ret.push({
                check: check.check ?? check.label,
                id: check.id,
                risposta: attivita?.find(x => x == check.id) != undefined,
                fields: [],
                codice: check.codice
              })
            }
          }
        })
        console.log("ret", ret);
        return ret;
      }));
    }

}
