<div class="page-breadcrumb" style="background-color:#1D1D1B !important ;">
    <div class="d-md-flex align-items-center">
        <div class="mr-auto">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                        <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                            <a href='javascript:void(0)' style="text-transform: uppercase;">{{url.title | translate}}</a>
                        </li>
                        <li class="breadcrumb-item active" style="text-transform: uppercase;" *ngIf="last && url.title">{{url.title| translate}}</li>
                    </ng-template>
                </ol>
            </nav>
        </div>
    </div>
</div>