import { Component, ViewChild, ElementRef,OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './../Login/_services/authentication.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent {
  @ViewChild('navbarTogglerDemo03') navbarToggler: ElementRef;
  selectedLanguage : string = "it";
  dropdownOpen = false;
  constructor(private translate: TranslateService, private router: Router, private authService:AuthenticationService){}

  toggleMenu() {
    const element = this.navbarToggler.nativeElement;
    if (element.classList.contains('show')) {
      element.classList.remove('show');
    } else {
      element.classList.add('show');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      this.dropdownOpen = false;
    } else {
      this.dropdownOpen = true;
    }
  }
  

  toggleDropdown() {
    if(this.isLogged())
      this.dropdownOpen = !this.dropdownOpen;
    else
      this.navigate("/Login")
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.selectedLanguage = lang;
  }

  navigateAndClose(path: string) {
    console.log(path);
    if(path === "ecommerce/assistenza" || path === "ecommerce/profilo")
      if(this.authService.currentUserValue)
        this.router.navigate([path])
      else
        this.router.navigate(['Login'])
    else
      this.router.navigate([path]);
    this.dropdownOpen = false;
  }

  navigate(path:String){
    if(path === "ecommerce/assistenza" || path === "ecommerce/profilo")
      if(this.authService.currentUserValue)
        this.router.navigate([path])
      else
        this.router.navigate(['Login'])
    else
      this.router.navigate([path]);    
    this.toggleMenu();
  }

  logout(){
    this.authService.logout();
  }

  isLogged(){
    if(this.authService.currentUserValue)
      return true;
    else
      return false;
  }
}
