import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Marker} from "../models/Marker";
import {Intervento} from "../../Interventi/Models/Intervento";
import {Azienda} from "../../Admin/models/Azienda";
import {DatePipe} from "@angular/common";
import {Circle_Marker} from "../models/Circle_Marker";
import {ToastrService} from "ngx-toastr";
import {Subscription} from "rxjs";
import {MarkerIconPath} from "../models/marker-icon-path";
import {ApiRestService} from "../../Admin/_service/api-rest.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {LatLngLiteral} from "../../../LibrerieCustom/agm-core-1.1.0";

@Component({
  selector: 'app-seleziona-clienti',
  templateUrl: './seleziona-clienti.component.html'
})
export class SelezionaClientiComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  collectionSize: number = 0;
  Markers: Marker[] = [];
  visualizzaTutti: boolean = true;
  @Input() ruolo: string = '';
  @Input() centerMaplat: number = 40.7876777;
  @Input() centerMaplon: number = 17.1468458;
  @Input() circleMarker: Circle_Marker = { latitude: this.centerMaplat, longitude: this.centerMaplon, radius: 15000, editable: true, circleDraggable: true, fillColor: "blue", fillOpacity:0.5};
  submitted: boolean = false;
  page = 1;
  pageSize = 50;
  pagesizeserver = 4
  newpagegenerated = 1;

  @Input() IsModal: boolean;
  @Output() Selected = new EventEmitter<Azienda[]>();
  @Output() onCircleMarkerChange = new EventEmitter<Circle_Marker>();
  @Input() ListaClienti: Azienda[];
  constructor(
    private datepipe: DatePipe,
    private toastService: ToastrService,
    private markerIconPath: MarkerIconPath,
    private apiRestService: ApiRestService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.refresh()
  }
  refresh(){
    this.Markers = [];
      this.ListaClienti?.forEach(Azienda =>{
        this.Markers.push(this.getMarkerOfAzienda(Azienda,true))
      })
  }

  getMarkerOfAzienda(Item: Azienda, inLista?:boolean): Marker {
    const marker: Marker = new Marker();
    marker.lat = Item.lat;
    marker.lng = Item.lon;
    marker.draggable = false;
    marker.selected = false;
    marker.inLista = inLista;
    marker.intervento = Item.id;
    marker.interventoObject = Item;
    marker.iconUrl = this.getMarkerIcon(marker);
    marker.visible = this.getMarkerVisibility(marker);
    return marker;
  }

  getMarkerIcon(Marker: Marker): string {
    if (Marker) {
      if (Marker.selected)
        return this.markerIconPath.getSelezionato();
      else if (Marker.inLista)
        return this.markerIconPath.getInLista();
      else
        return this.markerIconPath.getNonInLista();
    } else
      return this.markerIconPath.getInLista();
  }
  getMarkerVisibility(Marker: Marker): boolean {
    // const progdata : boolean = this.piaificazione && Marker.interventoObject && Marker.pianificato && Marker.inLista && this.datepipe.transform(Marker.interventoObject.data_fine_prevista, 'yyyy-MM-dd') == this.datepipe.transform(this.piaificazione, 'yyyy-MM-dd');
    // if (Marker && Marker != null) {
    //   if (Marker.selected )
    //     return this.selezionati;
    //   else if ( progdata)
    //     return this.programmatiData;
    //   else if ( Marker.pianificato && Marker.inLista && !progdata)
    //     return this.programmati;
    //   else if (Marker.pianificato && !Marker.inLista)
    //     return this.programmatiAltrui;
    //   else if (Marker.inLista)
    //     return this.inlista;
    //   else if(!Marker.inLista)
    //     return this.altrui;
    //   else
    //     return true;
    // } else
    return true;
  }


  centra(item: Intervento) {
    this.circleMarker.latitude = item.sedeObject.lat;
    this.circleMarker.longitude = item.sedeObject.lon;
    this.centerMaplat = item.sedeObject.lat;
    this.centerMaplon = item.sedeObject.lon;
  }
  save() {
    var selected = this.Markers?.find(x => x.selected);
    if(selected?.interventoObject) {
      this.Selected.emit([selected.interventoObject]);
      this.closeBtnClick();
      return;
    }
    this.toastService.warning('Selezionare un elemento');
  }

  onMarkerClick(value: Marker) {
    if (value.selected)
      this.deselezionaIntervento(this.ListaClienti?.find(x => x?.id === value?.intervento), value);
    else
      this.selezionaIntervento(this.ListaClienti?.find(x => x?.id === value?.intervento), value);
  }

  selezionaIntervento(item: Azienda, marker?: Marker) {
    if (item) {
      if (!marker) {
        const markerselezionato = this.Markers.find(x => x.intervento == item.id);
        this.selezionaMarker(markerselezionato);
      } else
        this.selezionaMarker(marker);
    }
  }
  deselezionaIntervento(item: Azienda, marker?: Marker) {
    if (item && !marker) {
      const markerselezionato = this.Markers.find(x => x.intervento == item.id);
      this.deselezionaMarker(markerselezionato);
    } else if (item)
      this.deselezionaMarker(marker);
  }
  selezionaMarker(Marker: Marker) {
    if (Marker && Marker != null) {
      Marker.selected = true;
      Marker.iconUrl = this.getMarkerIcon(Marker);
      Marker.visible = this.getMarkerVisibility(Marker);
    }
  }
  deselezionaMarker(Marker: Marker) {
    if (Marker && Marker != null) {
      Marker.selected = false;
      Marker.iconUrl = this.getMarkerIcon(Marker);
      Marker.visible = this.getMarkerVisibility(Marker);
    }
  }
  closeBtnClick() {
    this.activeModal.dismiss();
  }

  CircleDrag(latlon: LatLngLiteral) {
    this.onCircleMarkerChange.emit(
      { latitude: latlon.lat, longitude: latlon.lng, radius: this.circleMarker.radius, editable: true, circleDraggable: true, fillColor: "blue", fillOpacity:0.5}
    )

    this.refresh()
  }
}
