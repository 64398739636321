import { Stampa } from './../../task/models/date';
import { DatePipe } from '@angular/common';
import { Azienda } from './../../Admin/models/Azienda';
import { Progetto } from './../../Progetti/Models/Progetto';
import { FiltriInterventi } from './../Models/FiltriInterventi';
import { Intervento } from './../Models/Intervento';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClassicType } from 'src/app/shared/models/ClassicType';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { ApiRestService } from './api-rest.service';

@Injectable({
  providedIn: 'root'
})
export class InterventiStoreService {
  constructor(private apiRestService: ApiRestService, private datepipe: DatePipe) { }
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private IS_MODAL = new BehaviorSubject<boolean>(true);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private STATI_INTERVENTI = new BehaviorSubject<ClassicType[]>([]);
  private TIPI_INTERVENTI = new BehaviorSubject<ClassicType[]>([]);
  private DATA_PIANIFICAZIONE = new BehaviorSubject<string>(this.datepipe.transform(new Date(), 'yyyy-MM-dd'));
  private INTERVENTI = new BehaviorSubject<RitornoPaginazione<Intervento[]>>(null);
  private INTERVENTI_ALTRUI = new BehaviorSubject<RitornoPaginazione<Intervento[]>>(null);
  private INTERVENTO_SELEZIONATO = new BehaviorSubject<Intervento>(null);
  private OFFERTA_SELEZIONATA = new BehaviorSubject<Progetto>(null);
  private STAMPE = new BehaviorSubject<Stampa[]>([]);
  //private SHOW_ALL_INTERVENTI = new BehaviorSubject<boolean>(false);
  private dataStore: {
    IsModal: boolean,
    Editable: boolean,
    ShowAddForm: boolean,
    StatiIntervento: ClassicType[],
    TipiIntervento: ClassicType[],
    Interventi: RitornoPaginazione<Intervento[]>,
    InterventiAltrui: RitornoPaginazione<Intervento[]>,
    InterventoSelezionato: Intervento,
    OffertaSelezionata: Progetto,
    DataPianificazione: string,
    Stampe: Stampa[],
    //ShowAllIntervento: boolean,
  } = {
    IsModal:true,
    Editable: false,
    ShowAddForm: false,
    StatiIntervento: [],
    TipiIntervento: [],
      Interventi: null,
      InterventiAltrui: null,
      InterventoSelezionato: null,
      OffertaSelezionata: null,
      DataPianificazione:this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      Stampe: []
    //ShowAllIntervento: false,
    }
  readonly $isModal = this.IS_MODAL.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $statiIntervento = this.STATI_INTERVENTI.asObservable();
  readonly $tipiIntervento = this.TIPI_INTERVENTI.asObservable();
  readonly $interventi = this.INTERVENTI.asObservable();
  readonly $interventiAltrui = this.INTERVENTI_ALTRUI.asObservable();
  readonly $interventoSelezionato = this.INTERVENTO_SELEZIONATO.asObservable();
  readonly $offertaSelezionata = this.OFFERTA_SELEZIONATA.asObservable();
  readonly $dataPianificazione = this.DATA_PIANIFICAZIONE.asObservable();
  readonly $stampe = this.STAMPE.asObservable();
  //readonly $showAllInterventi = this.SHOW_ALL_INTERVENTI.asObservable();
  refreshIsModal = () => this.IS_MODAL.next(Object.assign({}, this.dataStore).IsModal);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshStatiIntervento = () => this.STATI_INTERVENTI.next(Object.assign({}, this.dataStore).StatiIntervento);
  refreshTipiIntervento = () => this.TIPI_INTERVENTI.next(Object.assign({}, this.dataStore).TipiIntervento);
  refreshInterventi = () => this.INTERVENTI.next(Object.assign({}, this.dataStore).Interventi);
  refreshInterventiAltrui = () => this.INTERVENTI_ALTRUI.next(Object.assign({}, this.dataStore).InterventiAltrui);
  refreshInterventoSelezionato = () => this.INTERVENTO_SELEZIONATO.next(Object.assign({}, this.dataStore).InterventoSelezionato);
  refreshOffertaSelezionata = () => this.OFFERTA_SELEZIONATA.next(Object.assign({}, this.dataStore).OffertaSelezionata);
  refreshDataPianificazione = () => this.DATA_PIANIFICAZIONE.next(Object.assign({}, this.dataStore).DataPianificazione);
  refreshStampe = () => this.STAMPE.next(Object.assign({}, this.dataStore).Stampe);
  //refreshShowAllInterventi = () => this.SHOW_ALL_INTERVENTI.next(Object.assign({}, this.dataStore).ShowAllIntervento);

  getStatiIntervento() {
    this.apiRestService.getStatiIntervento().subscribe(data => {
      this.dataStore.StatiIntervento = data;
      this.refreshStatiIntervento();
    });
  }
  getTipiIntervento() {
    this.apiRestService.getTipiIntervento().subscribe(data => {
      this.dataStore.TipiIntervento = data;
      this.refreshTipiIntervento();
    });
  }
  getInterventi(value: FiltriInterventi | Progetto | Azienda , tipo:'Filtro'|'Progetto'|'Cliente') {
    if (value instanceof FiltriInterventi)
      this.apiRestService.getInterventi(value)
        .subscribe(data => {
          this.dataStore.Interventi = data;
          this.refreshInterventi();
          return this.$interventi;
        })
    else if (tipo == 'Progetto')
      this.getInterventiProgetto(new FiltriInterventi(undefined,undefined,value.id));
    else if (tipo == 'Cliente')
      this.getInterventiClienti(new FiltriInterventi(undefined,undefined,undefined,value.id));

  }
  getInterventiAltrui(value: FiltriInterventi) {
    this.apiRestService.getInterventiAltrui(value)
      .subscribe(data => {
        this.dataStore.InterventiAltrui = data;
        this.refreshInterventiAltrui();
        return this.$interventiAltrui;
      })
  }
  getInterventiProgetto(value) {
    this.apiRestService.getInterventiProgetto(value)
      .subscribe(data => {
        this.dataStore.Interventi = data;
        this.refreshInterventi();
        return this.$interventi;
      })
  }
  getInterventiClienti(value) {
    this.apiRestService.getInterventiClienti(value)
      .subscribe(data => {
        this.dataStore.Interventi = data;
        this.refreshInterventi();
        return this.$interventi;
      })
  }
  updateShowAddForm(value: boolean) {
    this.dataStore.ShowAddForm = value;
    this.refreshShowAddForm();
  }
  updateDataPianificazione(value: string) {
    this.dataStore.DataPianificazione = this.datepipe.transform(value, 'yyyy-MM-dd');
    this.refreshDataPianificazione();
  }
  updateIsModal(value: boolean) {
    this.dataStore.IsModal = value;
    this.refreshIsModal();
  }
  updateOffertaSelezionata(value: Progetto) {
    this.dataStore.OffertaSelezionata = value;
    this.refreshOffertaSelezionata();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateInterventoSelezionato(value: Intervento) {
    if(value?.id)
      this.apiRestService.getInfoIntervento(value).subscribe(data => {
        console.log(data);
        this.dataStore.InterventoSelezionato = data.data;
        this.refreshInterventoSelezionato();
        this.dataStore.Stampe = data.stampe;
        this.refreshStampe();
      });
    else {
      this.dataStore.InterventoSelezionato = value;
      this.refreshInterventoSelezionato();
    }
  }
  svuotaListaInterventi() {
    this.dataStore.Interventi = null;
    this.refreshInterventi();
  }
}
