import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-legenda',
  templateUrl: './legenda.component.html',
})
export class LegendaComponent implements OnInit {
  @Input() legenda;
  
  ngOnInit() { }
}
