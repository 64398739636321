<div class="row col-lg-12">
    <div class="col-lg-4">
        <label>{{nameCategoria1}}</label>
        <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="categorie" [(ngModel)]="selectedCategoria" (ngModelChange)="categoriaChange()"></ng-multiselect-dropdown>
    </div>
    <div *ngIf="selectedCategoria && selectedCategoria?.figli.length > 0" class="col-lg-4">
        <label>{{nameCategoria2}}</label>
        <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="sottoCategoria" [(ngModel)]="selectedSottoCategoria"(ngModelChange)="sottoCategoriaChange()" ></ng-multiselect-dropdown>
    </div>
    <div *ngIf="selectedSottoCategoria && selectedSottoCategoria?.figli.length > 0" class="col-lg-4">
        <label>{{nameCategoria3}}</label>
        <ng-multiselect-dropdown  [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="prestazione" [(ngModel)]="selectedPrestazione" (ngModelChange)="prestazioneChange()"></ng-multiselect-dropdown>
    </div>
</div>






