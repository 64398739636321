
/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2023.
 * Title: vertical-sidebar.component.ts;
 * Path: /Volumes/Dati 1/SbSW_SoftwareBySystem_Works/KI_KlimaItalia/GesKI_sbs_gestionale_Klimautalia_front_angular/src/app/layouts/vertical-sidebar/vertical-sidebar.component.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 05/09/23, 17:47;
 * Updated at: 05/09/23, 16:43;
 *
 *
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouteInfo } from '../_Models/sidebar.metadata';
import { VerticalSidebarService } from './vertical-sidebar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Permission} from "../../Login/_guards/Permission";
import {environment} from "../../../environments/environment";
declare var $: any;

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html'
})
export class VerticalSidebarComponent {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: RouteInfo[] = [];
  path = '';
  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  handleNotify() {
    this.notify.emit(!this.showClass);
  }

  getQuerystring(path: string){
    if(!path?.includes('?')) {
      return null;
    }
     var queryString: string = path;
     var queryParams: any = {};
    // Estrai la parte della querystring dalla stringa completa
    const queryStringIndex =queryString.indexOf('?');
    const queryStringPart = queryString.slice(queryStringIndex + 1);

    // Dividi la querystring in coppie chiave-valore
    const keyValuePairs = queryStringPart.split('&');

    // Loop attraverso le coppie chiave-valore e crea l'oggetto JSON
    keyValuePairs.forEach(keyValuePair => {
      const [key, value] = keyValuePair.split('=');
      if (key && value) {
        queryParams[key] = decodeURIComponent(value);
      }
    });
    return queryParams;
  }

  getPath(queryString: string){
    if(queryString.includes('?')) {
      const queryStringIndex = queryString.indexOf('?');
      return queryString.slice(0, queryStringIndex !== -1 ? queryStringIndex : undefined);
    }
    return queryString;

  }

  constructor(private menuServise: VerticalSidebarService, private router: Router, private permission: Permission, private route:ActivatedRoute) {
    this.menuServise?.items?.subscribe(menuItems => {
      this.sidebarnavItems = this.getVisibleItem(menuItems);
      this.route.params.subscribe(data => {
        this.path = this.showMenu = this.sidebarnavItems?.find(menu => this.getVisibleItem(menu?.submenu)?.find(s => s?.path?.replace(/\//gi,'') == this.router?.url?.replace(/\//gi,''))!= undefined)?.title
      });
      
      
      
      //this.sidebarnavItems?.filter(m => m?.submenu?.filter(s => s?.path === this.router?.url ? this.path = m?.title : this.path));
      //this.addExpandClass(this.path);
    });
  }
  hasSubmenu(element: RouteInfo): boolean{
    return element.submenu?.length > 0 && this.getVisibleItem(element.submenu)?.length > 0
  }
  isActive(item){
    return item?.path?.replace(/\//gi,'') == this.router?.url?.replace(/\//gi,'');
  }
  getVisibleItem(elements: RouteInfo[]) : RouteInfo[] {
    return elements?.filter(x=>
      (x?.enabled == undefined || x?.enabled == true) &&
      this.permission.HasCapacity(x?.capacita) &&
      this.permission.isOperativeRoles(x?.ruoliOperativi) &&
      this.permission.isntOperativeRoles(x?.notruoliOperativi) &&
      (x.module == undefined || environment.Modules[x.module])
    );
  }

  addExpandClass(element: any) {
    this.showMenu = element === this.showMenu ? '0' : element;
  }

  addActiveClass(element: any) {
    this.showSubMenu = element === this.showSubMenu? '0' : element;
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}
