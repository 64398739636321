import { Component } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('MzQwODExNUAzMjMwMmUzMTJlMzBJdCtaeWE0UmczTmtTRHZROXdKNTc1RDhwZVpJRCtrczlac01CTnRJVkVzPQ==;MzQwODExNkAzMjMwMmUzMTJlMzBXVGZIdTNDenZnVWNFbmlZVnJYWVBsK09KMi9tcSs1WUY1V3d1SzZHdUM4PQ==;Mgo+DSMBaFt/QHFqVVhkW1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQFljTn1TdkBmXn9ed3xSQQ==;Mgo+DSMBPh8sVXJ0S0d+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTcUZjWXpbd3RVQWhbVg==;ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkJgWX9bdXNVRmhcVUQ=;NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RckVkWn9edXFSRmBUUE12;MzQwODEyMUAzMjMwMmUzMTJlMzBFVFozZ004ZXlrMVhZUFJOamlkNFgvMzV2Y2pHcUNFRUl6Rkd2bDdkQ2JnPQ==;MzQwODEyMkAzMjMwMmUzMTJlMzBUVVFvK3lKemlYUVgwaHdYWEtxQmo1Y2lPTnNvRUc1bWZWMThJNFFLRDNNPQ==;Mgo+DSMBMAY9C3t2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkJgWX9bdXNVRmhaVE0=;MzQwODEyNEAzMjMwMmUzMTJlMzBheWxSb1dxYmNoU2FQL3BYa3F3V29QNGpIcTRmaDBzdktJZjkxVHV0bFc4PQ==;MzQwODEyNUAzMjMwMmUzMTJlMzBsRkNsdlZSVXcyQkZsVVR1czRZN1dUTmg2ZFRlcjJkY3pKdnU4eVRwNktRPQ==');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('it');
    translate.use('it');
  }
}
