import { InterventiTecnico } from './../Models/InterventiTecnico';
import { PianificaObj } from './../Models/PianificaObj';
import { ClassicType } from './../../shared/models/ClassicType';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { RitornoPaginazione } from 'src/app/shared/models/RitornoPaginazione';
import { Intervento } from '../Models/Intervento';
import {Tempo} from "../Models/tempo";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  header() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  headerforfile() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  getStatiIntervento(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/stati`,
      this.options()).pipe(map((data) => {
        const output: ClassicType[] = [];
        data.forEach(item => output.push({id: item.id.toString(), label: item.label}))
        return output;
      }));
  }
  getTipiIntervento(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/types`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getInterventi(value): Observable<RitornoPaginazione<Intervento[]>> {
    return this.http.post<RitornoPaginazione<Intervento[]>>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Intervento[]> = new RitornoPaginazione<Intervento[]>();
        prodotti.total = data.total;
        prodotti.data = data.data;
        return prodotti;
      }));
  }
  getInterventiAltrui(value): Observable<RitornoPaginazione<Intervento[]>> {
    return this.http.post<RitornoPaginazione<Intervento[]>>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/list/other`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Intervento[]> = new RitornoPaginazione<Intervento[]>();
        prodotti.total = data.total;
        prodotti.data = data.data;
        return prodotti;
      }));
  }
  getInterventiProgetto(value): Observable<RitornoPaginazione<Intervento[]>> {
    return this.http.post<RitornoPaginazione<Intervento[]>>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/progetto`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Intervento[]> = new RitornoPaginazione<Intervento[]>();
        prodotti.total = data.total;
        prodotti.data = data.data;
        return prodotti;
      }));
  }
  getInterventiClienti(value): Observable<RitornoPaginazione<Intervento[]>> {
    return this.http.post<Intervento[]>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/cliente`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const prodotti: RitornoPaginazione<Intervento[]> = new RitornoPaginazione<Intervento[]>();
        prodotti.total = data.length;
        prodotti.data = data;
        return prodotti;
      }));
  }
  getInterventigiornalieri(giorno: string, tecnici: number[]): Observable<InterventiTecnico[]> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/progetti/fasi/day`,
      JSON.stringify({data:giorno, tecnici: tecnici}),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getInfoIntervento(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  addinterventi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateinterventi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  pianificaInterventi(value:PianificaObj): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/update/group`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  softDeleteinterventi(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }

  getComuni(stringa: string): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiPaths.getBaseAPI()}/anagrafica/clienti/comuni`,
    JSON.stringify({comune: stringa}),
      this.options()).pipe();
  }
  AddPartecipante(value): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/partecipante/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  DeletePartecipante(value): Observable<any[]> {
    return this.http.post<any[]>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/partecipante/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  GetGanntdata(value): Observable<any>{
    return this.http.post<any[]>(`${this.apiPaths.getBaseAPI()}/progetti/fasi/list/gantt`,
      JSON.stringify(value),
      this.options()).pipe();
  }


  //#region tempi
  getTempi(value): Observable<RitornoPaginazione<Tempo[]>> {
    return this.http.post<RitornoPaginazione<Tempo[]>>(
      `${this.apiPaths.getBaseAPI()}/progetti/tempi/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {return data;}));
  }
  addTempo(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/tempi/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateTempo(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/tempi/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteTempo(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/progetti/tempi/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion
}
