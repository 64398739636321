import { ClassicType } from '../../shared/models/ClassicType';
import {Persona} from "../../Admin/models/persona";
import {Rma} from "../../rma/_Models/rma";
import {Task} from "../../task/models/Task";
export class Scadenza extends Task{
  padreObject?: any;
  attivitaObject?: any;
  managerObject?: any;
  attivita?: number;

  constructor() {
    super();
  }
  noteTecnico?: string;
  telefono?: string;
  padre?: number;
  id?: number;
  nome?: string;
  note?: string;
  data_inizio_prevista?: Date;
  data_fine_prevista?: any;
  data_inizio_effettiva?: Date;
  data_fine_effettiva?: Date;
  alert_inizio_task?: number;
  alert_fine_task?: number;
  tipo?: string;
  tipoObject?: ClassicType;
  chiuso?: boolean;
  priorita?: number;
  manager?: Persona | number | any;
  figli?: Scadenza[];

  // ripeti per
  tipo_periodo?: string; // settimanale / mensile / annuale
  frequenza?: string | number;
  periodo?: number;

  // ogni 5 del mese
  giorno_mese?: boolean; //
  giorno_mese_ordine?: string;
  giorno_mese_giorno?: string;

  // "frequenza"             => "nullable|integer|min:2",
  // "periodo"               => "nullable|integer|min:1",
  // "giorno_mese"           => "nullable|bool",
  // "giorno_mese_ordine"    => "nullable|string|in:first,second,third,fourth|required_if:giorno_mese,true",
  // "giorno_mese_giorno"    => "nullable|string|in:monday,tuesday,wednesday,thursday,friday,saturday,sunday|required_if:giorno_mese,false",
  // "tipo_periodo"          => "nullable|string|in:days,weeks,months,years"

  public static RmaToScadenza(rma:Rma):Scadenza{
    var out: Scadenza = new Scadenza();
    out.id= rma.id;
    out.nome = rma.nome;
    out.note= rma.note;
    out.data_inizio_prevista = rma.data_inizio_prevista;
    out.data_fine_prevista= rma.data_fine_prevista;
    out.data_inizio_effettiva = rma.data_inizio_effettiva;
    out.data_fine_effettiva= rma.data_fine_effettiva;
    out.alert_inizio_task = rma.alert_inizio_task;
    out.alert_fine_task= rma.alert_fine_task;
    out.tipo = rma.tipo;
    out.tipoObject= rma.tipoObject;
    out.chiuso = rma.chiuso;
    out.priorita= rma.priorita;
    out.manager = rma.manager;
    out.figli = rma.figli;
    out.padreObject = rma.padreObject;
    out.telefono = rma.telefono;
    out.noteTecnico = rma.noteTecnico;
    return out;
  }


}
