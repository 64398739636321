<div class="form-group col-lg-12 row">
    <!-- <input type="date" name="inzio" [(ngModel)]="filtro.inizio" (ngModelChange)="Refresh()" id="data_inizio" class="form-control col-lg-3 ml-2">
    <input type="date" name="fine" [(ngModel)]="filtro.fine" (ngModelChange)="Refresh()" id="data_fine" class="form-control col-lg-3 ml-2"> -->
    <div class="ml-auto">
        <!-- <button *ngIf="!storico" type="button" class="btn btn-cyan py-2 ml-auto" (click)="switchStorico()"><span class="ml-1 text-white font-weight-bold">Storico</span></button> -->
        <button *ngIf="storico" type="button" class="btn btn-cyan py-2 ml-auto" (click)="switchStorico()"><span class="ml-1 text-white font-weight-bold">Torna Indietro</span></button>
        <!--<button type="button" class="btn btn-cyan py-2 ml-auto" (click)="add()"><i class="fas fa-plus  font-14 mr-1 text-white"></i><span class="ml-1 text-white font-weight-bold">Aggiungi</span></button>-->
        <button type="button" class="btn btn-cyan py-2 ml-auto" (click)="inFieri()"><i class="fas fa-plus  font-14 mr-1 text-white"></i><span class="ml-1 text-white font-weight-bold">Aggiungi</span></button>
    </div>
</div>



<table *ngIf="!storico && dati" class="table">
    <thead>
        <tr>
            <th scope="col">Valutazioni</th>
            <th *ngFor="let date of dati.valutazioni" style="cursor: pointer;" (click)="edit(date)" [ngClass]="Scaduto(date)?'':'text-info'" class="" cope="col">
                {{ (!date.inFieri ? date.data_inizio_prevista : '') | date:'dd/MM/yyyy '}}
                 <br> 
                <span style="color:red" *ngIf="date.inFieri">In Fieri</span>
                <span *ngIf="!date.inFieri && !Scaduto(date)">Approvato</span>
                <span style="color:gray" *ngIf="!date.inFieri && Scaduto(date)">{{date.data_fine_effettiva | date: 'dd/MM/yyyy'}}</span> 
            </th><!--da mettere ng for sulle date-->
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of dati.data">
            <th scope="row">{{item.label}}</th>
            <th *ngFor="let value of item.values">{{value.valore}}</th>
        </tr>
    </tbody>
</table>
<div #dynamicComponentContainer [class.d-none]="!storico"></div>
