<div class="d-md-flex align-items-center bg-white p-2" *ngIf="!hideAdd" >
    <span class="d-flex align-items-center p-2 ">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14">{{'RMA.attivita.title' | translate}}:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14" *ngIf="ListaScadenze">{{ListaScadenze.length}}</span>
    </span>
  <div class="ml-auto mt-3 mt-md-0">
    <button type="button" (click)="ScadAddButton()" class="btn btn-cyan rounded-pill p-2" ><i
      class="fas fa-plus  font-14 mr-1 text-white"></i>
      <span class="ml-1 text-white font-weight-bold">{{'RMA.attivita.title' | translate}}</span>
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table bg-white table-hover align-middle mb-0 " *ngIf="!environment.RmaAddOn['headersScadenza']">
    <thead>
    <tr>
      <th >{{'RMA.attivita.nome' | translate}}</th>
      <th >{{'RMA.attivita.note' | translate}}</th>
      <th scope="col">{{'RMA.attivita.dataip' | translate}}</th>
      <th scope="col">{{'RMA.attivita.azioni' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let scadenza of ListaScadenze">
      <td>
        <span class="text-dark font-medium font-16">
          {{ scadenza.nome}}
          </span>
      </td>
      <td>
        <span class="text-dark font-medium font-16">
          {{ scadenza.note}}
          </span>
      </td>
      <td class="align-middle">
        <span class="text-dark font-medium font-16">
          {{ scadenza.data_inizio_prevista | date: "dd/MM/yyyy"}}
        </span>
      </td>
     <!-- <td class="align-middle">
          <span>
            {{ scadenza.data_fine_prevista | date: "dd/MM/yyyy"}}
          </span>
      </td>-->
      <td class="align-middle">
        <a href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)="ScadModBtn(scadenza)">
          <i class="fas fa-pencil-alt"></i>
        </a>
        <a href="javascript:void(0)" class="link font-16 text-danger font-medium mr-2" (click)="Delete(scadenza)">
          <i class="fas fa-trash-alt"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
  <table class="table bg-white table-hover align-middle mb-0 " *ngIf="environment.RmaAddOn['headersScadenza']" cdkDropList  (cdkDropListDropped)="drop($event)">
    <thead>
    <tr>
      <ng-container *ngFor="let campo of environment.RmaAddOn['headersScadenza']">
        <th scope="col" *ngIf="permission.isntOperativeRoles(campo.prohibitedRoles) && permission.isOperativeRoles(campo.AllowedRoles) && headShow(campo)" >{{campo.nome}}</th>
      </ng-container>
      <th scope="col">Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of ListaScadenze" [ngbTooltip]="item.note" cdkDrag>
      <ng-container *ngFor="let campo of environment.RmaAddOn['headersScadenza']">
        <td *ngIf="permission.isntOperativeRoles(campo.prohibitedRoles) && permission.isOperativeRoles(campo.AllowedRoles) && headShow(campo)" (click)='ScadModBtn(item)'>
          <span style="font-size: 14px;" [ngClass]="{'badge badge-pill px-3': campoUtility.Colore(campo,item) }" >
            <ng-container *ngFor="let oggetto of campo.oggetti; let i = index" >
              <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'date' && item[oggetto]">
                {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy'}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'object' && campo.inputType == 'date' && campoUtility.getObj(item, oggetto) ">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy'}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'datetime-local' && item[oggetto]">
                {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'object' && campo.inputType == 'datetime-local' && campoUtility.getObj(item, oggetto)">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
              </ng-template>
               <ng-template [ngIf]="campo.type!= 'object' && campo.inputType != 'date' && campo.type != 'replace' && campo.type != 'objArray' && campo.inputType != 'datetime-local'">
                {{campoUtility.getObj(item, oggetto)}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'object' && campo.inputType != 'date' && item[oggetto] && campoUtility.getObj(item, oggetto)">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]]}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'object' && campo.inputType != 'date' && !item[oggetto] && campo.oggettisec && campo.oggettisec[i] && item[campo.oggettisec[i]]">
                {{item[campo.oggettisec[i]][campo.field[i]]}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'replace' ">
                {{campoUtility.Replace(campo,item, oggetto)}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'objArray' ">
                <ng-container *ngFor="let obg of campoUtility.getObj(item, oggetto)">
                  {{obg[campo.field[i]]}} &nbsp;
                </ng-container>
              </ng-template>
              <ng-container *ngIf="campo.type == 'boolean'">
                <span [class.text-danger]="!item[oggetto]" [class.text-success] = "item[oggetto]" >
                  <i [class.far] ="!item[oggetto]" [class.fa-times-circle] ="!item[oggetto]" [class.fas] ="item[oggetto]" [class.fa-check-circle] ="item[oggetto]" ></i>
                </span>
              </ng-container>
           </ng-container>
          </span>
        </td>
      </ng-container>
      <td class="align-middle">
        <ng-container *ngIf="environment.RmaAddOn['AzioniScadenza']">
          <ng-container *ngFor="let button of environment.RmaAddOn['AzioniScadenza']">
            <a type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"  *ngIf="permission.HasCapacity(button['capacita']) && campoUtility.ButtonShow(button,item)" (click)='customButton(item,button)'>
              <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
              <span [ngClass]="button['labelClass']"> {{button['nome'] | translate}} </span>
            </a>
          </ng-container>
        </ng-container>
        <a href="javascript:void(0)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2" (click)='ScadModBtn(item)'>
          <i-feather name="edit-3" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="item.type == 'S'" href="javascript:void(0)" ngbTooltip="Elimina" class="link font-16 text-danger font-weight-bold"
           (click)='Delete(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="tag(item)" href="javascript:void(0)"
           [ngbTooltip]="'Sei stato/a taggato/a in questa Attivita'"
           class="link font-16 text-danger font-weight-bold">
          <i class="fas fa-exclamation-circle text-info"></i>
        </a>
        <a class="example-handle" cdkDragHandle>
          <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
            <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
