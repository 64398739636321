import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MarkerIconPath {
  private iconBase = 'http://maps.google.com/mapfiles/';
  public selezionato: string = this.iconBase + "ms/icons/blue-dot.png";
  public inLista: string = this.iconBase + "ms/icons/red-dot.png";
  public nonInLista: string = this.iconBase + "ms/icons/grey.png";
  public pianificato: string = this.iconBase + "ms/icons/yellow.png";
  public pianificatoNonInLista: string = this.iconBase + "ms/icons/pink.png";
  public pianificatoData: string = this.iconBase + "ms/icons/green.png";
  public getSelezionato = () => this.selezionato;
  public getInLista = () => this.inLista;
  public getNonInLista = () => this.nonInLista;
  public getPianificato = () => this.pianificato;
  public getPianificatoNonInLista = () => this.pianificatoNonInLista;
  public getpianificatoData = () => this.pianificatoData;

}
