import { RitornoPaginazione } from './../../shared/models/RitornoPaginazione';
import { Scadenza } from './../Models/Scadenza';
import { ClassicType } from './../../shared/models/ClassicType';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  header() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  headerforfile() {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }
  //#region  scadenze
  getTipiScadenze(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/types`,
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  getListaScadenze(value): Observable<RitornoPaginazione<Scadenza[]>> {
    return this.http.post<RitornoPaginazione<Scadenza[]>>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        return data;
      }));
  }
  addScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  getScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/get`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteScadenza(value): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/scadenzario/scadenze/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  //#endregion

}
