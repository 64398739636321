<div class="modal-header"  *ngIf="IsModal" >
  <h5 class="modal-title" id="editUserLabel">Task</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="{'': IsModal, 'card': !IsModal }">
  <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
    <app-stampa [Stampe]="Stampe" *ngIf="Stampe && Stampe.length > 0"></app-stampa>
    <div class="row">
      <div class="col-sm-12 col-md-3 p-t-0 p-b-0 p-r-5 p-l-5" *ngIf="!offerta?.id && !InterventoSelezionato?.id && !InterventoSelezionato?.padre">
        <label for="tipo" class="control-label col-form-label">Offerta:</label>
        <ng-multiselect-dropdown required #offerteb="ngModel" [disabled]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRoles(['RF','PM'])" [settings]="offerteDropdownSettings" [placeholder]="'Seleziona'" id="offerte" name="offerte"
                                 [data]="offerte" [(ngModel)]="offerteSelezionate">
        </ng-multiselect-dropdown>
        <small class="form-text text-danger" *ngIf="!offerteb.valid && (offerteb.dirty || offerteb.touched)">campo richiesto!</small>
      </div>
      <div class="col-sm-12 col-md-3 p-t-0 p-b-0 p-r-5 p-l-5">
        <label for="tipo" class="control-label col-form-label">Tipo:</label>
        <ng-multiselect-dropdown required #tipo="ngModel" [disabled]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF') && !permission.hasOperativeRole('PM')" [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="tipo" name="tipo"
          [data]="tipiIntervento" [(ngModel)]="InterventoSelezionato.tipiObject">
        </ng-multiselect-dropdown>
        <small class="form-text text-danger" *ngIf="!tipo.valid && (tipo.dirty || tipo.touched)">Tipo di intervento
          richiesto!</small>
      </div>
      <div class="col-sm-12 col-md-3 p-t-0 p-b-0 p-r-5 p-l-5">
        <label for="resp" class="control-label col-form-label">Responsabile:</label>
        <ng-multiselect-dropdown required #resp="ngModel" [disabled]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF') && !permission.hasOperativeRole('PM')" [settings]="userDropdownSettings" [placeholder]="'Seleziona'" id="resp" name="resp" [data]="Responsabili"
          [(ngModel)]="InterventoSelezionato.managers" (click)="filtroPersonale('')" (onFilterChange)="filtroPersonale($event)">
        </ng-multiselect-dropdown>
        <small class="form-text text-danger" *ngIf="!resp.valid && (resp.dirty || resp.touched)">Responsabile dell'intervento
          richiesto!</small>
      </div>
      <div class="col-sm-12 col-md-3 p-t-0 p-b-0 p-r-5 p-l-5">
        <label for="stato" class="control-label col-form-label">Stato:</label>
        <ng-multiselect-dropdown required #stato="ngModel" [disabled]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="stato" name="stato" [data]="stati"
          [(ngModel)]="InterventoSelezionato.statiObject">
        </ng-multiselect-dropdown>
        <small class="form-text text-danger" *ngIf="!stato.valid && (stato.dirty || stato.touched)">Stato dell'intervento richiesto!</small>
      </div>
      <div *ngIf="InterventoSelezionato.id" class="col-sm-12 col-md-3 m0 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="durata" class="control-label col-form-label">Durata(Ore Uomo):</label>
          <input type="number" step="0.1" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [(ngModel)]="InterventoSelezionato.durata" class="form-control" id="durata">
        </div>
      </div>
      <div class="col-sm-12 col-md-12 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="nome" class="control-label col-form-label">Descrizione:</label>
          <textarea type="text" #nome="ngModel" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" required [(ngModel)]="InterventoSelezionato.nome" class="form-control" id="nome" placeholder="Nome"></textarea>
          <small class="form-text text-danger" *ngIf="!nome.valid && (nome.dirty || nome.touched)">Nome dell'intervento richiesto!</small>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="dip"  class="control-label col-form-label">Data inizio prevista:</label>

          <input required #dip="ngModel" type="datetime-local" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [(ngModel)]="InterventoSelezionato.data_inizio_prevista" class="form-control" id="dip" >
          <small class="form-text text-danger" *ngIf="!dip.valid && (dip.dirty || dip.touched)">Data di inizio prevista dell'intervento
            richiesta!</small>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 m0 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="dfp" class="control-label col-form-label">Data fine prevista:</label>
          <input #dfp="ngModel" type="datetime-local" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [(ngModel)]="InterventoSelezionato.data_fine_prevista" class="form-control" id="dfp">
          <small class="form-text text-danger" *ngIf="!dfp.valid && (dfp.dirty || dfp.touched)">data di fine prevista dell'intervento
            richiesta!</small>
        </div>
      </div>
      <div *ngIf="InterventoSelezionato.id" class="col-sm-12 col-md-3 m0 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="die" class="control-label col-form-label">Data inizio effettiva:</label>
          <input type="datetime-local" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [(ngModel)]="InterventoSelezionato.data_inizio_effettiva" class="form-control" id="die">
        </div>
      </div>
      <div *ngIf="InterventoSelezionato.id" class="col-sm-12 col-md-3 m0 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="dfe" class="control-label col-form-label">Data fine effettiva:</label>
          <input type="datetime-local" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" [(ngModel)]="InterventoSelezionato.data_fine_effettiva" class="form-control" id="dfe">
        </div>
      </div>
      <div class="col-12 m0 p-t-0 p-b-0 p-r-5 p-l-5">
        <div class="form-group">
          <label for="note" class="control-label col-form-label">Note:</label>
          <textarea type="text" rows="5" [(ngModel)]="InterventoSelezionato.note" [readOnly]="!permission.isPermitted('gestione-fasi') && !permission.hasOperativeRole('RF')&& !permission.hasOperativeRole('PM')" class="form-control" id="note" placeholder="note"></textarea>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="InterventoSelezionato && InterventoSelezionato.id" >
      <ul ngbNav #nav="ngbNav" activeId="Task" class="nav-tabs">
        <li ngbNavItem="Task">
          <a ngbNavLink> Sotto Task </a>
          <ng-template ngbNavContent>
            <app-lista-interventi-offerta class="col-12" [Offerta]="offerta" [Interventi]="InterventoSelezionato.figli" [hasAddButton]="permission.isPermitted('gestione-fasi') || permission.hasOperativeRole('PM') || permission.hasOperativeRole('RF')" [Padre]="InterventoSelezionato" ></app-lista-interventi-offerta>
          </ng-template>
        </li>
        <li ngbNavItem="Partecipanti" >
          <a ngbNavLink> Partecipanti </a>
          <ng-template ngbNavContent>
            <app-partecipanti-list-fasi class="col-12" [List]="InterventoSelezionato.persone" [hasAddButton]="permission.isPermitted('gestione-fasi') || permission.hasOperativeRole('RF')|| permission.hasOperativeRole('PM')" [Padre]="InterventoSelezionato" ></app-partecipanti-list-fasi>
          </ng-template>
        </li>
        <li ngbNavItem="Tempi">
          <a ngbNavLink> Tempi </a>
          <ng-template ngbNavContent>
            <app-tempi-list class="col-12" [List]="InterventoSelezionato.tempi" [hasAddButton]="true" [ModificheAbilitate]="true" [Padre]="InterventoSelezionato" ></app-tempi-list>
          </ng-template>
        </li>
        <li ngbNavItem="File" >
          <a ngbNavLink> File </a>
          <ng-template ngbNavContent>
            <app-file-uploader class="col-12" [url]="'/progetti/fasi/file'" [(id)]="InterventoSelezionato.id" [GestioneFile]="permission.isPermitted('gestione-fasi') || permission.hasOperativeRole('PM') || InterventoSelezionato?.manager?.id == authSvc?.currentUserValue?.id " (onChange)="onFileChanged()"></app-file-uploader>
          </ng-template>
        </li>
        <li ngbNavItem="Check"  >
          <a ngbNavLink> Checklist </a>
          <ng-template ngbNavContent>
              <app-check-list [(Items)]="InterventoSelezionato.todo"></app-check-list>
          </ng-template>
        </li>
        <li ngbNavItem="Spese" *ngIf="environment.Modules.Prima_Nota" >
          <a ngbNavLink> Costi </a>
          <ng-template ngbNavContent>
            <div class="d-flex justify-content-between">
              <div class="p-3"  style="width: 75%;" *ngIf="!isModifyDisabled && permission.isPermitted('gestione-spese')" >
                <div class="row" >
                  <div *ngIf="permission.isPermitted('gestione-spese')"  class="col-lg-6">
                    <ng-multiselect-dropdown [settings]="speseDropdownSettings" [placeholder]="'Costo'"  [data]="Spese" [(ngModel)]="Spesa">
                    </ng-multiselect-dropdown>
                  </div>
                  <div class="d-flex align-items-center" >
                    <button class="btn btn-outline-info " style="width: 100%;"  (click)="addSpesa()" type="button">
                      {{ 'Aggiungi'}}
                    </button>
                  </div>
                </div>

              </div>

            </div>
            <div class="table-responsive">
              <table class="table bg-white table-hover align-middle mb-0 no-wrap">
                <thead>
                <tr>
                  <th >Costo</th>
                  <th scope="col">Quantita</th>
                  <th scope="col" *ngIf="permission.isPermitted('gestione-spese') && !isModifyDisabled">Azioni</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of InterventoSelezionato.speseObject">
                  <td >
                    <span class="font-14">{{item.label}}</span>
                  </td>
                  <td class="align-middle" >
                    <span *ngIf="!permission.isPermitted('gestione-spese') || isModifyDisabled" class="font-14">{{item.quantita}}</span>
                    <input *ngIf="permission.isPermitted('gestione-spese') && !isModifyDisabled" [(ngModel)]="item.quantita"  placeholder="Quantità" type="number" name="qty" class="form-control">
                  </td>
                  <td class="align-middle" *ngIf="permission.isPermitted('gestione-spese') && !isModifyDisabled">
                    <a href="javascript:void(0)" ngbTooltip="Elimina"
                       class="link font-16 text-danger font-weight-bold" (click)='delSpesa(item)'>
                      <i-feather name="trash-2" class="feather-sm"></i-feather>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </ng-template>
        </li>

        <!--      <li ngbNavItem="Files">-->
        <!--        <a ngbNavLink> Files </a>-->
        <!--        <ng-template ngbNavContent>-->
        <!--          <app-file-uploader (onChange)="onFileChanged()"></app-file-uploader>-->
        <!--        </ng-template>-->
        <!--      </li>-->
      </ul>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10" *ngIf="permission.isPermitted('gestione-fasi') || permission.hasOperativeRole('PM') || permission.hasOperativeRole('RF')">
  <button type="submit" (click)="saveClick()"  class=" btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Salva </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
  <button type="submit" (click)="saveClick(true)"  class=" btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Salva e chiudi </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>

<!-- <div class="card">
  <app-voci-di-costo></app-voci-di-costo>
</div>
<div class="card">
  <app-firma></app-firma>
</div> -->


