import { ClassicType } from './../../shared/models/ClassicType';
import { Persona } from './persona';
export class Attivita {
  id?: number;
  attivita?: string;
  note?: string;
  data_attivita?: string;
  data_chiusura?: string;
  att_chiusa?: boolean;
  responsabile?: Persona;
  resp_attivita?: number;
  allegato?: string;// id del file
  file?: string; // nome del file
  cliente?: number;
  tipo?: string;
  tipoObject?: ClassicType;
  ordine?: number;

}
