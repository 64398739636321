<div class="modal-header" *ngIf="IsModal">
  <h5 class="modal-title"> {{'Scadenza.title' | translate}}</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="selected && selected != null && IsModal" class="modal-body">
  <div class="row">
    <div class="col-lg-3">
      <label for="tipo" class="control-label col-form-label">{{'Scadenza.tipo' | translate}}: *</label>
      <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Scadenza.tipo' | translate" id="tipo" [data]="Tipi"
                               [(ngModel)]="TipiSelezionati" (ngModelChange)="TipoCambiato()">
      </ng-multiselect-dropdown>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="indirizzo" class="control-label col-form-label">{{'Scadenza.nome' | translate}}:</label>
        <input type="text" required [(ngModel)]="selected.nome" class="form-control" id="indirizzo" placeholder="Nome">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="data_inizio_prevista" class="control-label col-form-label">{{'Scadenza.dataip' | translate}}:</label>
        <input type="datetime-local" [(ngModel)]="selected.data_inizio_prevista" class="form-control" id="data_inizio_prevista">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="data_fine_prevista" class="control-label col-form-label">{{'Scadenza.datafp' | translate}}:</label>
        <input type="datetime-local" [(ngModel)]="selected.data_fine_prevista" class="form-control" id="data_fine_prevista">
      </div>
    </div>
    <div class="col-lg-3" *ngIf="environment.CalendarAddon.managerScadenza">
      <label for="manager" class="control-label col-form-label">{{'Scadenza.manager' | translate}}: *</label>
      <ng-multiselect-dropdown [settings]="userDropdownSettings" [placeholder]="'Scadenza.manager' | translate" id="manager" [data]="managers"
                               [(ngModel)]="selectedManagers" (onFilterChange)="managerFilter($event)" >
      </ng-multiselect-dropdown>
    </div>
<!--    <div class="col-3">-->
<!--      <div class="form-group">-->
<!--        <label for="alert_inizio_Task" class="control-label col-form-label">Avvisami ore prima:</label>-->
<!--        <input type="number" [(ngModel)]="selected.alert_inizio_task" class="form-control" id="alert_inizio_Task">-->
<!--      </div>-->
<!--    </div>-->
    <div class="col-4">
      <div class="row">
        <span >{{'Scadenza.avvisami' | translate}}</span>
        <div class="col-3">
                <div class="form-group">
<!--                  <label for="alert_inizio_Task" class="control-label col-form-label">Avvisami ore prima:</label>-->
                  <input type="number" min="0" step="1" [(ngModel)]="selected.alert_inizio_task" class="form-control" id="alert_inizio_Task">
                </div>
          </div>
        <span >{{'Scadenza.ore' | translate}}</span>
      </div>

      <!--      <div class="form-group">-->
      <!--        <label for="alert_inizio_Task" class="control-label col-form-label">Avvisami ore prima:</label>-->
      <!--        <input type="number" [(ngModel)]="selected.alert_inizio_task" class="form-control" id="alert_inizio_Task">-->
      <!--      </div>-->
    </div>
    <div class="col-3">
      <div class="row">
        <span for="chiuso" >{{'Scadenza.chiuso' | translate}}:</span>
        <div class="col-3">
          <div class="form-group">
            <input type="checkbox" [(ngModel)]="selected.chiuso" class="form-control" id="chiuso">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label for="note" class="control-label col-form-label">{{'Scadenza.note' | translate}}:</label>
        <textarea type="text" [(ngModel)]="selected.note" class="form-control" id="note" placeholder="Note"></textarea>
      </div>
    </div>
    <div class="col-12">
      <div class="row" *ngIf="!selected.id">
        <span >{{'Scadenza.periodo' | translate}}</span>
        <div class="col-1" *ngIf="!selected.id">
          <div class="form-group">
            <input type="number" [(ngModel)]="selected.periodo" class="form-control" id="periodo">
          </div>
        </div>
        <div class="col-lg-2" *ngIf="!selected.id">
          <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Periodo'" id="tipo_periodo" [data]="TipiPeriodo"
                                   [(ngModel)]="TipiPeriodoSelezionati" (ngModelChange)="TipoPeriodoCambiato()">
          </ng-multiselect-dropdown>
        </div>
        <span >{{'Scadenza.per' | translate}}</span>
        <div class="col-1" *ngIf="!selected.id">
          <div class="form-group">
            <input type="number" [(ngModel)]="selected.frequenza" class="form-control" id="frequenza">
          </div>
        </div>
        <span >{{'Scadenza.volte' | translate}}</span>
      </div>
      <div class="row" *ngIf="TipiPeriodoSelezionati && TipiPeriodoSelezionati.length > 0 && TipiPeriodoSelezionati[0] && TipiPeriodoSelezionati[0].id == 'months' ">
        <div class="col-2">
          <div class="form-group">
            <label for="giorno_mese" class="control-label col-form-label">{{'Scadenza.stessoGiorno' | translate}}:</label>
            <input type="checkbox" [(ngModel)]="!selected.giorno_mese" class="form-control" id="giorno_mese">
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label class="control-label col-form-label">{{'Scadenza.giornoSpecifico' | translate}}:</label>
            <input type="checkbox" [(ngModel)]="selected.giorno_mese" class="form-control" >
          </div>
        </div>
        <span *ngIf="!selected.id && selected.giorno_mese">{{'Scadenza.ogni' | translate}}</span>
        <div class="col-lg-3" *ngIf="!selected.id && selected.giorno_mese">
          <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Scadenza.ordine' | translate"  [data]="giorno_mese_ordineList"
                                   [(ngModel)]="giorno_mese_ordineSelezionati">
          </ng-multiselect-dropdown>
        </div>
        <div class="col-lg-3" *ngIf="!selected.id && selected.giorno_mese">
          <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Scadenza.giorno' | translate" [data]="giorno_mese_giornoList"
                                   [(ngModel)]="giorno_mese_giornoSelezionati" >
          </ng-multiselect-dropdown>
        </div>
        <span *ngIf="!selected.id && selected.giorno_mese">{{'Scadenza.delMese' | translate}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="environment.TaskAddon.checkList">
    <hr>
    <app-check-list [(Items)]="selected.todo"></app-check-list>
  </div>
  <hr *ngIf="selected.id">
  <app-scadenze-list *ngIf="selected.id && hasScadenzeFiglie" [ListaScadenze]="selected.figli" [Padre]="selected.padreObject" (AddButtonClick)="ScadAddButton()" (Updated)="updated.emit()"></app-scadenze-list>
</div>
<div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
  <button (click)="saveModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'Scadenza.save' | translate}} </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
