import { LoggedUser } from '../../Login/Models/LoggedUser';
import {Files} from "../../shared/models/File";
import {Azienda} from "../../Admin/models/Azienda";
import {Persona} from "../../Admin/models/persona";
import {Tempo} from "../../Interventi/Models/tempo";
export class Task {
  id?: number;
  nome?: string;
  note?: string;
  data_inizio_prevista?: any;
  data_fine_prevista?: any;
  data_inizio_effettiva?: any;
  data_fine_effettiva?: any;
  alert_inizio_task?: number;
  alert_fine_task?: number;
  operatore?: LoggedUser;
  tipo?: any;
  files?: Files[];
  chiuso?: boolean
  type?: string;
  manager?: any;
  notaInterna?: string;
  notaChiusura?: string;
  statoObject?: any;
  tipoObject?: any;
  stato?: any;
  todo?: any;
  assistenzaId?: number;
  clienteObject?: Azienda;
  cliente?: any;
  provaId?: any;
  provaObject?: any;
  abilitata?: Boolean | number;
  figli?: any;
  progressivo?: any;
  partecipanti?: number[];
  partecipantiObject?: Persona[];
  noteTecnico?:string;
  tempi?: any;
  padreObject?: any;
  padre?: number;
  chiusoObject?: { id: boolean; label: string };
  magazzino?: any;
  scaricoMagazzino?: boolean;
  managerObject?: Persona;
  constructor(manager?: Persona) {
    this.todo=[]
    this.manager = manager?.id;
    this.managerObject = manager;
    this.alert_inizio_task = 0;
    this.alert_fine_task = 0;
  }
}
