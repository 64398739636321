import { environment } from "src/environments/environment";
import {HttpHeaders} from "@angular/common/http";
import {AuthenticationService} from "../Login/_services/authentication.service";
import {Injectable} from "@angular/core";

/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2023.
 * Title: RmaData.ts;
 * Path: /Volumes/Dati 1/SbSW_SoftwareBySystem_Works/KI_KlimaItalia/GesKI_sbs_gestionale_Klimautalia_front_angular/src/app/rma/RmaData.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 07/09/23, 09:21;
 * Updated at: 07/09/23, 09:21;
 *
 *
 */
@Injectable({
  providedIn: 'root'
})
export class RmaData {
  constructor(private authSvc: AuthenticationService) {
  }


  public getRmaHeaders(): HttpHeaders {
    var  logged =  JSON.parse(localStorage.getItem('currentUser'));
  var  rmaHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${environment.RmaAddOn['linkPiattaformaAssistenza'] ? this.getRmaUser()?.access_token : logged?.access_token}`,
    });


    return rmaHeaders;
  }

  public getRmaLink(): string {
    return environment.RmaAddOn['linkPiattaformaAssistenza']
      ? environment.RmaAddOn['linkPiattaformaAssistenza'] + '/api'
      : null;
  }

  public getRmaUser(): any {
    return environment.RmaAddOn['linkPiattaformaAssistenza'] ? JSON.parse(localStorage.getItem('rmaCurrentUser')) : null;
  }
  public getRmaMultipartHeaders(): HttpHeaders {
    var  logged =  JSON.parse(localStorage.getItem('currentUser'));
    return new HttpHeaders({
      Authorization: `Bearer ${environment.RmaAddOn['linkPiattaformaAssistenza'] ? this.getRmaUser()?.access_token : logged?.access_token}`,
    });;
  }
}

