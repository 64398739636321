import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ripetizione-task',
  templateUrl: './ripetizione-task.component.html',
  styleUrls: ['./ripetizione-task.component.scss']
})
export class RipetizioneTaskComponent implements AfterViewInit {

  isDropdownOpen = false;
  showOrder = false;
  @Input() Padre;
  @Input() SubCampi: any[] = [];
  @Input() campi: any[] = [
    {
      nome: 'Frequenza',
      source: 'frequenze',
      oggetti: ['frequenza'],
      internalOutput: 'frequenzaObject',
      OutputField: 'frequenza',
      OnChange: 'frequenzachange',
      modificabile: true,
      inputType: 'singleDropdown',
      placeholder: 'Seleziona una frequenza',
      settings: {
        singleSelection: true,
        closeDropDownOnSelection: true,
        singleOutput: true,
        selectFirst: true,
        idField: 'id',
        textField: 'label',
        searchPlaceholderText: "Cerca",
        allowSearchFilter: true,
        allowRemoteDataSearch: false,
      },
      class: 'col-lg-12',
      visibleifProhibited: true
    },
    // {
    //   nome: 'Volte Al Giorno',
    //   oggetti: ['volte'],
    //   modificabile: true,
    //   inputType: 'number',
    //   class: 'col-lg-12',
    //   visibleifProhibited: true
    // },
  ]
  @Input() ripetizione: {
    repeatEvery: number,
    frequency: 'weeks' | 'days' | 'months' | 'years',
    ordine: 'first' | 'second' | 'third' | 'fourth',
    frequenza: string,
    daysOfWeek: {
      monday: boolean,
      tuesday: boolean,
      wednesday: boolean,
      thursday: boolean,
      friday: boolean,
      saturday: boolean,
      sunday: boolean,
    },
    endCondition: 'never' | 'date' | 'occurrences',
    endDate: string,
    occurrences: number,
  } = {
      repeatEvery: 1,
      frequency: 'weeks',
      ordine: null,
      frequenza: 'UT',
      daysOfWeek: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      endCondition: 'never',
      endDate: '',
      occurrences: 1,
    };
  @Output() ripetizioneChange = new EventEmitter<any>();
  @Input() Sources: any = {
    frequenze: [{ id: 'UT', label: 'una tantum' }, { id: 'OG', label: 'ogni giorno' }, { id: 'OS', label: 'ogni settimana' }, { id: 'OM', label: 'ogni mese' }, { id: 'OA', label: 'ogni anno' }, { id: 'TT', label: 'tutti i giorni della settimana' }, { id: 'PE', label: 'personalizzata' }],
  }

  ngAfterViewInit(): void {
    if (!this.Sources)
      this.Sources = {
        frequenze: [{ id: 'UT', label: 'una tantum' }, { id: 'OG', label: 'ogni giorno' }, { id: 'OS', label: 'ogni settimana' }, { id: 'OM', label: 'ogni mese' }, { id: 'OA', label: 'ogni anno' }, { id: 'TT', label: 'tutti i giorni della settimana' }, { id: 'PE', label: 'personalizzata' }],
      }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  toggleDay(day: string) {
    this.ripetizione.daysOfWeek = this.ripetizione.daysOfWeek ?? {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    this.ripetizione.daysOfWeek[day] = !this.ripetizione.daysOfWeek[day];
    //this.ripetizioneChange.emit(this.ripetizione);
  }
  stampa() {
    console.log("Ripeti ", this.ripetizione.repeatEvery);
    console.log("Frequenza ", this.ripetizione.frequency);
    console.log("Giorni Selezionati ", this.ripetizione.daysOfWeek);
    console.log("Condizione Finale", this.ripetizione.endCondition);
    console.log("Data di Fine", this.ripetizione.endDate);
    console.log("Occorrenze", this.ripetizione.occurrences);
  }

  verify(name: string, min: number = 1) {
    setTimeout(() => { this.ripetizione[name] = !this.ripetizione[name] || this.ripetizione[name] < min ? min : this.ripetizione[name]; }, 1000)
  }
  ChangeHandler(item: { OnChange: string; Event: any }) {
    if (this.ripetizione['frequenzaObject']) {
      var freq = this.Sources.frequenze.find(x => x.id == this.ripetizione['frequenzaObject'].id)
      if (freq?.ChangeType)
        freq?.ChangeType(this.Padre, this.ripetizione);
    }
   
    switch (item.OnChange) {
      case 'frequenzachange':
        if (!item.Event) return;
        this.SubCampi = [
          ...item.Event?.campi ?? []
        ]
        console.log("Frequenza Change", item.Event);
        switch (item.Event?.id) {
          case 'UT':
            this.ripetizione.endCondition = 'occurrences';
            this.ripetizione.occurrences = 1;
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.frequency = 'days';
            this.ripetizione.daysOfWeek = undefined;
            break;
          case 'OG':
            this.ripetizione.endCondition = 'never';
            this.ripetizione.frequency = 'days';
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.daysOfWeek = undefined;
            break;
          case 'OS':
            this.ripetizione.endCondition = 'never';
            this.ripetizione.frequency = 'weeks';
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.daysOfWeek = undefined;
            break;
          case 'OM':
            this.ripetizione.endCondition = 'never';
            this.ripetizione.frequency = 'months';
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.daysOfWeek = undefined;
            break;
          case 'OA':
            this.ripetizione.endCondition = 'never';
            this.ripetizione.frequency = 'years';
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.daysOfWeek = undefined;
            break;
          case 'TT':
            this.ripetizione.endCondition = 'never';
            this.ripetizione.frequency = 'weeks';
            this.ripetizione.repeatEvery = 1;
            this.ripetizione.daysOfWeek = {
              monday: true,
              tuesday: true,
              wednesday: true,
              thursday: true,
              friday: true,
              saturday: true,
              sunday: true,
            };
            break;
        }
        break;
    }
  }

}



