<div class="modal-header">
  <h5 class="modal-title" id="editUserLabel">Modifica Attività</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="attivita" class="control-label col-form-label">Attività</label>
          <input [(ngModel)]="attivita.attivita" type="text" class="form-control" id="attivita" placeholder="Attività">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="data" class="control-label col-form-label">Data</label>
          <input [(ngModel)]="attivita.data_attivita" type="datetime-local" class="form-control" id="data">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="datachiusura" class="control-label col-form-label">Data di chiusura</label>
          <input [(ngModel)]="attivita.data_chiusura" type="datetime-local" class="form-control" id="datachiusura">
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input [(ngModel)]="attivita.att_chiusa" type="checkbox" class="custom-control-input" id="chiusa">
            <label class="custom-control-label" for="chiusa">Attivita conclusa</label>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="note" class="control-label col-form-label">Note</label>
          <textarea rows="5" [(ngModel)]="attivita.note" type="text" class="form-control" id="note"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div *ngIf="tipiAttivita" class="">
        <label class="control-label col-form-label"> Tipologia:</label>
        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Seleziona'"
                                 [data]="tipiAttivita" [(ngModel)]="tipologieSelezionate">
        </ng-multiselect-dropdown>
      </div>
    </div>
    <div class="col-12">
      <div *ngIf="listaNomiPersonale" class="">
        <label class="control-label col-form-label"> Responsabile:</label>
        <ng-multiselect-dropdown [settings]="utentidropdownSettings" [placeholder]="'Seleziona'" name="internal_seg"
                                 [data]="listaNomiPersonale" [(ngModel)]="Responsabile" (onFilterChange)="filtroResponsabile($event)">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
  <div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
    <button (click)="saveEditModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
      <span class="ml-1 text-white font-weight-bold"> Salva </span>
      <i-feather name="save" class="feather-md "></i-feather>
    </button>
  </div>
</div>
