import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";
import {Documento} from "../../Progetti/Models/Documento";
import {ApiPathsService} from "../../api-paths.service";
import {ToastrService} from "ngx-toastr";
import {DownloadService} from "../../shared/_services/download.service";

@Component({
  selector: 'app-select-doc-model',
  templateUrl: './select-doc-model.component.html',
  styleUrls: ['./select-doc-model.component.css']
})
export class SelectDocModelComponent implements OnInit {
  @Input() Segnalazione: any;
  @Input() Modello: Documento;
  @Input() Modelli: Documento[];
  @Input() ToSend: { attivita?: number,id?: number, idTemplate?: number, documento?:  string } = {};
  @Output() Update = new EventEmitter();
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    singleOutput: true,
    idField: 'id',
    textField: 'file',
    searchPlaceholderText: "Cerca",
    allowSearchFilter: false,
    allowRemoteDataSearch: false,
  }
  attivitadropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    singleOutput: true,
    idField: 'id',
    textField: 'check',
    searchPlaceholderText: "Cerca",
    allowSearchFilter: false,
    allowRemoteDataSearch: false,
  }
  @Input() Attivita: any[];
  AttivitaSel: any;
  constructor(
    private activeModal: NgbActiveModal,
    private apiPaths: ApiPathsService,
    private toastService: ToastrService,
    private downloadService: DownloadService,
  ) {}

  ngOnInit(): void {
    this.refresh();
  }
  refresh(): void {
    this.apiPaths.ClassicPost("/documenti/documenti/list", {page: 1, size: 200, old: true}).subscribe(docs=>{
      this.Modelli = docs?.data?.filter(x=>x.checks.some(y=>this.Attivita.find(z=>z.id == y) != undefined)) ?? [];//?.filter(x => x?.checks?.includes(this.Segnalazione?.attivita ?? 0)) ?? [];
    })
  }
  async immaginecambiata(event) {
    const inpUpload: HTMLInputElement =event.target;
    const file: Blob = inpUpload.files[0];
    console.log("FileUploader ", event);
    if(!file) return;
    if (!file.type.includes("pdf")){
      inpUpload.value = "";
      this.toastService.warning("Questo formato non è supportato. Inserire solo pdf.", "Attenzione!")
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.ToSend.documento = reader?.result?.toString()?.length > 1 ? reader.result.toString()?.replace('data:application/pdf;base64,', ''): undefined;
    };
  }
  SaveButtonClick() {
    if(!this.Modello){
      this.toastService.warning("Selezionare un modello.", "Attenzione!")
      return;
    }
    this.ToSend.id = this.Segnalazione.id;
    this.ToSend.idTemplate = this.Modello?.id;
    this.ToSend.attivita = this.AttivitaSel.id;
    this.apiPaths.ClassicPost("/rma/segnalazione/template", this.ToSend).subscribe(data=>{
      if (true){
        console.log("Save response: ", data);
        this.Update?.emit(data);
        this.toastService.success("File generato con successo");
        this.closeBtnClick();
      }
    })
  }

  closeBtnClick() {
    this.activeModal.dismiss();
  }
}
