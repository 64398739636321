import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Check} from "../../models/check";

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['check-list.component.css']
})
export class CheckListComponent {
  @Input() Items: Check[];
  @Output() ItemsChange = new EventEmitter<Check[]>();
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Items, event.previousIndex, event.currentIndex);
  }

  add() {
    this.Items?.push({todo: "", check: false, modifica: true})
  }
  modifica(item: Check){
    item.modifica = !item.modifica;
  }
  delete(item: Check){
    const idx: number = this.Items.findIndex(x => x == item);
    if (idx > -1) {
      this.Items.splice(idx, 1);
      this.ItemsChange.emit(this.Items);
    }
  }
}
