<div [ngClass]="'card'">
  <div [ngClass]="'card-body' ">
    <div class="row">
      <div class="col-12">
        <div class="d-md-flex align-items-center bg-white p-2">
          <div class="ml-auto mt-3 mt-md-0">
            <button type="button" (click)="add()" class="btn btn-cyan rounded-pill p-2" ><i
              class="fas fa-plus  font-14 mr-1 text-white"></i>
              <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of Items" cdkDrag>
            <a
              href="javascript:void(0)"
              [ngbTooltip] ="item.modifica ? ('inline.save'|translate) : ('inline.edit'|translate)"
              class="link font-16 text-info font-medium mr-2"
              (click)='modifica(item)'>
              <i class="fas" [ngClass]="{'fa-pencil-alt': !item.modifica, 'fa-check': item.modifica}"></i>
            </a>
            <a
              href="javascript:void(0)"
              [ngbTooltip] ="('inline.delete'|translate)"
              class="link font-16 text-danger font-medium mr-2"
              (click)='delete(item)'>
              <i class="fas" [ngClass]="'fa-trash-alt'"></i>
            </a>
            <input id="check" [(ngModel)]="item.check" type="checkbox">
            &nbsp;
            <span *ngIf="item.modifica" style="width: 100%">
              <input [(ngModel)]="item.todo" placeholder="Todo" type="text" name="iva" class="form-control">
            </span>
            <span *ngIf="!item.modifica">
              {{item.todo}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

