import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {Scadenza} from "../../Scadenzario/Models/Scadenza";
import {environment} from "../../../environments/environment";
import {ClassicType} from "../../shared/models/ClassicType";
import {ScadenzeStoreService} from "../../Scadenzario/_services/scadenze-store.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DatePipe} from "@angular/common";
import {ApiRestService} from "../_services/api-rest.service";
import {ApiPathsService} from "../../api-paths.service";
import {CampoAggiuntivo} from "../../shared/models/campo-aggiuntivo";
import {SelectDocModelComponent} from "../select-doc-model/select-doc-model.component";

@Component({
  selector: 'app-edit-esiti-rma',
  templateUrl: './edit-esiti-rma.component.html',
  styleUrls: ['./edit-esiti-rma.component.css']
})
export class EditEsitiRmaComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  @Input() Item: Scadenza;
  @Input() IsModal: boolean = true;
  @Output() updated = new EventEmitter<Scadenza>();
  @Output() added = new EventEmitter<Scadenza>();
  @Input() checkSelezionate: { check?: string; id?: number; risposta?: boolean; fields?: CampoAggiuntivo[] } [];
  @Input() hasScadenzeFiglie: boolean = environment.Modules.ScadenzeFiglie;
  TipiSelezionati: ClassicType[] = [];
  Tipi: ClassicType[] = [{id:1, label: "POSITIVO"}, {id:0, label: "NEGATIVO"}];
  environment = environment;
  DropdownSources={
    Tipi: [{id:1, label: "POSITIVO"}, {id:0, label: "NEGATIVO"}],
    checkSelezionate: []

  }
  constructor(
    private store: ScadenzeStoreService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private apirestservice: ApiRestService,
    private apipathsservice: ApiPathsService,
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if(this.Item?.id)
      this.subscriptions.add(this.apirestservice.GetSegnalazione({id: this.Item.id}).subscribe(data => {
        this.Item = data ?? new Scadenza();
        this.compila(data);
      }))
    else
      this.compila(this.Item);

    this.apipathsservice.ClassicGet("/rma/segnalazione/stati").subscribe(data=>{
      this.Tipi = data;
      this.DropdownSources.Tipi = data;
    })

  }
  compila(item: Scadenza){
    this.DropdownSources.checkSelezionate = this.checkSelezionate;
      // this.TipiSelezionati = [item.data_fine_prevista ? {id:1, label: "POSITIVO"} : {id:0, label: "NEGATIVO"}];
  }
  saveModalButton() {
    //this.Item.data_fine_prevista = this.TipiSelezionati?.find(x=>true)?.id == 1 ? this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm') : undefined
    if (this.Item && !isNaN(this.Item.id)) {
      this.apirestservice.UpdateSegnalazione(this.Item).subscribe(data => {
        this.updated.emit(data);
        this.closeBtnClick();
      });
    } else if (this.Item && (isNaN(this.Item.id) || this.Item.id < 1)) {
      this.apirestservice.AddSegnalazione(this.Item).subscribe(data => {
        this.added.emit(data);
        this.closeBtnClick();
      });
    }
  }
  closeBtnClick() {
    this.activeModal.dismiss();
  }

  CaricaModello() {
    const editmodal = this.modalService.open(SelectDocModelComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<SelectDocModelComponent>editmodal.componentInstance).Segnalazione = this.Item;
    (<SelectDocModelComponent>editmodal.componentInstance).Update.subscribe(data => {

    });
  }
}
