<div class="card">
    <div class="card-body">
      <div class="row">
        <ng-container *ngFor="let legenda of legenda?.legenda">
          <div class="col-4">
            <h4 class="card-title">{{legenda.area | translate}}</h4>
            <div>
              <span style="height: 10px;width: 10px;background-color: #bbb;border-radius: 50%;display: inline-block;"
                    [ngStyle]="{'background-color': legenda.color}"></span>
              &nbsp;
              <span class="text-dark">{{legenda.label | translate}}</span>
              <br>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
</div>
