import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { ToastrService } from "ngx-toastr";
import { Permission } from "src/app/Login/_guards/Permission";
import { ApiPathsService } from "src/app/api-paths.service";
import { environment } from "src/environments/environment";


@Component({
    selector: 'app-categorie-nav',
    templateUrl: './categorie-nav.component.html',
    styleUrls: ['./categorie-nav.component.scss']
  })
  export class CategorieNavComponent {
    @Input() HasAdd: boolean = false;
    @Input() Categorie: any[];
    @Output() CategorieChange = new EventEmitter<any>(); 
    @Input() CatFull: any[];
    @Output() CatFullChange = new EventEmitter<any>(); 
    @Input() sel_cat_id: number;
    @Output() sel_cat_idChange = new EventEmitter<number>(); 
    @Input() Capacita: string[] = []; 
    @Input() EditModal: NgbModal;
    @Output() DeleteClick= new EventEmitter<any>();
    @Input() Sublist: boolean = false;
    @Input() modificheAbilitate: boolean = false;
    @Output() modificheAbilitateChange = new EventEmitter<any>(); 
    @Output() Navigate = new EventEmitter<any>();
    @Output() Refresh = new EventEmitter<any>();
    @Output() Save = new EventEmitter<any>();

    Env = environment;
    categoriedropdownSettings: IDropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      textField: 'categoria',
      searchPlaceholderText: "inserire almeno 3 caratteri",
      allowSearchFilter: true,
      allowRemoteDataSearch: true,
    };
   
    CategoriaDaModificare: any = null;
    LabelCategoria: string = "";
    showMenu = '';
    showSubMenu = '';
    
    CategorieLineare: any[] = [];
    CategorieSelezionate: any[] = [];
    Ordine: number;
    constructor(
        public permission: Permission,
        private modalService: NgbModal,
        private toastService: ToastrService,
        public apiPaths: ApiPathsService
    ){}

    AbilitaModifiche() {
        this.modificheAbilitate = !this.modificheAbilitate;
        this.modificheAbilitateChange.emit(this.modificheAbilitate);
    }
    
    Elimina(value: any) {
        if (!confirm("Sei sicuro di voler eliminare questa categoria?")) 
            return
        this.DeleteClick.emit(value);
    }
    openModal(targetModal: NgbModal, selected, selectedParent) {
        this.modalService.open(targetModal, {centered: true, backdrop: 'static'});
        this.CategoriaDaModificare = selected ?? {};
        this.CategorieSelezionate = selectedParent ? [selectedParent] : [];
        this.LabelCategoria = selected ? selected.categoria : "";
        this.Ordine = selected ? selected.ordine : 0;
      }
      clicked(cat) {
        if (cat && !isNaN(cat.id)) {
          this.sel_cat_id = cat.id;
          console.log(this.sel_cat_id)
          this.Navigate.emit(cat);
        }
      }

      async immaginecambiata(event) {
        const file: Blob = event.target.files[0];
        if (!file.type.includes("image")) {
          this.toastService.warning("Questo formato non è supportato. Inserire solo immagini.", "Attenzione!")
          return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.CategoriaDaModificare.immagine = reader?.result?.toString()?.length > 1 ? reader.result.toString() : undefined;
          this.CategoriaDaModificare.file = reader?.result?.toString()?.length > 1 ? event.target.files[0].name : this.CategoriaDaModificare.file;
        };
      }
    
      saveModalButton() {
        if (this.CategoriaDaModificare !== null) {
          const updateData = this.CategoriaDaModificare;
    
          updateData.categoria = this.LabelCategoria;
          updateData.cat_id = this.CategorieSelezionate != null && this.CategorieSelezionate.length > 0 ? this.CategorieSelezionate[0].id : undefined;
          updateData.ordine = this.Ordine;
         this.Save.emit(updateData)
        }
      }
    
      modalClose() {
        this.Refresh.emit();
        this.closeBtnClick()
      }
    
      deleteModalButton() {
        if (confirm("Sei sicuro di voler eliminare questo elemento ?")) {
          const updateData = Object.assign(this.CategoriaDaModificare, {cat_id: this.CategorieSelezionate?.length > 0? this.CategorieSelezionate[0].id : undefined});
         
          
            this.DeleteClick.emit(updateData);
            this.closeBtnClick();
        }
      }
    
      closeBtnClick() {
        this.modalService.dismissAll();
        //this.ngOnInit();
      }
      GetSource(tipo){
        if(!(this.Env['CategorieAddon'] && this.Env['CategorieAddon']['Sources']?.length > 0)) return null;
        return this.Env['CategorieAddon']['Sources'].find((source:{nome: string, tipo: string, restroute: string})=>  tipo == source.tipo );
      }
      GetCatSource(cat){
        var tipo = cat.listType
        console.log("Cat Type: ", tipo);
        console.log("Cat Source: ", this.GetSource(tipo))
        return this.GetSource(tipo);
      }
  }
