import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Task} from "../../models/Task";
import {Persona} from "../../../Admin/models/persona";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {RicercaPaginazione} from "../../../Admin/models/sendpersonale";
import {ApiRestService as PersonaleApiRestService} from "../../../Admin/_service/api-rest.service";
import {ApiRestService} from "../../_service/api-rest.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Permission} from "../../../Login/_guards/Permission";
import {ClassicType} from "../../../shared/models/ClassicType";
import {FileStoreService} from "../../../shared/_services/file-store.service";
import { environment } from 'src/environments/environment';
import {Scadenza} from "../../../Scadenzario/Models/Scadenza";
import {EditAttivitaRmaComponent} from "../../../rma/edit-attivita-rma/edit-attivita-rma.component";
import {ScadenzeStoreService} from "../../../Scadenzario/_services/scadenze-store.service";
import {DatePipe} from "@angular/common";
import {ApiPathsService} from "../../../api-paths.service";

@Component({
  selector: 'app-attivita-edit',
  templateUrl: './attivita-task-edit.component.html'
})
export class AttivitaTaskEditComponent implements OnInit {
  @Input() IsModal: boolean = true;
  @Input() Item: Task;
  @Output() updated = new EventEmitter<Task>();
  environment=environment;
  @ViewChild("descr") descrizione: ElementRef;
  managers: Persona[] = [];
  managerselezionati: Persona[] = [];
  statiselezionati: ClassicType[] = [];
  tipiselezionati: ClassicType[] = [];
  stati: ClassicType[] = [];
  tipi: ClassicType[] = [];
  @Input() Campi:any[];
  CampiAggiuntivi: any[] = [];
  utentidropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: false,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  clientidropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: false,
    idField: 'id',
    textField: 'nominativo',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  prodottidropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: false,
    idField: 'id',
    textField: 'seriale',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
  };
  clientiselezionati: any;
  clienti: any;
  prodotti: any;
  prodottiselezionati: any;
  DSource={};
  Stampe: any[];
  referenti: any[] = [];
  referente;

  constructor(
    private apiRestService: ApiRestService,
    private personaleApiRestService: PersonaleApiRestService,
    private ngbActiveModal: NgbActiveModal,
    public permission: Permission,
    private apirestservice: ApiRestService,
    private filestore: FileStoreService,
    private scadenzeStore: ScadenzeStoreService,
    private modalService: NgbModal,
    private datepipe: DatePipe,
    private apiPaths: ApiPathsService,
  ) { }

  ngOnInit(): void {
    this.apirestservice.getTipiAttivita().subscribe(data=> this.tipi = data ?? [])
    this.apirestservice.getStatiAttivita().subscribe(data=> this.stati = data ?? [])
    this.refresh();
    this.CampiAggiuntivi = this.Campi ?? this.environment.RmaAddOn['CampiEditTaskboard'];
  }

  close(){
    this.ngbActiveModal.dismiss();
  }
  ScadAddButton() {
    const nuova = new Scadenza();
    nuova.padre = this.Item.id;
    this.scadenzeStore.updateScadenzaSelezionata(nuova);
    const editmodal = this.modalService.open(EditAttivitaRmaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditAttivitaRmaComponent>editmodal.componentInstance).IsModal = true;
    (<EditAttivitaRmaComponent>editmodal.componentInstance).Item = nuova;
    (<EditAttivitaRmaComponent>editmodal.componentInstance).added.subscribe(data=>{this.refresh();});
    (<EditAttivitaRmaComponent>editmodal.componentInstance).updated.subscribe(data=> {});
  }
  refresh(){
    if(this.Item?.id)
      this.apirestservice.getInfoAttivita({id:this.Item.id}).subscribe(data => {
        if(!data) return;
        this.Item = data?.data;
        this.filestore.SetListaFile(data.data.files);
        this.filestore.SelectSection('attivita');
        //this.statiselezionati = this.Item?.statoObject? [this.Item.statoObject] : [];
        //this.tipiselezionati =  this.Item?.tipoObject?  [this.Item.tipoObject]  : [];
        this.managerselezionati =  this.Item?.manager?  [this.Item.manager]  : [];
        this.referente =  this.Item['referenteObject'] ?  [this.Item['referenteObject']]  : [];
        if(this.Item.managerObject)
        this.managerselezionati=  this.Item?.managerObject?  [this.Item.managerObject]  : [];
        this.clientiselezionati =  this.Item?.clienteObject?  [this.Item.clienteObject]  : [];
        this.prodottiselezionati =  this.Item?.provaObject?  [this.Item.provaObject]  : [];
        this.Item.chiusoObject = this.Item.chiuso != null ? {id: this.Item.chiuso, label: this.Item.chiuso? 'Chiuso':  'Non Chiuso' } : null
        this.Item['approvazioneObject'] = this.Item['approvazione'] !== null? {id: this.Item['approvazione'], label: this.Item['approvazione']? 'Approvato': 'Non Approvato' }: null
        this.Stampe= data.stampe;
      })
    else
      this.Item.data_inizio_prevista = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')
  }

  filtroResponsabile(item: string) {
    if (item.length >= 3)
      this.personaleApiRestService.getListaPersonale(new RicercaPaginazione(item, 0, 1)).subscribe(data=>this.managers = data?.data);
  }

  save(close?: boolean) {
    if(environment.RmaAddOn['CampiEditTaskboard'] && !environment.RmaAddOn['campiSum']){

    }else {
      //this.Item.stato = this.getId(this.statiselezionati);
      //this.Item.tipo = this.getId(this.tipiselezionati);
      this.Item.manager = this.getId(this.managerselezionati);
      this.Item['referente'] = this.getId(this.referente);
      this.Item.cliente = this.getId(this.clientiselezionati);
      this.Item.provaId = this.getId(this.prodottiselezionati);
      this.Item.partecipanti = this.Item?.partecipantiObject?.map(x => x.id)
    }
    if (this.Item.id)
      this.apirestservice.updateAttivita(this.Item).subscribe((data) =>this.saved(this.Item, close));
    else
      this.apirestservice.addAttivita(this.Item).subscribe((data) => this.saved(data, close));
  }
  saved(data, close?:boolean){
    this.Item.id = data.id;
    this.updated.emit(this.Item);
    this.refresh();
    if (close)
      this.close();
  }
  getId(array: any[]) {
    if(array && array[0] && array[0].id)
      return array[0].id;
    return null;
  }

  onFileChanged() {
    this.refresh();
  }
  ChangeHandler(item: { OnChange: string; Event: any }) {
    switch (item.OnChange ) {
      case 'OnFilesChange' :
        this.refresh();
        break;
      case 'AddAccessorio' :
        if(!this.Item['accessoriObject'])  this.Item['accessoriObject'] = [];
        if(this.Item['addAccessoriObject'])  {
          if(this.Item['accessoriObject'].some(x=>x.id == item.Event.id)){
            this.Item['accessoriObject'].find(x=>x.id == item.Event.id).quantita++;
          } else
          this.Item['accessoriObject'].push({id: item.Event.id, nome: item.Event.prodotto, prodotto: item.Event.prodotto, quantita: 1, valore: item.Event.valore, magazzino: this.Item.magazzino ?? 2, stampa: null, scarico:this.Item.scaricoMagazzino});
          setTimeout(()=>{this.Item['addAccessoriObject'] = null;}, 300)
        }
        break;
      case 'OnDelete':
        this.Item['accessoriObject'] = this.Item['accessoriObject'].filter(x => x.id != item.Event.id);
        break;
      case 'OnOperatoreChange':

        this.apiPaths.ClassicPost('/prodotti/magazzini/persona', {id: item?.Event?.id}).subscribe(
          (data)=>{this.Item.magazzino = data.data}
        )
        break;
    }
  }

  filtroCliente(event: string) {
    if (event.length >= 3)
      this.personaleApiRestService.getListaAziende(new RicercaPaginazione(event, 0, 1)).subscribe(data=>this.clienti = data?.data);
  }

  filtroProdotti(s: string) {
    if(this.getId(this.clientiselezionati))
      this.apirestservice.getProveAcquistoCliente({id:this.getId(this.clientiselezionati)}).subscribe(data=>this.prodotti = data?.data);
  }


  protected readonly undefined = undefined;
  TitoloScadenza: string;
  DescrizioneScadenza: string;
  segnalazionemanagerselezionati: Persona[] = [];

  AggiungiScadenzaInline() {
    if(!this.TitoloScadenza  && !this.DescrizioneScadenza){
      this.ScadAddButton();
      return;
    }else{
      var scadenza : Scadenza = {nome: this.TitoloScadenza, note: this.DescrizioneScadenza, padre: this.Item.id, alert_fine_task:0, alert_inizio_task:0, manager: this.segnalazionemanagerselezionati?.find(x=>true)?.id};
      this.apiPaths.ClassicPost('/rma/segnalazione/add',scadenza).subscribe(data=>{
        this.TitoloScadenza  = this.DescrizioneScadenza = undefined;
        this.segnalazionemanagerselezionati = [];
        this.refresh();
      })
    }
  }
  focusDescr(){
    this.descrizione?.nativeElement?.focus();
  }

  downloadCsv(data, filename='data') {
    let csvData = this.ConvertToCSV(data, ['nome','note']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line ='';
      for (let index in headerList) {
        let head = headerList[index];
        line += array[i][head] + ';';
      }
      str += line + '\r\n';
    }
    return str;
  }




}
