<div class="modal-header">
  <h5 class="modal-title" id="editUserLabel">Lezione</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ul ngbNav #nav="ngbNav" activeId="Task" class="nav-tabs">
    <li ngbNavItem="Task">
      <a ngbNavLink> Info Generali </a>
      <ng-template ngbNavContent>
        <div class="row" *ngIf="Attivita">
          <div class="col-3"
               *ngIf="(!Edizione?.light || CalendarItem) && !Attivita.async && permission.isPermitted('gestione-formazione')">
            <label for="responsabile" class="control-label col-form-label">Docente:</label>
            <ng-multiselect-dropdown #responsabile="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                                     [settings]="UserDropdownSettings" [placeholder]="'Docente'" id="responsabile"
                                     name="responsabile" [data]="Manager"
                                     [(ngModel)]="managerSelezionati"></ng-multiselect-dropdown>
            <small class="form-text text-danger" *ngIf="!responsabile.valid ">richiesto!</small>
          </div>
          <div class="col-3"
               *ngIf="(!Edizione?.light || CalendarItem) && !Attivita.async && permission.isPermitted('gestione-formazione')">
            <label for="Tutor" class="control-label col-form-label">Tutor:</label>
            <ng-multiselect-dropdown #Tutor="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                                     [settings]="UserDropdownSettings" [placeholder]="'Seleziona'" id="Tutor"
                                     name="Tutor" [data]="Tutors"
                                     [(ngModel)]="tutorsSelezionati"
                                     (onFilterChange)="filtroPersone($event)"></ng-multiselect-dropdown>
            <small class="form-text text-danger" *ngIf="!Tutor.valid ">richiesto!</small>
          </div>
          <div class="col-3"
               *ngIf="(!Edizione?.light || CalendarItem) && !Attivita.async && permission.isPermitted('gestione-formazione')">
            <label for="aula" class="control-label col-form-label">Aula:</label>
            <ng-multiselect-dropdown #aule="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                                     [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="aula" name="aula"
                                     [data]="Aule"
                                     [(ngModel)]="auleSelezionate"></ng-multiselect-dropdown>
            <small class="form-text text-danger" *ngIf="!aule.valid ">richiesto!</small>
          </div>
          <div class="col-3" *ngIf="permission.isPermitted('gestione-formazione')">
            <div class="form-group">
              <label for="Ordine" class="control-label col-form-label">Ordine:</label>
              <input id="Ordine" type="number" placeholder="Ordine" required [(ngModel)]="Attivita.ordine" class="form-control">
            </div>
          </div>
          <div class="col-3"
               *ngIf="(!Edizione?.light || CalendarItem) && permission.isPermitted('gestione-formazione')">
            <div class="form-group">
              <label for="Link" class="control-label col-form-label">Link:</label>
              <input id="Link" type="text" [disabled]="!permission.isPermitted('gestione-formazione')" #Link="ngModel"
                     [(ngModel)]="Attivita.link" class="form-control">
              <small class="form-text text-danger" *ngIf="!Link.valid ">Link richiesto!</small>
            </div>
          </div>
          <div class="col-md-3" *ngIf="LezioniEscluseSelezionata()?.length > 0">
            <label for="aula" class="control-label col-form-label">Propedeuticità:</label>
            <ng-multiselect-dropdown #padre="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                                     [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="padre"
                                     [data]="LezioniEscluseSelezionata()"
                                     [(ngModel)]="SelPadre"></ng-multiselect-dropdown>
            <small class="form-text text-danger" *ngIf="!padre.valid ">richiesto!</small>
          </div>
          <div class="col-3" *ngIf="SelPadre?.length > 0 && permission.isPermitted('gestione-formazione')">
            <div class="form-group">
              <label class="control-label col-form-label">Ripeti Lezione Precedente:</label>
              <input type="checkbox" #Link="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                     ngbTooltip="Con questa opzione si forza lo studente a ripetere la lettura / visione della lezione precedente"
                     [(ngModel)]="Attivita.ripeterePadre" class="form-control">
            </div>
          </div>
          <div class="col-3" *ngIf=" permission.isPermitted('gestione-formazione')">
            <div class="form-group">
              <label class="control-label col-form-label">Lezione Asincrona:</label>
              <input type="checkbox" #Link="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                     [(ngModel)]="Attivita.async" class="form-control">
            </div>
          </div>
          <div class="col-3" *ngIf=" permission.isPermitted('gestione-formazione')">
            <div class="form-group">
              <label class="control-label col-form-label">Pubblica:</label>
              <input type="checkbox" #Link="ngModel" [disabled]="!permission.isPermitted('gestione-formazione')"
                     [(ngModel)]="Attivita.public" class="form-control">
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="Nome" class="control-label col-form-label">Nome:</label>
              <input id="Nome" type="text" [disabled]="!permission.isPermitted('gestione-formazione')" required
                     #Nome="ngModel" [(ngModel)]="Attivita.nome" class="form-control">
              <small class="form-text text-danger" *ngIf="!Nome.valid && permission.isPermitted('gestione-formazione')">Nome
                richiesto!</small>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="Note" class="control-label col-form-label">Descrizione:</label>
              <textarea id="Note" type="text" rows="10" [disabled]="!permission.isPermitted('gestione-formazione')"
                        placeholder="Descrizione" required #Note="ngModel" [(ngModel)]="Attivita.note"
                        class="form-control"></textarea>
              <small class="form-text text-danger" *ngIf="!Note.valid && permission.isPermitted('gestione-formazione')">Note
                richieste!</small>
            </div>
          </div>

          <div class="col-3" *ngIf="!Attivita.async">
            <div class="form-group">
              <label for="Inizio" class="control-label col-form-label">Inizio:</label>
              <input id="Inizio" type="datetime-local" [disabled]="!permission.isPermitted('gestione-formazione')"
                     required #Inizio="ngModel" [(ngModel)]="Attivita.data_inizio_prevista" class="form-control">
              <small class="form-text text-danger"
                     *ngIf="!Inizio.valid && permission.isPermitted('gestione-formazione')">Inizio richiesto!</small>
            </div>
          </div>
          <div class="col-3" *ngIf="!Attivita.async">
            <div class="form-group">
              <label for="Fine" class="control-label col-form-label">Fine:</label>
              <input id="Fine" type="datetime-local" [disabled]="!permission.isPermitted('gestione-formazione')"
                     required #Fine="ngModel" [(ngModel)]="Attivita.data_fine_prevista" class="form-control">
              <small class="form-text text-danger" *ngIf="!Fine.valid && permission.isPermitted('gestione-formazione')">Fine
                richiesta!</small>
            </div>
          </div>
          <div class="col-12">
            <button type="submit" *ngIf="Attivita.multimediaObject?.length == 1 && !CorsiAggiungibili"
                    (click)="Segui(Attivita.multimediaObject)"
                    class="ml-auto btn btn-info p-2 waves-effect waves-light">
              <span class="ml-1 mr-1 text-white font-weight-bold"> SEGUI </span>
            </button>
          </div>
          <div class="col-12" *ngIf="Attivita?.multimediaObject?.length > 1 || CorsiAggiungibili">
            <app-media [(Lista)]="Attivita.multimediaObject" [lezione]="Attivita" (onRefresh)="closeBtnClick()"
                       (onOpenCorso)="closeBtnClick()" [Editabile]="false" [HideSoglia]="false"
                       [CorsiAggiungibili]="CorsiAggiungibili"></app-media>
          </div>


        </div>
      </ng-template>
    </li>
    <!--    <li ngbNavItem="multimedia" >
          <a style="background-color: #0e2d7d; color: white;"
             ngbNavLink> {{ CorsiAggiungibili ? 'Contenuti\nMultimediali' : 'Segui' }} </a>
          <ng-template ngbNavContent>

          </ng-template>
        </li>-->
    <li *ngIf="isEditable && permission.isPermitted('gestione-formazione')" ngbNavItem="Iscritti">
      <a ngbNavLink> Iscritti </a>
      <ng-template ngbNavContent>
        <app-alunni-list [(Alunni)]="Attivita.alunni" [Modifica]="false" [HasAdd]="false"></app-alunni-list>
      </ng-template>
    </li>
    <li *ngIf="isEditable && (permission.isPermitted('gestione-formazione') || Attivita.files?.length > 0)"
        ngbNavItem="Files">
      <a ngbNavLink> Allegati </a>
      <ng-template ngbNavContent>
        <app-file-uploader [url]="'/servizi/attivita/file'" [(id)]="Attivita.id"
                           [GestioneFile]="permission.isPermitted('gestione-formazione')"
                           (onChange)="onFileChanged()"></app-file-uploader>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
  <button type="submit" (click)="saveClick()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Salva </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
