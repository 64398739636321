import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CampoUtility } from "../models/campo-aggiuntivo";
import { DynamicCrudService } from "../_services/dynamic-crud.service";

@Component({
    templateUrl: './dynamic-buttons.component.html',
    selector: 'app-dynamic-buttons',
})
export class DynamicButtonsComponent implements OnInit{
    @Input() Azioni: any[] = [];
    @Input() ActiveID: string;
    @Output() ActiveIDChange = new EventEmitter<any>();
    @Input() component: any;
    @Input() HideDelete: boolean = false;
    @Input() CrudSettings: any = {};
    @Input() CustomHandle: (x)=>boolean;
    @Input() Item: any;
    @Output() ItemChange = new EventEmitter<any>();
    @Output() updated = new EventEmitter<any>();
    @Output() saved = new EventEmitter<any>();
    @Output() close = new EventEmitter<any>();
        constructor(
        public campoUtility: CampoUtility,
        private crudService: DynamicCrudService
    ){ }
    ngOnInit(): void {

    }
    customButton(selected, button) {
        this.crudService.CustomButtonClick(selected, button, false, this.CrudSettings,(x)=>{this.updated.emit(x)}, (x)=>{this.saved.emit(x);}, (x)=>{this.close.emit();}, this.CustomHandle, this.component );
    }
    GetActions(Azioni: any){
        if(!Azioni ||!this.ActiveID ) return Azioni ?? [];
        return Azioni?.filter(x=> !x.tabs?.length || x.tabs.includes(this.ActiveID));
    }
}