import { Persona } from './persona';
import { Files } from '../../shared/models/File';
import { ClassicType } from '../../shared/models/ClassicType';
import { Attivita } from './Attivita';
import { Sede } from './Sede';
export class Azienda {
  id: number;
  nominativo?: string;
  indirizzo?: string;
  tipi?: string [];
  tipiObject?: ClassicType[];
  ruoli?: any[];
  ruoliObject?: ClassicType[];
  tipiSedeObject?: ClassicType[];
  tipiSede?: number[];
  cap?: string;
  citta?: string;
  provincia?: string;
  email?: string;
  pec?: string;
  telefono?: string;
  codice_univoco?: string;
  piva?: string;
  cf?: string;
  note?: string;
  commerciale?: Persona;
  id_commerciale?: number;
  segnalatoreInterno?: Persona;
  password_confirmation?: string;
  privacy?: boolean;
  cognome?: string;
  nome?: string;
  referenti?: Persona[];
  id_segnalatore_interno?: number;
  segnalatore?: Azienda;
  id_segnalatore?: number;
  username?: string;
  password?: string;
  attivita?: Attivita[];
  files?: Files;
  sedi?: Sede[];
  lat?: number;
  lon?: number;
  nazione?: string;
  ConfermaPassword?: string;
  referente?: string | any;
  disponibilita?: string;
  chiusura?: string;
  rate?: number;
  noteRate?: string;
  active?: boolean;
  ruoliAccesso?: any[];
  ruoliAccessoObject?: any[];
  codAdhoc?: any;
  compilazione?: string;
  luogoNascita?: string;
  dataNascita?: string;
  statoCivile?: string;
  titoloStudio?: string;
  occupazione?: string;
  invalidita?: boolean;
  legge104?: boolean;
  diagnosi?: string;
  accompagnamento?: boolean;
  pensione?: boolean;
  tutelare?: string;
  medico?: string;
  telMedico?: string;
  terapia?: string;
  checkAmm?: boolean;
  professione?: any;
  referenteObject?: any;
  emailAccount?: string;
  iva?: string;
  Domande?: any;
  checks?: any[];
  congelamento?: boolean;
  congelamenti?: any[];

  constructor() {
    this.cap = ""
      this.cf = ""
      this.chiusura = ""
      this.citta = ""
      this.cognome = ""
      this.commerciale = undefined
      this.disponibilita = ""
      this.email = ""
      this.files = undefined
      this.id = undefined
      this.id_commerciale = undefined
      this.id_segnalatore = undefined
      this.id_segnalatore_interno = undefined
      this.lat = undefined
      this.lon = undefined
      this.nazione = ""
      this.nome = ""
      this.note = ""
      this.password = ""
      this.password_confirmation = ""
      this.pec = ""
      this.piva = ""
      this.privacy = false
      this.provincia = ""
      this.referenti = []
      this.ruoli = []
      this.ruoliObject = []
      this.sedi = []
      this.segnalatore = undefined
      this.segnalatoreInterno = undefined
      this.telefono = ""
      this.tipi = []
      this.tipiObject = []
      this.tipiSede = []
      this.tipiSedeObject = []
      this.username = ""
      this.nominativo = ""
      this.indirizzo =""
      this.referente =""
      this.referenteObject = {}
  }

}
