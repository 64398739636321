import { Component, OnInit } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Attivita} from "../models/Attivita";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {Persona} from "../models/persona";
import {ClassicType} from "../../shared/models/ClassicType";
import {RicercaPaginazione} from "../models/sendpersonale";
import {PersonaleStoreService} from "../_service/personale-store.service";
import {AziendeStoreService} from "../_service/aziende-store.service";
import {ApiRestService} from "../_service/api-rest.service";

@Component({
  selector: 'app-edit-attivita-cliente',
  templateUrl: './edit-attivita-cliente.component.html'
})
export class EditAttivitaClienteComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'label',
    allowSearchFilter: false ,
  };
  utentidropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  tipiAttivita: ClassicType[] = [{id:'id1', label: 'label'}];
  tipologieSelezionate: ClassicType[] = [];
  Responsabile: Persona[] = []
  listaNomiPersonale: Persona[] = [];
  attivita: Attivita = new Attivita();

  constructor(
    private modalService: NgbActiveModal,
    private personaleStore: PersonaleStoreService,
    private clientiStore: AziendeStoreService,
    private apiRestService: ApiRestService
  ) { }

  ngOnInit(): void {
    this.clientiStore.$tipiAttivita.subscribe((items) => {
      if (items && items.length > 0){
        this.tipiAttivita = items;
      }
    });
   this.personaleStore.$listaPersonale.subscribe((items) => {
      if (items)
        this.listaNomiPersonale = items.data;
    });
    this.clientiStore.$attivitaSelezionata.subscribe((item) => {
      if (item) {
        this.attivita = item;
        if (item.responsabile)
          this.Responsabile= [item.responsabile];
        if (item.tipoObject)
          this.tipologieSelezionate = [item.tipoObject];
        this.attivita.data_attivita = item.data_attivita.split('.')[0];
        this.attivita.data_chiusura = item?.data_chiusura?.split('.')[0];
      }
    });
  }
  closeBtnClick() {
    this.modalService.dismiss();
  }
  getDaEditForm(): Attivita {
    const nuovo = this.attivita;
    if (this.Responsabile && this.Responsabile.length > 0)
      nuovo.resp_attivita = this.Responsabile[0].id;
    if (this.tipologieSelezionate && this.tipologieSelezionate.length > 0)
      nuovo.tipo = this.tipologieSelezionate[0].id;
    return nuovo;
  }
  saveEditModalButton() {
    this.apiRestService.updateAttivita(this.getDaEditForm()).subscribe(() => {
      this.closeBtnClick();
    });
  }
  filtroResponsabile(item: string) {
    if (item.length >= 3) {
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 0, 1));
    }
  }
}
