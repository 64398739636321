/*
 * .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
 *    .       .       .       .       .       .       .       .       .       .       .       .
 *      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
 *  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
 *    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
 *   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
 *      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
 *   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
 *      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
 *   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
 *      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
 *   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
 *     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
 *   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
 *     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
 *   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
 *      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
 *   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
 *        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
 *   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
 *
 * Copyright FortyFix(c) 2024.
 * Title: ModuleUtility.ts;
 * Path: /Volumes/Dati/SbSW_SoftwareBySystem_Works/Fortyfix/GESFF_Gestionale_FF_Angular/src/environments/Utility/ModuleUtility.ts;
 * Description: ;
 * Author: giovannibattistadellaporta;
 * Created at: 20/03/24, 11:32;
 * Updated at: 20/03/24, 11:32;
 *
 *
 */
import {BaseEnv} from "../BaseEnvironments/BaseEnv";
import {isArray} from "chart.js/helpers";
export function addModule(moduleEnv, environment) {
  console.log(`Load Module: `, moduleEnv);
  console.log(`into: `, environment);
  var res = generateEnv(environment, moduleEnv);
  console.log(`Result`, res);
  return res;
}
export function getModule(moduleEnv) {
  return addModule(moduleEnv,BaseEnv);
}

export function generateEnv (baseEnv, moduleEnv, log: boolean = false, paramName : string= "") {
  var newEnv = Object.assign({}, baseEnv);
  Object.getOwnPropertyNames(moduleEnv).forEach(moduleProperty=>{
    if(isArray(moduleEnv[moduleProperty])){
      var filtered = baseEnv[moduleProperty]?.filter(item => moduleEnv[moduleProperty]?.find(x=>(x.id && x.id == item.id) || ( x.title &&  x.title == item.title) || ( x.nome &&  x.nome == item.nome)) == undefined);
      if(filtered?.length <  baseEnv[moduleProperty]?.length){
        console.log("Attenzione non tutti gli item dell'array sorgente sono presenti nell'array di destinazione",baseEnv[moduleProperty],filtered );
      }
      newEnv[moduleProperty] = [... moduleEnv[moduleProperty], ...(filtered ?? []) ];
    } else if(
      typeof moduleEnv[moduleProperty] === "object" &&
      moduleEnv[moduleProperty] !== null
    ){
      newEnv[moduleProperty] = generateEnv(baseEnv[moduleProperty] ?? {}, moduleEnv[moduleProperty], true, `${paramName}.${moduleProperty}`);
    }
    else{
      newEnv[moduleProperty] = moduleEnv[moduleProperty];
    }

  })
  return newEnv;
}
