<div class="modal-header">
  <h5 class="modal-title" >{{'Carica un modello o un vecchio report' | translate}}</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div *ngIf="Segnalazione" class="modal-body">
    <div class="row">
      <div class="col-lg-3" >
        <label class="control-label col-form-label">Modello:</label>
        <ng-multiselect-dropdown  [settings]="dropdownSettings" [placeholder]="'Seleziona'" id="esito" name="esito" [data]="Modelli" [(ngModel)]="Modello">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-3" >
        <label class="control-label col-form-label">Attività:</label>
        <ng-multiselect-dropdown  [settings]="attivitadropdownSettings" [placeholder]="'Seleziona'" id="attivita"  [data]="Attivita" [(ngModel)]="AttivitaSel">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label  class="control-label col-form-label">Vecchio Report:</label>
          <input type="file" class="upload" (change)="immaginecambiata($event)">
        </div>
      </div>
    </div>
</div>
<div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
  <button (click)="SaveButtonClick()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> {{'RMA.attivita.salva' | translate}} </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
